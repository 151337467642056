import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Importando o ícone de fechar
import KButton from "components/KButton";
import { useNavigate } from "react-router-dom"; // Importar useNavigate para redirecionamento

function RegisterOptionsPopup({ open, onClose }) {
  const navigate = useNavigate(); // Inicializando useNavigate

  const handleApiRegister = () => {
    console.log("Cadastrar API selecionado");
    onClose(); // Fechar o modal após a ação
  };

  const handleManualRegister = () => {
    console.log("Cadastrar Manual selecionado");
    onClose(); // Fechar o modal e redirecionar
    navigate("/registrar-entrada"); // Redireciona para a página de registro manual
  };

  return (
    <Dialog open={open}  onClose={onClose} fullWidth maxWidth="sm" >
      <Card
        sx={{
            borderRadius: "0px", // Aumentando o arredondamento das bordas para suavizar
        }}
      >
        <CardContent>
          {/* Ícone de fechar no canto superior direito */}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h5"
            textAlign="center"
            gutterBottom
            color="white" // Cor do texto para melhor contraste
          >
            Escolha uma opção de cadastro
          </Typography>
          <DialogContent>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={6}>
                <KButton
                  variant="contained"
                  color="info"
                  fullWidth
                  onClick={handleApiRegister}
                  sx={{
                    borderRadius: "8px",
                    padding: "10px 0",
                  }}
                >
                  Automático
                </KButton>
              </Grid>
              <Grid item xs={6}>
                <KButton
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={handleManualRegister}
                  sx={{
                    borderRadius: "8px",
                    padding: "10px 0",
                  }}
                >
                  Manual
                </KButton>
              </Grid>
            </Grid>
          </DialogContent>
        </CardContent>
      </Card>
    </Dialog>
  );
}

export default RegisterOptionsPopup;
