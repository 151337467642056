import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardActions,
  InputAdornment,
  Box,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import KButton from "components/KButton";
import KTypography from "components/KTypography";
import apiService from "utils/apiService";
import KBox from "components/KBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate } from "react-router-dom";

const RegisterExit = () => {
  const navigate = useNavigate(); // Inicialização do hook useNavigate
  const [groupsList, setGroupsList] = useState([]);
  const [sourceGroup, setSourceGroup] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSelectedItems, setSearchSelectedItems] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [quantityToExit, setQuantityToExit] = useState(1);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [pendingSourceGroup, setPendingSourceGroup] = useState(null);
  const [observation, setObservation] = useState(""); // Observação geral para o registro de saída

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await apiService.groups.getAll();
        setGroupsList(response.data);
      } catch (error) {
        console.error("Erro ao buscar grupos:", error);
      }
    };
    fetchGroups();
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await apiService.items.getActive();
        setItemsList(response.data);
      } catch (error) {
        console.error("Erro ao buscar itens:", error);
      }
    };
    fetchItems();
  }, []);

  const handleOpenDialog = (item) => {
    setCurrentItem(item);
    setQuantityToExit(1); // Reset quantity
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentItem(null);
  };

  const handleAddToExit = () => {
    const remainingQuantity =
      currentItem.quantity - (selectedItems.find((i) => i.id === currentItem.id)?.quantityToExit || 0);

    if (quantityToExit > remainingQuantity) {
      alert(
        `A quantidade selecionada excede o disponível. Máximo permitido: ${remainingQuantity}`
      );
      return;
    }

    setSelectedItems((prev) => {
      const existingItem = prev.find((i) => i.id === currentItem.id);
      if (existingItem) {
        return prev.map((i) =>
          i.id === currentItem.id
            ? { ...i, quantityToExit: i.quantityToExit + quantityToExit }
            : i
        );
      } else {
        return [...prev, { ...currentItem, quantityToExit }];
      }
    });
    handleCloseDialog();
  };

  const handleRemoveFromExit = (item) => {
    setSelectedItems((prev) => prev.filter((i) => i.id !== item.id));
  };

  const handleSourceGroupChange = (value) => {
    if (selectedItems.length > 0) {
      setPendingSourceGroup(value); // Temporarily store the new group
      setConfirmationDialog(true); // Open the confirmation dialog
    } else {
      setSourceGroup(value);
      setSelectedItems([]); // Clear selected items when source changes
    }
  };

  const confirmSourceGroupChange = () => {
    setSourceGroup(pendingSourceGroup);
    setSelectedItems([]);
    setConfirmationDialog(false);
    setPendingSourceGroup(null);
  };

  const handleRegisterExit = async () => {
    try {
      const stockOutflows = selectedItems.map((item) => ({
        item_id: item.id,
        quantity: item.quantityToExit,
      }));

      // Fazendo a chamada para registrar todas as saídas de estoque com a observação geral
      await apiService.stock_outflows.add({
        items: stockOutflows,
        observation: observation || "Saída de estoque registrada.",
      });

      alert("Saída registrada com sucesso!");
      setSelectedItems([]);
      setObservation(""); // Limpa a observação após o registro

      // Redireciona para a página /almoxarifado
      navigate("/almoxarifado");
    } catch (error) {
      console.error("Erro ao registrar saída:", error);
      alert("Erro ao registrar saída.");
    }
  };

  const getRemainingQuantity = (item) => {
    const selectedItem = selectedItems.find((i) => i.id === item.id);
    return item.quantity - (selectedItem ? selectedItem.quantityToExit : 0);
  };

  const filteredSelectedItems = selectedItems.filter((item) =>
    item.item_model_name.toLowerCase().includes(searchSelectedItems.toLowerCase())
  );

  const filteredItems = itemsList.filter((item) => {
    const matchesGroup = item.group_id === parseInt(sourceGroup);
    const matchesSearchTerm = item.item_model_name
      ?.toString()
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesGroup && matchesSearchTerm;
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        sx={{
          width: "100%",
          p: 3,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          title={
            <KTypography variant="h5" fontWeight="bold">
              Registrar Saída de Itens
            </KTypography>
          }
          subheader={
            <KTypography variant="body2" color="text">
              Selecione o grupo de origem e os itens para registrar a saída.
            </KTypography>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Grupo de Origem"
                variant="outlined"
                fullWidth
                select
                value={sourceGroup}
                onChange={(e) => handleSourceGroupChange(e.target.value)}
              >
                {groupsList.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observação Geral"
                variant="outlined"
                fullWidth
                value={observation}
                onChange={(e) => setObservation(e.target.value)}
                sx={{ mt: 2 }}
              />
            </Grid>
          </Grid>

          {/* Seção de Itens Disponíveis */}
          <Grid container spacing={3} marginTop={3}>
            <Grid item xs={12} sm={6}>
              <KTypography variant="h6" sx={{ mb: 2 }}>
                Itens Disponíveis
              </KTypography>
              <TextField
                placeholder="Pesquisar item..."
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />
              <KBox
                sx={{
                  height: 300,
                  overflowY: "auto",
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  p: 2,
                }}
              >
                {filteredItems.map((item) => {
                  const remainingQuantity = getRemainingQuantity(item);
                  return (
                    <KBox
                      key={item.id}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={1}
                      p={1}
                      borderRadius="8px"
                      sx={{ backgroundColor: "rgba(193, 192, 199, 0.2)" }}
                    >
                      <KBox
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <KTypography variant="subtitle2" fontWeight="bold">
                          Modelo: {item.item_model_name}
                        </KTypography>
                        <KTypography variant="body2" color="text">
                          NCM: {item.ncm} | Documento: {item.stock_inflow_document_number}
                        </KTypography>
                        <KTypography variant="body2" color="text">
                          Quantidade Disponível: {remainingQuantity}
                        </KTypography>
                      </KBox>
                      <KButton
                        variant="outlined"
                        color="info"
                        onClick={() => handleOpenDialog(item)}
                        disabled={remainingQuantity <= 0}
                      >
                        Selecionar
                      </KButton>
                    </KBox>
                  );
                })}
              </KBox>
            </Grid>

            {/* Seção de Itens Selecionados */}
            <Grid item xs={12} sm={6}>
              <KTypography variant="h6" sx={{ mb: 2 }}>
                Itens Selecionados para Saída
              </KTypography>
              <TextField
                placeholder="Pesquisar item selecionado..."
                variant="outlined"
                fullWidth
                value={searchSelectedItems}
                onChange={(e) => setSearchSelectedItems(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />
              <KBox
                sx={{
                  height: 300,
                  overflowY: "auto",
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  p: 2,
                }}
              >
                {filteredSelectedItems.map((item) => (
                  <KBox
                    key={item.id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1}
                    p={1}
                    borderRadius="8px"
                    sx={{ backgroundColor: "rgba(193, 192, 199, 0.2)" }}
                  >
                    <KBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <KTypography variant="subtitle2" fontWeight="bold">
                        Modelo: {item.item_model_name}
                      </KTypography>
                      <KTypography variant="body2" color="text">
                        Quantidade a Sair: {item.quantityToExit}
                      </KTypography>
                    </KBox>
                    <KButton
                      variant="outlined"
                      color="error"
                      onClick={() => handleRemoveFromExit(item)}
                    >
                      Remover
                    </KButton>
                  </KBox>
                ))}
              </KBox>
            </Grid>
          </Grid>
        </CardContent>

        {/* Botão Registrar Saída no Canto Inferior Direito */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <KButton
            variant="contained"
            color="warning"
            onClick={handleRegisterExit}
          >
            Registrar Saída
          </KButton>
        </Box>

        {/* Modal para Inserir Quantidade */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="sm"
        >
          <Card>
            <CardContent>
              <KTypography variant="h5" gutterBottom>
                Selecionar Quantidade para Saída
              </KTypography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <KTypography>
                    Modelo: {currentItem?.item_model_name}
                  </KTypography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    label="Quantidade"
                    variant="outlined"
                    fullWidth
                    value={quantityToExit}
                    onChange={(e) =>
                      setQuantityToExit(
                        Math.min(Number(e.target.value), currentItem?.quantity)
                      )
                    }
                    inputProps={{ min: 1, max: currentItem?.quantity }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <KButton
                onClick={handleCloseDialog}
                color="secondary"
                variant="outlined"
              >
                Cancelar
              </KButton>
              <KButton
                onClick={handleAddToExit}
                color="success"
                variant="contained"
                disabled={quantityToExit <= 0}
              >
                Adicionar
              </KButton>
            </CardActions>
          </Card>
        </Dialog>

        {/* Modal de Confirmação de Mudança de Grupo */}
        <Dialog
          open={confirmationDialog}
          onClose={() => setConfirmationDialog(false)}
        >
          <DialogTitle>Confirmação</DialogTitle>
          <DialogContent>
            <KTypography variant="body1">
              Alterar o grupo de origem irá limpar todos os itens selecionados
              para saída. Deseja continuar?
            </KTypography>
          </DialogContent>
          <DialogActions>
            <KButton
              onClick={() => setConfirmationDialog(false)}
              color="secondary"
              variant="outlined"
            >
              Cancelar
            </KButton>
            <KButton
              onClick={confirmSourceGroupChange}
              color="primary"
              variant="contained"
            >
              Confirmar
            </KButton>
          </DialogActions>
        </Dialog>
      </Card>
      <Footer />
    </DashboardLayout>
  );
};

export default RegisterExit;
