import React, { useCallback, useState, useEffect } from "react";
import { Card, CardHeader, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import KBox from "components/KBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import KTable from "components/Table"; // Importando seu KTable
import HistoryHeader from "./HistoryHeader"; // Novo header com ComplexStatisticsCard
import Grid from "@mui/material/Grid"; // Importando Grid
import apiService from "utils/apiService";

// Configurações das colunas para o KTable
const columns = [
  { field: "date", headerName: "Data", flex: 1 },
  { field: "user", headerName: "Usuário", flex: 1 },
  { field: "action", headerName: "Ação", flex: 1 },
  { field: "details", headerName: "Detalhes", flex: 2 },
];

function History() {
  const [loading, setLoading] = useState(false);
  const [logRows, setLogRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // Carregar dados de histórico da API ao montar o componente
  useEffect(() => {
    setLoading(true);
    // Chamada à API para obter todas as entradas de histórico
    apiService.historyEntries.getAll()
      .then((response) => {
        const data = response.data.map((entry) => ({
          id: entry.id,
          date: entry.created_at,
          user: entry.actor_name,
          action: entry.action_type,
          details: entry.action_description,
        }));
        setLogRows(data); // Atualizar o estado com os dados obtidos
      })
      .catch((error) => {
        console.error("Erro ao carregar entradas de histórico:", error);
      })
      .finally(() => {
        setLoading(false); // Remover o estado de carregamento
      });
  }, []);

  const totalActions = logRows.length;
  const recentActions = 20; // Exemplos de dados
  const pendingActions = 5; // Exemplos de dados

  // Função para formatar as linhas (caso necessário)
  const formatLogRows = (rows) => {
    return rows.map((row) => ({
      ...row,
      // Aqui você pode formatar os dados se precisar
    }));
  };

  const handleSelection = useCallback((selectedRows) => {
    setSelectedRows(selectedRows);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox py={3}>
        {/* Novo componente de Header */}
        <HistoryHeader
          totalActions={totalActions}
          recentActions={recentActions}
          pendingActions={pendingActions}
          onFilterClick={() => console.log("Filtrar")} // Ação para filtrar
        />

        <Grid container spacing={3}>
          {/* Indicador de Carregamento */}
          {loading && (
            <Grid item xs={12} display="flex" justifyContent="center">
              <CircularProgress />
            </Grid>
          )}

          {/* Tabela de Ações dos Usuários com KTable */}
          {!loading && (
            <Grid item xs={12}>
              <Box p={3}>
                <KTable
                  columns={columns}  // Colunas para o KTable
                  rows={formatLogRows(logRows)}        // Linhas formatadas
                  title="Lista de Ações de Usuários"
                  onEdit={(row) => console.log("Editando ação", row)} // Função de edição
                  onDelete={(rowId) => console.log("Deletando ação", rowId)} // Função de exclusão
                  onSelect={handleSelection} // Função de seleção
                  editableFields={{ action: "Ação" }} // Campos editáveis (se necessário)
                  showEditButton={false}
                  showDeleteButton={false}
                  availableActions={["export"]}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </KBox>
      <Footer />
    </DashboardLayout>
  );
}

export default History;
