import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  Card,
  CardContent,
  Tooltip,
  TextField,
  Autocomplete,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import KBox from "components/KBox";
import KButton from "components/KButton";
import RegisterItemsHeader from "./RegisterItemsHeader";
import produce from "immer";
import FormattedPriceInput from "components/PriceField";
import { NumericFormat } from "react-number-format";
import apiService from "utils/apiService";

// Lista de unidades de medida
const UNIDADES_MEDIDA = [
  { label: "Unidade", value: "Un" },
  { label: "Quilograma (Kg)", value: "kg" },
  { label: "Tonelada", value: "tonelada" },
  { label: "Grama (g)", value: "grama" },
  { label: "Miligramas (mg)", value: "mg" },
  { label: "Litros", value: "litro" },
  { label: "Mililitros (ml)", value: "ml" },
  { label: "Metro Cúbico (m³)", value: "m3" },
  { label: "Galão", value: "galao" },
  { label: "Metros", value: "m" },
  { label: "Centímetros (cm)", value: "cm" },
  { label: "Milímetros (mm)", value: "mm" },
  { label: "Metro Quadrado (m²)", value: "m2" },
  { label: "Centímetro Quadrado (cm²)", value: "cm2" },
  { label: "Caixa", value: "caixa" },
  { label: "Pacote", value: "pacote" },
  { label: "Rolo", value: "rolo" },
  { label: "Balde", value: "balde" },
  { label: "Palete", value: "palete" },
  { label: "Dúzia", value: "duzia" },
  { label: "Par", value: "par" },
];

// Componente reutilizável para campos de texto
const CustomTextField = React.memo(({ label, name, value, onChange, error, helperText }) => {
  return (
    <TextField
      label={label}
      name={name}
      value={value || ""}
      onChange={onChange}
      fullWidth
      error={error}
      helperText={error ? helperText : ""}
    />
  );
});

// Componente para cada linha de item
const ItemRow = React.memo(({ item, index, onChange, onRemove, error, itemModels }) => (
  <Card sx={{ mb: 2, borderRadius: 2, boxShadow: 1, width: '100%' }}>
    <CardContent>
      <Grid container spacing={2} alignItems="center">
        {/* Código do Produto */}
        <Grid item xs={2}>
          <CustomTextField
            label="Código do Produto"
            name="itemCode"
            value={item.itemCode}
            onChange={(e) => onChange(index, e)}
            error={error && !item.itemCode}
            helperText="Obrigatório"
          />
        </Grid>

        {/* NCM */}
        <Grid item xs={2}>
          <CustomTextField
            label="NCM"
            name="ncm"
            value={item.ncm}
            onChange={(e) => onChange(index, e)}
            error={error && !item.ncm}
            helperText="Obrigatório"
          />
        </Grid>

        {/* Item Model */}
        <Grid item xs={2}>
          <Autocomplete
            options={itemModels}
            getOptionLabel={(option) => option.name || ""}
            value={item.itemModel || null}
            onChange={(event, newValue) => {
              const fakeEvent = {
                target: {
                  name: "itemModel",
                  value: newValue,
                },
              };
              onChange(index, fakeEvent);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Item"
                placeholder="Selecione o item"
                fullWidth
                error={error && !item.itemModel}
                helperText={error && !item.itemModel ? "Obrigatório" : ""}
              />
            )}
          />
        </Grid>

        {/* Unidade de Medida */}
        <Grid item xs={1}>
          <FormControl fullWidth>
            <InputLabel>Unidade</InputLabel>
            <Select
              name="measurementUnit"
              value={item.measurementUnit}
              onChange={(e) => onChange(index, e)}
              label="Unidade"
            >
              {UNIDADES_MEDIDA.map((measurementUnit) => (
                <MenuItem key={measurementUnit.value} value={measurementUnit.value}>
                  {measurementUnit.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Quantidade */}
        <Grid item xs={1}>
          <TextField
            label="Quantidade"
            name="quantity"
            value={item.quantity}
            onChange={(e) => onChange(index, e)}
            error={error && !item.quantity}
            helperText="Obrigatório"
            type="number"  // Apenas números são permitidos
          />
        </Grid>

        {/* Preço Unitário */}
        <Grid item xs={2}>
          <TextField
            label="Preço Unitário"
            name="unitPrice"
            value={item.unitPrice}
            onChange={(e) => onChange(index, e)}
            error={error && !item.unitPrice}
            helperText="Obrigatório"
            type="number"  // Apenas números são permitidos
          />
        </Grid>

        {/* Subtotal (Somente Leitura) */}
        <Grid item xs={1.5}>
          <TextField
            name="subtotal"
            label="Subtotal"
            value={item.subtotal.toFixed(2)}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        {/* Botão Remover */}
        <Grid item xs={0.5} container justifyContent="center" alignItems="center">
          <Tooltip title="Remover Item" arrow>
            <span>
              <IconButton
                color="error"
                onClick={() => onRemove(index)}
                disabled={index === 0}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
));

function RegisterItems() {
  const location = useLocation();
  const navigate = useNavigate();
  const entryData = useMemo(() => location.state || {}, [location.state]);

  // Estado para itens, erros e modelos de itens
  const [items, setItems] = useState([
    { itemModel: null, quantity: "", itemCode: "", ncm: "", measurementUnit: "Un", unitPrice: "", subtotal: 0 },
  ]);
  const [error, setError] = useState(false);
  const [itemModels, setItemModels] = useState([]);

  // Função para carregar os modelos de itens (pode ser cacheada)
  const fetchItemModels = useCallback(async () => {
    try {
      const response = await apiService.item_models.getAll();
      setItemModels(response.data);
    } catch (error) {
      console.error("Erro ao buscar os modelos de itens:", error);
    }
  }, []);

  // Carrega os modelos de itens apenas uma vez
  useEffect(() => {
    fetchItemModels();
  }, [fetchItemModels]);

  // Calcula o total geral
  const totalGeral = useMemo(
    () => items.reduce((acc, item) => acc + item.subtotal, 0),
    [items]
  );

  // Função para lidar com mudanças nos itens
  const handleItemChange = useCallback((index, event) => {
    const { name, value } = event.target;
    setItems((prevItems) =>
      produce(prevItems, (draft) => {
        draft[index][name] = value;
        draft[index].subtotal =
          (draft[index].quantity || 0) * (draft[index].unitPrice || 0);
      })
    );
  }, []);

  // Função para adicionar um novo item
  const handleAddItem = useCallback(() => {
    setItems((prevItems) =>
      produce(prevItems, (draft) => {
        draft.push({
          itemModel: null,
          quantity: "",
          itemCode: "",
          ncm: "",
          measurementUnit: "Un",
          unitPrice: "",
          subtotal: 0,
        });
      })
    );
  }, []);

  // Função para remover um item
  const handleRemoveItem = useCallback((index) => {
    setItems((prevItems) =>
      produce(prevItems, (draft) => {
        draft.splice(index, 1);
      })
    );
  }, []);

  // Função para submeter os dados
  const handleSubmit = useCallback(async () => {
    // Verifica se há algum campo obrigatório ausente
    const invalidItems = items.some((item) => !item.itemModel || !item.quantity || !item.unitPrice || !item.itemCode);

    if (invalidItems) {
      setError(true);
      console.log("Erro: Existem itens com campos obrigatórios faltando.");
      return;
    }

    let stock_inflow_id = null;

    // Payload para o stock inflow
    const stockInflowPayload = {
      document_number: entryData.documentNumber,
      delivery_date: entryData.date,
      purchase_rating: entryData.evaluationLevel || null,
      supplier_id: entryData.supplier.id,
      group_id: entryData.group.id,
      requester_id: entryData.requester?.id || null,
      requester_message: entryData.requestMessage,
      requisition_number: entryData.requestNumber,
      carrier: entryData.carrier,
      freight_type: entryData.freightType,
      vehicle_type: entryData.vehicleType,
      vehicle_plate: entryData.vehiclePlate,
      inspection: entryData.inspection,
      observation: entryData.observations,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    console.log("Payload de stock inflow enviado:", stockInflowPayload);

    try {
      const response = await apiService.stock_inflows.add(stockInflowPayload);
      stock_inflow_id = response.data.id;
      console.log("Stock Inflow criado com sucesso, ID:", stock_inflow_id);
    } catch (error) {
      console.error("Erro ao adicionar stock inflow:", error.response?.data || error);
      return;
    }

    // Enviar os itens relacionados ao stock inflow
    for (const item of items) {
      const itemPayload = {
        item_model_id: item.itemModel.id,
        group_id: entryData.group.id,
        code: item.itemCode,  // Ajustado de itemCode para code
        ncm: item.ncm,
        measurement_unit: item.measurementUnit,
        quantity: Number(item.quantity),  // Garantindo que quantity seja um número
        price: Number(item.unitPrice),  // Garantindo que price seja um número
        stock_inflow_id: stock_inflow_id,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      console.log("Payload do item enviado:", itemPayload);

      try {
        const itemResponse = await apiService.items.add(itemPayload);
        console.log("Item cadastrado com sucesso:", itemResponse.data);
      } catch (error) {
        console.error("Erro ao adicionar item:", error.response?.data || error);
        if (error.response?.data?.detail) {
          console.log("Detalhes do erro:", error.response.data.detail);
        }
      }
    }

    // Após sucesso, redirecionar para a página de resumo
    const fullEntry = {
      ...entryData,
      items,
    };
    navigate("/resumo", { state: fullEntry });
  }, [items, entryData, navigate]);





  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox py={3}>
        <Grid container spacing={3} justifyContent="center" style={{ width: '100%' }}>
          <Grid item xs={12}>
            <RegisterItemsHeader {...entryData} />
            <Card elevation={3} sx={{ p: 3, borderRadius: 2, width: '100%' }}>
              {items.map((item, index) => (
                <ItemRow
                  key={index}
                  item={item}
                  index={index}
                  onChange={handleItemChange}
                  onRemove={handleRemoveItem}
                  error={error}
                  itemModels={itemModels}
                />
              ))}
              <Box display="flex" justifyContent="space-between" mt={3} alignItems="center">
                <KButton
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleAddItem}
                  color="info"
                >
                  Adicionar Item
                </KButton>
                <Typography variant="h6">
                  Total Geral:{" "}
                  <NumericFormat
                    value={totalGeral.toFixed(2)}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </Typography>
              </Box>

              <Box display="flex" justifyContent="flex-end" mt={3}>
                <KButton
                  variant="contained"
                  color="success"
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                  sx={{ borderRadius: 2, px: 4 }}
                >
                  Concluir Entrada
                </KButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </KBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RegisterItems;
