// src/routes.js

import Dashboard from "layouts/dashboard";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import Constructions from "layouts/constructions";
import Warehouse from "layouts/warehouse";
import History from "layouts/history";
import ProjectDetails from "layouts/projectDetails"; // Import the ProjectDetails component
import Suppliers from "layouts/suppliers";
import AdminDashboard from "layouts/admin/adminDashboard";
import RegisterEntry from "layouts/registerEntry";
import RegisterItems from "layouts/registerItems";
import RegisterExit from "layouts/registerExit";
import AddMovement from "layouts/Movement/AddMovement.js";
import Movement from "layouts/Movement/index.js";
import AddItem from "layouts/Item/AddItem.js";
import Item from "layouts/Item/index.js";
import PurchaseOrder from "layouts/purchaseOrder";

import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    showInSidenav: true, // Visível no Sidenav
  },
  {
    type: "collapse",
    name: "Almoxarifado",
    key: "almoxarifado",
    icon: <Icon fontSize="small">warehouse</Icon>,
    route: "/almoxarifado",
    component: <Warehouse />,
    showInSidenav: true, // Visível no Sidenav
  },
  {
    type: "collapse",
    name: "Item",
    key: "item",
    icon: <Icon fontSize="small">constructions</Icon>,
    route: "/item",
    component: <Item />,
    showInSidenav: true, // Visível no Sidenav
  },
  {
    type: "collapse",
    name: "Movimentação",
    key: "movimentacao",
    icon: <Icon fontSize="small">local_shipping</Icon>,
    route: "/movimentacao",
    component: <Movement />,
    showInSidenav: true, // Visível no Sidenav
  },
  {
    type: "collapse",
    name: "Grupos",
    key: "grupos",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/grupos",
    component: <Constructions />,
    showInSidenav: true, // Visível no Sidenav
  },
  {
    type: "collapse",
    name: "Fornecedores",
    key: "fornecedores",
    icon: <Icon fontSize="small">store</Icon>, // Escolha um ícone apropriado
    route: "/fornecedores",
    component: <Suppliers />,
    showInSidenav: true, // Visível no Sidenav
  },
  {
    type: "collapse",
    name: "Histórico",
    key: "historico",
    icon: <Icon fontSize="small">history</Icon>,
    route: "/historico",
    component: <History />,
    showInSidenav: true, // Visível no Sidenav
  },
  {
    type: "collapse",
    name: "Notificações",
    key: "notificacoes",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notificacoes",
    component: <Notifications />,
    showInSidenav: false, // Visível no Sidenav
  },
  {
    type: "collapse",
    name: "Conta",
    key: "conta",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/conta",
    component: <Profile />,
    showInSidenav: true, // Visível no Sidenav
  },
  {
    type: "collapse",
    name: "Logar",
    key: "logar",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/logar",
    component: <SignIn />,
    showInSidenav: false, // Não visível no Sidenav
  },
  // Adiciona a rota para ProjectDetails
  {
    type: "route",
    key: "project-details",
    route: "/project-details/:id",
    component: <ProjectDetails />,
    showInSidenav: false, // Não visível no Sidenav
  },
  {
    type: "route",
    key: "registrar-entrada",
    route: "/registrar-entrada",
    component: <RegisterEntry />,
    showInSidenav: false, // Não visível no Sidenav
  },
  {
    type: "collapse",
    key: "ordem_de_compra",
    name: "Ordem de Compra",
    route: "/ordem_de_compra",
    component: <PurchaseOrder />,
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    showInSidenav: true,
  },
  {
    type: "collapse",
    key: "financeiro",
    name: "Financeiro",
    route: "/financeiro",
    component: <PurchaseOrder />,
    icon: <Icon fontSize="small">local_atm</Icon>,
    showInSidenav: true,
  },

  {
    type: "collapse",
    key: "graficos",
    name: "Gráficos",
    route: "/graficos",
    component: <PurchaseOrder />,
    icon: <Icon fontSize="small">leaderboard</Icon>,
    showInSidenav: true,
  },
  {
    type: "collapse",
    name: "Administração",
    key: "admin",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>, // Escolha um ícone apropriado
    route: "/admin",
    component: <AdminDashboard />,
    showInSidenav: true, // Visível no Sidenav
  },
  {
    type: "collapse",
    key: "controle_de_almoco",
    name: "Controle de Almoço",
    route: "/controle_de_almoco",
    component: <PurchaseOrder />,
    icon: <Icon fontSize="small">restaurant</Icon>,
    showInSidenav: true,
  },
  {
    type: "route",
    key: "register-items",
    route: "/register-items",
    component: <RegisterItems />,
    showInSidenav: false, // Não visível no Sidenav
  },
  {
    type: "route",
    key: "registrar-saida",
    route: "/registrar-saida",
    component: <RegisterExit />,
    showInSidenav: false, // Não visível no Sidenav
  },
  {
    type: "route",
    key: "adicionar-movimentacao",
    route: "/adicionar-movimentacao",
    component: <AddMovement />,
    showInSidenav: false, // Não visível no Sidenav
  },
  {
    type: "route",
    key: "adicionar-item",
    route: "/adicionar-item",
    component: <AddItem />,
    showInSidenav: false, // Não visível no Sidenav
  },


];

export default routes;
