import React from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <Link key={link.name} href={link.href} target="_blank" sx={{ mx: 2 }}>
        <KTypography variant="button" fontWeight="regular" color="text">
          {link.name}
        </KTypography>
      </Link>
    ));

  return (
    <KBox width="100%" py={3} sx={{ mt: "auto" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} lg={6}>
          <Stack
            direction="row"
            justifyContent={{ xs: "center", lg: "flex-start" }}
            alignItems="center"
            flexWrap="wrap"
            spacing={1}
          >
            <KTypography variant="body2" color="text" fontSize={size.sm}>
              &copy; {new Date().getFullYear()}, feito com
            </KTypography>
            <Icon color="inherit" fontSize="small" sx={{ mx: 0.5 }}>
              favorite
            </Icon>
            <KTypography variant="body2" color="text" fontSize={size.sm}>
              pela
            </KTypography>
            <Link href={href} target="_blank">
              <KTypography variant="button" fontWeight="medium">
                &nbsp;{name}&nbsp;
              </KTypography>
            </Link>
          </Stack>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Stack
            direction="row"
            justifyContent={{ xs: "center", lg: "flex-end" }}
            alignItems="center"
            spacing={2}
            sx={{ mt: { xs: 2, lg: 0 } }}
          >
            {renderLinks()}
          </Stack>
        </Grid>
      </Grid>
    </KBox>
  );
}

// Definindo valores padrão para as props do Footer
Footer.defaultProps = {
  company: {
    href: "https://kaiocesar.my.canva.site/portfolio-dev",
    name: "Kortex",
  },
  links: [
    { href: "https://kaiocesar.my.canva.site/portfolio-dev", name: "Kortex" },
    { href: "", name: "Sobre Nós" },
    { href: "", name: "Instagram" },
    { href: "", name: "Licença" },
  ],
};

// Typechecking props para o Footer
Footer.propTypes = {
  company: PropTypes.shape({
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Footer;
