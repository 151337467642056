import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import KBox from "components/KBox";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import AddGroupModal from "./AddGroupModal";
import apiService from "utils/apiService";
import KButton from "components/KButton";
import defaultGroupPicture from "assets/images/empresa_grupo.png";

function Constructions() {
  const [searchTerm, setSearchTerm] = useState("");
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchGroups = async () => {
    try {
      const response = await apiService.groups.getAll();
      // Map over the response to include the group picture URL
      const groupsWithPictures = await Promise.all(
        response.data.map(async (group) => {
          try {
            // Fetch the group picture
            const pictureResponse = await apiService.groups.getGroupPicture(group.id);
            const imgURL = window.URL.createObjectURL(new Blob([pictureResponse.data]));
            console.log(imgURL)
            return {
              ...group,
              picture_path: imgURL,
            };
          } catch (error) {
            // Handle picture fetching error
            console.error(`Erro ao buscar imagem do grupo ${group.id}:`, error);
            return {
              ...group,
              picture_path: defaultGroupPicture,
            };
          }
        })
      );
      setProjects(groupsWithPictures);
    } catch (error) {
      console.error("Erro ao buscar grupos:", error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleAddGroup = () => {
    fetchGroups(); // Recarrega os grupos após adicionar
  };

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox p={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={8} md={10}>
            <TextField
              label="Buscar"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              inputProps={{ "aria-label": "Buscar grupos" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <KButton
              variant="contained"
              color="info"
              startIcon={<AddIcon />}
              onClick={() => setIsModalOpen(true)}
              fullWidth
            >
              Adicionar Grupo
            </KButton>
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={2}>
          {filteredProjects.map((project) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={project.id}>
              <DefaultProjectCard
                id={project.id}
                image={project.picture_path}
                title={project.name}
                description={project.description || "Sem descrição"}
                onGroupUpdate={fetchGroups} // Atualiza a lista após editar/excluir
              />
            </Grid>
          ))}
        </Grid>
      </KBox>
      <Footer />
      <AddGroupModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleAddGroup={handleAddGroup}
      />
    </DashboardLayout>
  );
}

export default Constructions;
