import React, { useState } from "react";
import { Modal, Box, TextField, Typography } from "@mui/material";
import apiService from "utils/apiService";
import KBox from "components/KBox";
import KButton from "components/KButton";

function AddTagModal({ open, onClose, onTagAdded }) {
  const [tagName, setTagName] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSave = async () => {
    if (!tagName) {
      setError(true);
      setErrorMessage("O nome da tag é obrigatório.");
      return;
    }

    try {
      const newTag = { name: tagName };
      await apiService.tags.add(newTag);

      if (onTagAdded) {
        onTagAdded();
      }

      setTagName("");
      setError(false);
      setErrorMessage("");
      onClose();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(true);
        setErrorMessage("Esta tag já existe.");
      } else {
        console.error(error);
      }
    }
  };

  const handleTagNameChange = (e) => {
    setTagName(e.target.value);
    if (error && e.target.value) {
      setError(false);
      setErrorMessage("");
    }
  };

  const handleClose = () => {
    setError(false);
    setErrorMessage("");
    setTagName("");
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <KBox mb={3}>
          <Typography variant="h6" textAlign="center">
            Cadastrar Nova Tag
          </Typography>
        </KBox>
        <KBox mb={3}>
          <TextField
            label="Nome da Tag"
            variant="outlined"
            fullWidth
            value={tagName}
            onChange={handleTagNameChange}
            required
            error={error}
            helperText={errorMessage}
          />
        </KBox>
        <KBox display="flex" justifyContent="space-between">
          <KButton variant="outlined" color="error" onClick={handleClose}>
            Cancelar
          </KButton>
          <KButton variant="contained" color="success" onClick={handleSave}>
            Cadastrar
          </KButton>
        </KBox>
      </Box>
    </Modal>
  );
}

export default AddTagModal;
