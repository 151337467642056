import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  Card,
  CardContent,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  GlobalStyles,
  CircularProgress,
} from "@mui/material";
import apiService from "utils/apiService";
import KTypography from "components/KTypography";
import KButton from "components/KButton";

function AddGroupModal({ open, handleClose, handleAddGroup }) {
  const [groupData, setGroupData] = useState({
    name: "",
    description: "",
    cnpj: "",
    status: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    cnpj: false,
    status: false,
  });

  const [selectedFile, setSelectedFile] = useState(null);  // State for the selected file
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (field) => (event) => {
    setGroupData({ ...groupData, [field]: event.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        alert("Tipo de arquivo não permitido. Por favor, envie uma imagem nos formatos JPG, JPEG ou PNG.");
        setSelectedFile(null);
        return;
      }

      const maxSizeInBytes = 15 * 1024 * 1024; // 15MB
      if (file.size > maxSizeInBytes) {
        alert("O arquivo é muito grande. O tamanho máximo permitido é 5MB.");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
    }
  };

  const validateFields = () => {
    const newErrors = {
      name: groupData.name.trim() === "",
      cnpj: groupData.cnpj.trim() === "",
      status: groupData.status.trim() === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("name", groupData.name);
      formData.append("description", groupData.description);
      formData.append("cnpj", groupData.cnpj);
      formData.append("status", groupData.status);

      try {
        // 1. Create the group
        const groupResponse = await apiService.groups.add(formData);
        const groupId = groupResponse.data.id;

        // 2. Update the group picture if a picture is selected
        if (selectedFile) {
          const pictureFormData = new FormData();
          pictureFormData.append("file", selectedFile);

          await apiService.groups.updateGroupPicture(groupId, selectedFile);
        }

        handleAddGroup();
        handleClose();
      } catch (error) {
        console.error(
          "Erro ao adicionar grupo ou atualizar a imagem:",
          error.response?.data || error.message
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Card>
        <CardContent>
          <KTypography variant="h5">Adicionar Novo Grupo</KTypography>
          <DialogContent>
            <Grid container spacing={2} paddingBottom={-10} paddingTop={1}>
              <Grid item xs={12}>
                <TextField
                  label="Nome"
                  value={groupData.name}
                  onChange={handleChange("name")}
                  fullWidth
                  variant="outlined"
                  required
                  error={errors.name}
                  helperText={errors.name ? "O nome é obrigatório" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descrição"
                  value={groupData.description}
                  onChange={handleChange("description")}
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CNPJ"
                  value={groupData.cnpj}
                  onChange={handleChange("cnpj")}
                  fullWidth
                  variant="outlined"
                  required
                  error={errors.cnpj}
                  helperText={errors.cnpj ? "O CNPJ é obrigatório" : ""}
                />
              </Grid>
              <GlobalStyles
                styles={{
                  ".MuiSelect-select": {
                    padding: "12px 14px !important",
                    height: "45px !important",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
              <Grid item xs={12} style={{ padding: "16px 0px 0px 16px" }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  required
                  error={errors.status}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={groupData.status}
                    onChange={handleChange("status")}
                    label="Status"
                    style={{
                      height: "45px",
                    }}
                  >
                    <MenuItem value="ONGOING">Em Andamento</MenuItem>
                    <MenuItem value="FINISHED">Finalizado</MenuItem>
                    <MenuItem value="CANCELED">Cancelado</MenuItem>
                  </Select>
                  {errors.status && (
                    <FormHelperText>O status é obrigatório</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file">
                  <KButton
                    variant="contained"
                    color="info"
                    component="span"
                    fullWidth
                  >
                    Selecionar Imagem do Grupo
                  </KButton>
                </label>
                {selectedFile && (
                  <FormHelperText>
                    Imagem selecionada: {selectedFile.name}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </CardContent>
        <CardActions>
          <DialogActions>
            <KButton
              onClick={handleClose}
              color="secondary"
              variant="outlined"
              disabled={isLoading}
            >
              Cancelar
            </KButton>
            <KButton
              onClick={handleSubmit}
              color="success"
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : "Adicionar"}
            </KButton>
          </DialogActions>
        </CardActions>
      </Card>
    </Dialog>
  );
}

export default AddGroupModal;
