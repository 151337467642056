/*
  SISTEMA KORTEX - AVISO LEGAL

  Este código-fonte é propriedade exclusiva da Kortex.
  A reprodução, distribuição ou modificação deste código sem autorização expressa é estritamente proibida.

  Qualquer violação dos direitos autorais ou das disposições contratuais aplicáveis
  resultará em medidas legais apropriadas.

  Todos os direitos reservados.

  KORTEX SYSTEM - LEGAL NOTICE

  This source code is the exclusive property of the Kortex.
  Reproduction, distribution, or modification of this code without explicit authorization is strictly prohibited.

  Any violation of copyright or applicable contractual provisions
  will result in appropriate legal action.

  All rights reserved.
*/
import React from "react";
import { GlobalStyles } from "@mui/material";
import { Margin } from "@mui/icons-material";

const SelectGlobalStyles = () => {
  return (
    <GlobalStyles
      styles={{
        ".MuiSelect-select": {
          padding: "12px 16px !important",
          height: "44px !important", // Garante a altura do campo de seleção
          display: "flex",
          alignItems: "center",
        },
      }}
    />
  );
};

export default SelectGlobalStyles;
