import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import { useAuth } from "../authentication/AuthContext/index";
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import apiService from "utils/apiService";
import usersData from "../authentication/AuthContext/data/db.json";

function Overview() {
  const { currentUser } = useAuth(); // currentUser deve conter um ID válido
  const [groups, setGroups] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tempDescription, setTempDescription] = useState("");
  const [users, setUsers] = useState([]);
  const scrollContainerRef = useRef(null);

  const fetchProfilePicture = useCallback(async () => {
    try {
      const response = await apiService.users.getProfilePicture();
      const imgURL = window.URL.createObjectURL(new Blob([response.data]));

      setUsers((prevUsers) => {
        const updatedUsers = prevUsers.map((user) => {
          if (user.email === currentUser.email) {
            return { ...user, foto_perfil: imgURL };
          }
          return user;
        });
        console.log(updatedUsers);
        return updatedUsers;
      });
    } catch (error) {
      console.error("Erro ao buscar foto de perfil:", error);
    }
  }, [currentUser.email]);

  const activeUsers = useMemo(() => {
    // return users.filter((user) => user.email !== currentUser.email);
    return users;
  }, [users, currentUser.email]);

  useEffect(() => {
    setUsers(usersData.users);
    fetchProfilePicture();
  }, [fetchProfilePicture]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleWheelScroll = (event) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        const scrollAmount = event.deltaY * 4;
        scrollContainer.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handleWheelScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("wheel", handleWheelScroll);
      }
    };
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await apiService.groups.getAll();
      setGroups(response.data);
    } catch (error) {
      console.error("Erro ao buscar grupos:", error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleEditClick = () => {
    setIsDialogOpen(true);
  };

  const handleSaveClick = () => {
    currentUser.description = tempDescription;
    setIsDialogOpen(false);
  };

  const handleCancelClick = () => {
    setTempDescription(currentUser.description);
    setIsDialogOpen(false);
  };

  const handleDescricaoChange = (event) => {
    setTempDescription(event.target.value);
  };

  if (!currentUser) {
    return <div>Carregando...</div>;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox mb={2} />
      <Header>
        <KBox mt={5} mb={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" style={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Descrição"
                description={
                  currentUser.description || "Sem descrição disponível"
                } // Valor padrão
                info={{
                  nome: currentUser.name,
                  celular: currentUser.phone,
                  email: currentUser.email,
                  cargo: currentUser.role,
                }}
                action={{
                  label: "Editar",
                  tooltip: "Clique para editar a descrição",
                  route: "",
                  color: "primary",
                  onClick: handleEditClick,
                }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>

          </Grid>
        </KBox>
        <KBox pt={2} px={2} lineHeight={1.25}>
          <KTypography variant="h6" fontWeight="medium">
            Grupos
          </KTypography>
          <KBox mb={1}>
            <KTypography variant="button" color="text" fontWeight="light">
              Navegue pelos grupos e explore os diferentes projetos disponíveis.
            </KTypography>
          </KBox>
        </KBox>
        <KBox
          p={2}
          ref={scrollContainerRef}
          sx={{
            overflowX: "auto",
            display: "flex",
            cursor: "grab",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.03)",
            },
            "&:active": {
              cursor: "grabbing",
            },
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              height: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
            },
          }}
        >
          <Grid container spacing={2} wrap="nowrap">
            {groups.map((group) => (
              <Grid item xs={12} md={6} xl={3} key={group.id}>
                <DefaultProjectCard
                  id={group.id}
                  image={group.picture_path || "default-image-path"}
                  title={group.name}
                  description={group.description || "Sem descrição"}
                  onGroupUpdate={fetchGroups} // Atualiza a lista após editar/excluir
                />
              </Grid>
            ))}
          </Grid>
        </KBox>
      </Header>
      <Footer />

      <Dialog open={isDialogOpen} onClose={handleCancelClick}>
        <DialogTitle>Editar Descrição</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edite a descrição do perfil abaixo:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="descricao"
            label="Descrição"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={tempDescription}
            onChange={handleDescricaoChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSaveClick} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Overview;
