/*
  SISTEMA KORTEX - AVISO LEGAL

  Este código-fonte é propriedade exclusiva da Kortex.
  A reprodução, distribuição ou modificação deste código sem autorização expressa é estritamente proibida.

  Qualquer violação dos direitos autorais ou das disposições contratuais aplicáveis
  resultará em medidas legais apropriadas.

  Todos os direitos reservados.

  KORTEX SYSTEM - LEGAL NOTICE

  This source code is the exclusive property of the Kortex.
  Reproduction, distribution, or modification of this code without explicit authorization is strictly prohibited.

  Any violation of copyright or applicable contractual provisions
  will result in appropriate legal action.

  All rights reserved.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for KAvatar
import KAvatarRoot from "components/KAvatar/KAvatarRoot";

const KAvatar = forwardRef(({ bgColor, size, shadow, ...rest }, ref) => (
  <KAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
));

// Setting default values for the props of KAvatar
KAvatar.defaultProps = {
  bgColor: "transparent",
  size: "md",
  shadow: "none",
};

// Typechecking props for the KAvatar
KAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf([
    "none",
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "xxl",
    "inset",
  ]),
};

export default KAvatar;
