import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import KButton from "components/KButton";

function ConfirmationModal({ open, onClose, onConfirm }) {
  return (
    <Modal open={open} onClose={onClose}>
      <KBox
        position="absolute"
        top="50%"
        left="50%"
        width="420px"
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <Card>
          <KBox p={3} textAlign="center">
            <KBox mb={0} fontSize={"40px"}>
              <Icon sx={{ color: "warning.main" }}>warning</Icon>
            </KBox>
            <KTypography variant="h4" fontWeight="bold" gutterBottom>
              Confirmação
            </KTypography>
            <KTypography variant="body1" sx={{ mb: 3 }}>
              Tem certeza de que deseja limpar todos os dados? Esta ação não
              pode ser desfeita.
            </KTypography>
            <KBox display="flex" justifyContent="center" gap={2}>
              <KButton
                variant="contained"
                color="error"
                onClick={onConfirm}
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  px: 3,
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                Limpar
              </KButton>
              <KButton
                variant="outlined"
                onClick={onClose}
                sx={{
                  borderRadius: 2,
                  textTransform: "none",
                  px: 3,
                  borderColor: "text.secondary",
                  color: "text.primary",
                  "&:hover": {
                    borderColor: "text.primary",
                    color: "text.primary",
                  },
                }}
              >
                Cancelar
              </KButton>
            </KBox>
          </KBox>
        </Card>
      </KBox>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;
