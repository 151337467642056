import React from "react";
import { Grid, TextField, InputAdornment, Tooltip } from "@mui/material";
import Person from "@mui/icons-material/Person";
import Assignment from "@mui/icons-material/Assignment";

function RequisitionTab({ formData, onChange }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <Tooltip title="Nome da pessoa que fez a solicitação." arrow>
          <TextField
            label="Solicitante"
            variant="outlined"
            fullWidth
            value={formData.requester}
            onChange={(e) => onChange("requester", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Tooltip
          title="Mensagens ou instruções específicas da solicitação."
          arrow
        >
          <TextField
            label="Mensagem"
            variant="outlined"
            fullWidth
            value={formData.requestMessage}
            onChange={(e) => onChange("requestMessage", e.target.value)}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Tooltip title="Número de identificação da requisição." arrow>
          <TextField
            label="Nº Requisição"
            variant="outlined"
            fullWidth
            value={formData.requestNumber}
            onChange={(e) => onChange("requestNumber", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Assignment />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default RequisitionTab;
