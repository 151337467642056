import React, { useCallback, useEffect, useRef, useState } from "react";
import KBox from "components/KBox";
import KAvatar from "components/KAvatar";
import Icon from "@mui/material/Icon";
import KSnackbar from "components/KSnackbar";
import { useProfilePicture } from "layouts/profile/components/Header/ProfilePictureProvider";
import apiService from "utils/apiService";
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress

function ProfilePicture({ canChangePicture, sx }) {
  const fileInputRef = useRef(null);
  const [hover, setHover] = useState(false);
  const { loading, profilePicture, setProfilePicture } = useProfilePicture();

  const [selectedFile, setSelectedFile] = useState(null);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [notificationContent, setNotificationContent] = useState("");
  const [notificationDateTime, setNotificationDateTime] = useState("");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        setNotificationContent(
          "Tipo de arquivo não permitido. Por favor, envie uma imagem nos formatos JPG, JPEG ou PNG."
        );
        setNotificationDateTime(new Date().toLocaleString());
        openErrorSB();
        setSelectedFile(null);
        return;
      }

      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSizeInBytes) {
        setNotificationContent(
          "O arquivo é muito grande. O tamanho máximo permitido é 5MB."
        );
        setNotificationDateTime(new Date().toLocaleString());
        openErrorSB();
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  };

  const handleUpload = useCallback(async () => {
    if (!selectedFile) {
      console.error("Nenhum arquivo válido para upload.");
      return;
    }

    try {
      await apiService.users.updateProfilePicture(selectedFile);
      const imgURL = window.URL.createObjectURL(selectedFile);
      setProfilePicture(imgURL);
      setNotificationContent("Foto de perfil atualizada com sucesso!");
      setNotificationDateTime(new Date().toLocaleString());
      openSuccessSB();
    } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      setNotificationContent(
        "Ocorreu um erro ao atualizar a foto de perfil. Tente novamente mais tarde."
      );
      setNotificationDateTime(new Date().toLocaleString());
      openErrorSB();
    }
  }, [selectedFile, setProfilePicture]);

  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile, handleUpload]);

  if (loading) {
    return (
      <KBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <CircularProgress />
      </KBox>
    );
  }

  return (
    <>
      <KBox
        position="relative"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <KAvatar
          src={profilePicture}
          alt="profile-image"
          size="xl"
          shadow="sm"
          sx={
            sx || {
              "& img": {
                objectFit: "cover",
                borderRadius: "50%",
                width: "100%",
                height: "100%",
              },
            }
          }
        />
        {canChangePicture && hover && (
          <KBox
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
          >
            <Icon sx={{ color: "white", fontSize: 30 }}>edit</Icon>
          </KBox>
        )}
        {canChangePicture && (
          <input
            type="file"
            accept="image/jpeg, image/png"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        )}
      </KBox>
      <KSnackbar
        color="success"
        icon="check"
        title="Sucesso"
        content={notificationContent}
        dateTime={notificationDateTime}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />

      <KSnackbar
        color="error"
        icon="error"
        title="Erro"
        content={notificationContent}
        dateTime={notificationDateTime}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    </>
  );
}

export default ProfilePicture;
