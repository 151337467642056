import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Card, CardHeader, Box, Tabs, Tab, Grid, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import KBox from "components/KBox";
import KButton from "components/KButton";
import Icon from "@mui/material/Icon";
import KTable from "components/Table";
import apiService from "utils/apiService";
import AddTagModal from "./AddTagModal";
import DateTimeConfig from "utils/dateTimeConfig";

// Configurações das colunas da tabela de Itens
// Configurações das colunas da tabela de Itens
const itemColumns = [
  { field: "id", headerName: "ID", flex: 0.3 },
  { field: "name", headerName: "Nome", flex: 1 },
  { field: "brand", headerName: "Marca", flex: 1 },
  {
    field: "tags",
    headerName: "Tags",
    flex: 1,
    renderCell: (params) => (
      <Box>
        {Array.isArray(params.value) && params.value.map((tag) => (
          <Chip
            key={tag.id}
            label={tag.name}
            size="small"
            sx={{ margin: "2px" }}
          />
        ))}
      </Box>
    ),
  },
  { field: "description", headerName: "Descrição", flex: 1.5 },
  {
    field: "created_at",
    headerName: "Data de Criação",
    flex: 1,
    renderCell: (params) => {
      // Verifica se o valor é uma data válida antes de renderizar
      const date = new Date(params.value);
      return isNaN(date.getTime()) ? "" : date.toLocaleDateString();
    },
  },
  {
    field: "updated_at",
    headerName: "Última Atualização",
    flex: 1,
    renderCell: (params) => {
      // Verifica se o valor é uma data válida antes de renderizar
      const date = new Date(params.value);
      return isNaN(date.getTime()) ? "" : date.toLocaleDateString();
    },
  },
];

const tagColumns = [
  { field: "id", headerName: "ID", flex: 0.3 },
  { field: "name", headerName: "Nome", flex: 1 },
  {
    field: "created_at",
    headerName: "Data de Criação",
    flex: 1,
    renderCell: (params) => {
      const date = new Date(params.value);
      return isNaN(date.getTime()) ? "" : date.toLocaleDateString();
    },
  },
  {
    field: "updated_at",
    headerName: "Última Atualização",
    flex: 1,
    renderCell: (params) => {
      const date = new Date(params.value);
      return isNaN(date.getTime()) ? "" : date.toLocaleDateString();
    },
  },
];



function ItemAndTagManagement() {
  const [activeTab, setActiveTab] = useState(0);
  const [items, setItems] = useState([]);
  const [tags, setTags] = useState([]);
  const [isAddTagModalOpen, setAddTagModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();

  const fetchItems = useCallback(async () => {
    try {
      const itemsResponse = await apiService.item_models.getAll();
      setItems(Array.isArray(itemsResponse.data) ? itemsResponse.data : []);
      console.log("Itens carregados:", itemsResponse.data);
    } catch (error) {
      console.error("Erro ao buscar itens:", error);
      setItems([]);
    }
  }, []);

  const fetchTags = useCallback(async () => {
    try {
      const tagsResponse = await apiService.tags.getAll();
      setTags(Array.isArray(tagsResponse.data) ? tagsResponse.data : []);
      console.log("Tags carregadas:", tagsResponse.data);
    } catch (error) {
      console.error("Erro ao buscar tags:", error);
      setTags([]);
    }
  }, []);

  useEffect(() => {
    console.log("Carregando dados...");
    fetchItems();
    fetchTags();
  }, [fetchItems, fetchTags]); // <-- Garante que fetchItems e fetchTags sejam chamadas apenas uma vez quando o componente é montado

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  const handleAddItem = useCallback(() => {
    navigate("/adicionar-item");
  }, [navigate]);

  const handleOpenAddTagModal = () => {
    setAddTagModalOpen(true);
  };

  const handleCloseAddTagModal = () => {
    setAddTagModalOpen(false);
  };

  const handleTagAdded = useCallback(async () => {
    try {
      await fetchTags();
      setAddTagModalOpen(false);
    } catch (error) {
      console.error("Erro ao recarregar tags:", error);
    }
  }, [fetchTags]);

  const deleteItem = useCallback(
    async (rowId) => {
      try {
        await apiService.item_models.delete(rowId);
        await fetchItems();
      } catch (error) {
        console.error("Erro ao deletar item:", error);
      }
    },
    [fetchItems]
  );

  const deleteTag = useCallback(
    async (rowId) => {
      try {
        await apiService.tags.delete(rowId);
        await fetchTags();
        await fetchItems();
      } catch (error) {
        console.error("Erro ao deletar tag:", error);
      }
    },
    [fetchTags, fetchItems]
  );

  const handleUpdateItem = useCallback(
    async (updatedItem) => {
      console.log(updatedItem);
      try {
        await apiService.item_models.update(updatedItem.id, updatedItem);
        await fetchItems();
      } catch (error) {
        console.error("Erro ao atualizar item:", error);
      }
    },
    [fetchItems]
  );

  const handleUpdateTag = useCallback(
    async (updatedTag) => {
      try {
        await apiService.tags.update(updatedTag.id, updatedTag);
        await fetchTags();
      } catch (error) {
        console.error("Erro ao atualizar tag:", error);
      }
    },
    [fetchTags]
  );

  const handleSelection = useCallback((selectedRows) => {
    console.log('Linhas selecionadas:', selectedRows); // Adicionado console.log para exibir as linhas selecionadas
    setSelectedRows(selectedRows);
  }, []);

  const memoizedItemColumns = useMemo(() => itemColumns, []);
  const memoizedTagColumns = useMemo(() => tagColumns, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox pb={6} px={2}>
        <Card sx={{ boxShadow: 4, borderRadius: 2 }}>
          <CardHeader
            title="Gestão de Itens e Tags"
            subheader="Visualize e gerencie os itens e tags cadastrados"
            titleTypographyProps={{ variant: "h4", fontWeight: "bold" }}
            subheaderTypographyProps={{
              variant: "subtitle1",
              color: "textSecondary",
            }}
            sx={{ textAlign: "center", py: 2 }}
          />
          <KBox mb={3}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={4}>
                <KButton
                  variant="contained"
                  color="info"
                  fullWidth
                  onClick={handleAddItem}
                  startIcon={<Icon>add</Icon>}
                >
                  Cadastrar Novo Item
                </KButton>
              </Grid>
              <Grid item xs={12} md={4}>
                <KButton
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={handleOpenAddTagModal}
                  startIcon={<Icon>label</Icon>}
                >
                  Cadastrar Nova Tag
                </KButton>
              </Grid>
            </Grid>
          </KBox>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab label="Itens" />
            <Tab label="Tags" />
          </Tabs>
          <Box p={3}>
            {activeTab === 0 && (
              <KTable
                tagsList={tags}
                columns={memoizedItemColumns}
                rows={items}
                title="Lista de Itens"
                onEdit={handleUpdateItem}
                onDelete={deleteItem}
                onSelect={handleSelection}
                editableFields={{
                  name: "Nome",
                  brand: "Marca",
                  tags: "Tags",
                  description: "Descrição",
                }}
              />
            )}
            {activeTab === 1 && (
              <KTable
                columns={memoizedTagColumns}
                rows={tags}
                title="Lista de Tags"
                onEdit={handleUpdateTag}
                onDelete={deleteTag}
                onSelect={handleSelection}
                editableFields={{ name: "Nome" }}
              />
            )}
          </Box>
        </Card>
      </KBox>
      <Footer />

      <AddTagModal
        open={isAddTagModalOpen}
        onClose={handleCloseAddTagModal}
        onTagAdded={handleTagAdded}
      />
    </DashboardLayout>
  );
}

export default ItemAndTagManagement;
