// DashboardLayout.js

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import KBox from "components/KBox";
import { useMaterialUIController, setLayout } from "context";
import Loader from "components/Loader/index"; // Importando o Loader

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor } = controller; // Pegar a cor selecionada
  const theme = useTheme(); // Obter o tema atual
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true); // Estado de carregamento

  // Mapear a cor do sidenav para uma cor do tema
  const colorMap = {
    primary: theme.palette.primary.main,
    dark: theme.palette.grey[900],
    info: theme.palette.info.main,
    success: theme.palette.success.main,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
  };

  const loaderColor = colorMap[sidenavColor] || theme.palette.primary.main;

  useEffect(() => {
    // Simula um carregamento de dados, substitua pela sua lógica real
    const simulateLoading = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1)); // Simulação de carregamento
        setLoading(false); // Dados carregados, remover loader
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        setLoading(false); // Remover loader mesmo em caso de erro
      }
    };

    simulateLoading();
    setLayout(dispatch, "dashboard");
  }, [pathname, dispatch]);

  return (
    <KBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(270),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {loading ? <Loader open={loading} color={loaderColor} /> : children}{" "}
      {/* Passa a cor correta */}
    </KBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
