import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import AccountBalance from "@mui/icons-material/AccountBalance";
import DirectionsCar from "@mui/icons-material/DirectionsCar";
import LocalShipping from "@mui/icons-material/LocalShipping";
import Verified from "@mui/icons-material/Verified";

function FreightTab({ formData, onChange }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Nome da transportadora responsável pelo frete." arrow>
          <TextField
            label="Transportadora"
            variant="outlined"
            fullWidth
            value={formData.carrier}
            onChange={(e) => onChange("carrier", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBalance />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Selecione o tipo de frete." arrow>
          <TextField
            label="Tipo de Frete"
            variant="outlined"
            fullWidth
            select
            value={formData.freightType}
            onChange={(e) => onChange("freightType", e.target.value)}
          >
            <MenuItem value="CIF">CIF por conta do fornecedor</MenuItem>
            <MenuItem value="FOB">FOB por conta do comprador</MenuItem>
            <MenuItem value="Outros">Outros</MenuItem>
          </TextField>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Tipo de veículo utilizado no transporte." arrow>
          <TextField
            label="Tipo de Veículo"
            variant="outlined"
            fullWidth
            value={formData.vehicleType}
            onChange={(e) => onChange("vehicleType", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DirectionsCar />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="Placa do veículo." arrow>
          <TextField
            label="Placa do Veículo"
            variant="outlined"
            fullWidth
            value={formData.vehiclePlate}
            onChange={(e) => onChange("vehiclePlate", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalShipping />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Informe se houve fiscalização." arrow>
          <TextField
            label="Fiscalização"
            variant="outlined"
            fullWidth
            value={formData.inspection}
            onChange={(e) => onChange("inspection", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Verified />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default FreightTab;
