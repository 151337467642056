import React from "react";
import { Grid, TextField, InputAdornment, Tooltip } from "@mui/material";
import Note from "@mui/icons-material/Note";

function ObservationsTab({ formData, onChange }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Tooltip
          title="Adicione qualquer observação relevante sobre a entrada."
          arrow
        >
          <TextField
            label="Observações"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={formData.observations}
            onChange={(e) => onChange("observations", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Note />
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default ObservationsTab;
