

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import KBox from "components/KBox";
import KTypography from "components/KTypography";

function PlatformSettings() {
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(false);

  return (
    <Card sx={{ boxShadow: "none" }}>
      <KBox p={2}>
        <KTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Configuração
        </KTypography>
      </KBox>
      <KBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <KTypography
          variant="caption"
          fontWeight="bold"
          color="text"
          textTransform="uppercase"
        >
          Conta
        </KTypography>

        <KBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <KBox mt={0.5}>
            <Switch
              checked={answersPost}
              onChange={() => setAnswersPost(!answersPost)}
            />
          </KBox>
          <KBox width="80%" ml={0.5}>
            <KTypography variant="button" fontWeight="regular" color="text">
              Envie-me um e-mail quando tiver solicitação de compra.
            </KTypography>
          </KBox>
        </KBox>
        <KBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <KBox mt={0.5}>
            <Switch
              checked={mentionsMe}
              onChange={() => setMentionsMe(!mentionsMe)}
            />
          </KBox>
          <KBox width="80%" ml={0.5}>
            <KTypography variant="button" fontWeight="regular" color="text">
              Envie-me um e-mail quando for uma notificação importante.
            </KTypography>
          </KBox>
        </KBox>
      </KBox>
    </Card>
  );
}

export default PlatformSettings;
