import React, { useCallback, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Grid, Card, Divider, Breadcrumbs, Icon, Tabs, Tab } from "@mui/material";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EditGroupModal from "examples/Cards/ProjectCards/DefaultProjectCard/EditGroupModal";
import KTable from "components/Table";
import apiService from "utils/apiService";

function ProjectDetails() {
  const { id } = useParams();
  const [group, setGroup] = useState(null);
  const [loadingGroup, setLoadingGroup] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // Estado para controlar a aba ativa

  useEffect(() => {
    console.log("ID do grupo atual:", id);

    const fetchGroupDetails = async () => {
      try {
        const response = await apiService.groups.getById(id);
        const pictureResponse = await apiService.groups.getGroupPicture(id);
        const imgURL = window.URL.createObjectURL(new Blob([pictureResponse.data]));
        console.log(imgURL)
        setGroup({
          ...response.data,
          picture_path: imgURL,
        });
      } catch (error) {
        console.error("Erro ao buscar detalhes do grupo:", error);
      } finally {
        setLoadingGroup(false);
      }
    };

    const fetchItemsByGroup = async () => {
      try {
        const itemsResponse = await apiService.items.getActive();
        const filteredItems = itemsResponse.data.filter(
          (item) => item.group_id === parseInt(id)
        );
        setItems(filteredItems);
      } catch (error) {
        console.error("Erro ao buscar itens:", error);
      } finally {
        setLoadingItems(false);
      }
    };

    fetchGroupDetails();
    fetchItemsByGroup();
  }, [id]);

  const handleEditGroup = (updatedGroup) => {
    setGroup(updatedGroup);
    setEditModalOpen(false);
  };

  const handleGroupUpdate = () => {
    setEditModalOpen(false);
  };

  const handleEditItem = async (updatedRow) => {
    try {
      const itemId = updatedRow.id;
      await apiService.items.update(itemId, updatedRow);
      const updatedItems = items.map((item) =>
        item.id === itemId ? updatedRow : item
      );
      setItems(updatedItems);
    } catch (error) {
      console.error("Erro ao editar o item:", error);
    }
  };

  const handleDeleteItem = async (rowId) => {
    try {
      await apiService.items.delete(rowId);
      setItems((prevItems) => prevItems.filter((item) => item.id !== rowId));
    } catch (error) {
      console.error("Erro ao deletar o item:", error);
    }
  };

  const handleSelection = useCallback((selectedRows) => {
    setSelectedRows(selectedRows);
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Definição das colunas da tabela, incluindo a nova coluna "Item" para "item_model_id"
  const itemColumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "code", headerName: "Cod. Produto", width: 150 },
    { field: "item_model_name", headerName: "Item", width: 200 },
    { field: "ncm", headerName: "NCM", width: 100 },
    { field: "measurement_unit", headerName: "Unid.", width: 100 },
    { field: "quantity", headerName: "Quantidade", width: 100 },
    { field: "price", headerName: "Preço Unit", width: 100 },
    { field: "stock_inflow_document_number", headerName: "Num. de Documento", width: 170 },
    { field: "group_name", headerName: "Grupo", width: 150 },
    { field: "supplier_name", headerName: "Fornecedor", width: 150 },
    { field: "created_at", headerName: "Data Criação", width: 150 },
    { field: "updated_at", headerName: "Data de Update", width: 150 },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox py={3} px={2}>
        {(loadingGroup || loadingItems) && (
          <KTypography variant="h6" align="center">
            Carregando...
          </KTypography>
        )}

        {/* Card de Detalhes do Grupo */}
        {!loadingGroup && group && (
          <Card sx={{ mb: 4, p: 3, boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}>
            {/* Breadcrumbs */}
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                <KTypography variant="body2" sx={{ color: "text.primary" }}>
                  Home
                </KTypography>
              </Link>
              <Link to="/groups" style={{ textDecoration: "none", color: "inherit" }}>
                <KTypography variant="body2" sx={{ color: "text.primary" }}>
                  Grupos
                </KTypography>
              </Link>
              <KTypography variant="body2" sx={{ color: "text.secondary" }}>
                {group.name}
              </KTypography>
            </Breadcrumbs>

            {/* Imagem do Grupo */}
            <KBox
              sx={{
                width: "100%",
                height: "220px",
                overflow: "hidden",
                borderRadius: "8px",
                mb: 3,
                backgroundColor: "grey.300",
              }}
            >
              {group.picture_path ? (
                <KBox
                  component="img"
                  src={group.picture_path}
                  alt={group.name}
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              ) : (
                <KTypography
                  variant="h6"
                  align="center"
                  sx={{ color: "text.secondary", mt: 8 }}
                >
                  Nenhuma imagem disponível
                </KTypography>
              )}
            </KBox>

            <KBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <KTypography variant="h4" fontWeight="bold" color="info">
                {group.name}
              </KTypography>
              <Icon
                fontSize="large"
                color="info"
                onClick={() => setEditModalOpen(true)}
                sx={{ cursor: "pointer" }}
              >
                edit
              </Icon>
            </KBox>

            <Divider />

            <KBox mt={3} mb={2}>
              <KTypography variant="h5" fontWeight="medium">
                Descrição
              </KTypography>
              <KTypography variant="body1">
                {group.description || "Nenhuma descrição disponível."}
              </KTypography>
            </KBox>

            <Divider />

            <KBox mt={3}>
              <KTypography variant="h5" fontWeight="medium" mb={2}>
                Detalhes do Grupo
              </KTypography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <KTypography variant="body1">
                    <strong>CNPJ:</strong> {group.cnpj || "N/A"}
                  </KTypography>
                  <KTypography variant="body1">
                    <strong>Status:</strong> {group.status || "N/A"}
                  </KTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KTypography variant="body1">
                    <strong>Criado em:</strong>{" "}
                    {new Date(group.created_at).toLocaleDateString()}
                  </KTypography>
                  <KTypography variant="body1">
                    <strong>Última atualização:</strong>{" "}
                    {new Date(group.updated_at).toLocaleDateString()}
                  </KTypography>
                </Grid>
              </Grid>
            </KBox>

            <EditGroupModal
              open={editModalOpen}
              handleClose={() => setEditModalOpen(false)}
              groupId={id}
              groupData={group}
              handleEditGroup={handleEditGroup}
              onGroupUpdate={handleGroupUpdate}
            />
          </Card>
        )}

        {/* Card de Itens do Grupo com Abas */}
        {!loadingItems && (
          <Card sx={{ p: 3, boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", height: "1000px", overflow: "auto" }}> {/* Altura fixa definida para o segundo card */}
            {/* Abas para alternar entre Itens do Grupo e Configuração */}


            {/* Conteúdo das Abas */}
            {activeTab === 0 && (
              <KBox mt={3}>
                <KTypography variant="h5" fontWeight="medium">Itens do Grupo</KTypography>
                <Divider sx={{ my: 2 }} />
                <KTable
                  columns={itemColumns}
                  rows={items}
                  title="Itens do Grupo"
                  onEdit={handleEditItem}
                  onDelete={handleDeleteItem}
                  onSelect={handleSelection}
                  showQuantityColumn={false}
                  showEditButton={false}
                  showDeleteButton={false}
                  editableFields={["code", "ncm", "price", "quantity", "item_model_id"]}
                />
              </KBox>
            )}

            {activeTab === 1 && (
              <KBox mt={3}>
                <KTypography variant="h5" fontWeight="medium">Configuração do Grupo</KTypography>
                <Divider sx={{ my: 2 }} />
                <KBox mt={3} mb={2}>
                  <KTypography variant="h5" fontWeight="medium">Descrição</KTypography>
                  <KTypography variant="body1">{group.description || "Nenhuma descrição disponível."}</KTypography>
                </KBox>
                <Divider />
                <KBox mt={3}>
                  <KTypography variant="h5" fontWeight="medium" mb={2}>Detalhes do Grupo</KTypography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <KTypography variant="body1"><strong>CNPJ:</strong> {group.cnpj || "N/A"}</KTypography>
                      <KTypography variant="body1"><strong>Status:</strong> {group.status || "N/A"}</KTypography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <KTypography variant="body1"><strong>Criado em:</strong> {new Date(group.created_at).toLocaleDateString()}</KTypography>
                      <KTypography variant="body1"><strong>Última atualização:</strong> {new Date(group.updated_at).toLocaleDateString()}</KTypography>
                    </Grid>
                  </Grid>
                </KBox>
              </KBox>
            )}
          </Card>
        )}
      </KBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProjectDetails;
