import axiosInstance from "../axiosInstance";

const apiService = {
  groups: {
    getAll: () => axiosInstance.get("/groups/"),
    getOngoingCount: () => axiosInstance.get("/groups/ongoing/count"),
    getGroupPicture: (id) =>
      axiosInstance.get(`/groups/${id}/group-picture`, {
        responseType: "blob",
      }),
    getById: (id) => axiosInstance.get(`/groups/${id}`),
    add: (data) => axiosInstance.post("/groups/", data),
    update: (id, data) => axiosInstance.patch(`/groups/${id}`, data),
    updateGroupPicture: (id, file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axiosInstance.patch(`/groups/${id}/group-picture`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    delete: (id) => axiosInstance.delete(`/groups/${id}`),
  },

  suppliers: {
    getAll: () => axiosInstance.get("/suppliers/"),
    getCount: () => axiosInstance.get("/suppliers/count"),
    getTop: () => axiosInstance.get("/suppliers/most-stock-inflows"), // falta puxar a avaliação deles
    getTopRated: (n) =>
      axiosInstance.get("/suppliers/top-rated", {
        params: { number_of_suppliers: n },
      }),
    getHighestRated: () => axiosInstance.get("/suppliers/highest-rated"),
    getLowestRated: () => axiosInstance.get("/suppliers/lowest-rated"),
    getById: (id) => axiosInstance.get(`/suppliers/${id}`),
    add: (data) => axiosInstance.post("/suppliers/", data),
    update: (id, data) => axiosInstance.patch(`/suppliers/${id}`, data),
    delete: (id) => axiosInstance.delete(`/suppliers/${id}`),
  },

  tags: {
    getAll: () => axiosInstance.get("/tags/"),
    getById: (id) => axiosInstance.get(`/tags/${id}`),
    add: (data) => axiosInstance.post("/tags/", data),
    update: (id, data) => axiosInstance.patch(`/tags/${id}`, data),
    delete: (id) => axiosInstance.delete(`/tags/${id}`),
  },

  item_models: {
    getAll: () => axiosInstance.get("/item-models/"),
    getInCriticalStockAmountCount: () =>
      axiosInstance.get("/item-models/critical-count"),
    getInWarningStockAmountCount: () =>
      axiosInstance.get("/item-models/warning-count"),
    getById: (id) => axiosInstance.get(`/item-models/${id}`),
    add: (data) => axiosInstance.post("/item-models/", data),
    update: (id, data) => axiosInstance.patch(`/item-models/${id}`, data),
    delete: (id) => axiosInstance.delete(`/item-models/${id}`),
  },

  item_groups: {
    getAll: () => axiosInstance.get("/item-groups/"),
    getAllByStockIn: (stock_inflow_id) =>
      axiosInstance.get(`/item-groups/by-stock-inflow/${stock_inflow_id}`),
    getById: (id) => axiosInstance.get(`/item-groups/${id}`),
    add: (data) => axiosInstance.post("/item-groups/", data),
    update: (id, data) => axiosInstance.patch(`/item-groups/${id}`, data),
    delete: (id) => axiosInstance.delete(`/item-groups/${id}`),
  },

  items: {
    getAll: () => axiosInstance.get("/items/"),
    getActive: () => axiosInstance.get("/items/active-items"),
    getById: (id) => axiosInstance.get(`/items/${id}`),
    add: (data) => axiosInstance.post("/items/", data),
    update: (id, data) => axiosInstance.patch(`/items/${id}`, data),
    delete: (id) => axiosInstance.delete(`/items/${id}`),
    getCount: () => axiosInstance.get("/items/count"),
  },

  stock_inflows: {
    getAll: () => axiosInstance.get("/stock-inflows/"),
    getById: (id) => axiosInstance.get(`/stock-inflows/${id}`),
    add: (data) => axiosInstance.post("/stock-inflows/", data),
    update: (id, data) => axiosInstance.patch(`/stock-inflows/${id}`, data),
    delete: (id) => axiosInstance.delete(`/stock-inflows/${id}`),
  },
  stock_outflows: {
    getAll: () => axiosInstance.get("/stock-outflows"),
    getById: (id) => axiosInstance.get(`/stock-outflows/${id}`),
    add: (data) => axiosInstance.post("/stock-outflows", data),
    update: (id, data) => axiosInstance.patch(`/stock-outflows/${id}`, data),
  },

  movements: {
    getAll: () => axiosInstance.get("/movements/"),
    getCurrentMonthCount: () =>
      axiosInstance.get("/movements/count/current-month"),
    getPreviousMonthCount: () =>
      axiosInstance.get("/movements/count/previous-month"),
    // end point movement recentes
    getById: (id) => axiosInstance.get(`/movements/${id}`),
    add: (data) => axiosInstance.post("/movements/", data),
    update: (id, data) => axiosInstance.patch(`/movements/${id}`, data),
    approve: (id) => axiosInstance.patch(`/movements/${id}/approve`),
    delete: (id) => axiosInstance.delete(`/movements/${id}`),
  },

  users: {
    getProfilePicture: () =>
      axiosInstance.get(`/users/me/profile-picture`, { responseType: "blob" }),

    updateProfilePicture: (file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axiosInstance.patch(`/users/me/profile-picture`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },

  historyEntries: {
    getAll: () => axiosInstance.get("/history-entries/"),
    getCount: () => axiosInstance.get("/history-entries/count"),
    getCountLast24Hours: () =>
      axiosInstance.get("/history-entries/count-last-24-hours"),
    getById: (id) => axiosInstance.get(`/history-entries/${id}`),
    getByActor: (actor_id) =>
      axiosInstance.get(`/history-entries/actor/${actor_id}`),
    getByActionType: (action_type) =>
      axiosInstance.get(`/history-entries/action/${action_type}`),
  },
};

export default apiService;
