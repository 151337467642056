import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch"; // Certifique-se de importar o Switch
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import KInput from "components/KInput";
import KButton from "components/KButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useAuth } from "../AuthContext/index";
import logo from "assets/images/logos/logo1.png"; // Logo da sua empresa
import backgroundImage from "assets/images/background/background.png";
import secondaryLogo from "assets/images/logos/logo1.png"; // Logo da Estrutural Engenharia

function SignIn() {
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();

  const handleSetRememberMe = () => setRememberMe((prev) => !prev);
  const handleTogglePasswordVisibility = () => setShowPassword((prev) => !prev);

  // Validação de formulário com Yup
  const validationSchema = Yup.object({
    email: Yup.string().email("Email inválido").required("Email é obrigatório"),
    password: Yup.string().required("Senha é obrigatória"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await login(values.email, values.password);
      } catch (error) {
        console.error("Falha no login:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  // Estilos CSS-in-JS
  const styles = {
    logo: {
      height: "250px", // Tamanho da logo configurável
      marginRight: "10px",
    },
    input: {
      borderRadius: 1,
      "&:hover": { borderColor: "#1A73E8" },
      "&:focus": { borderColor: "#1A73E8" },
      transition: "border-color 0.3s ease-in-out",
      color: "#FFFFFF", // Cor da fonte para melhor contraste
      "& .MuiInputBase-input": {
        color: "#FFFFFF", // Cor do texto do input
      },
      "& .MuiFormLabel-root": {
        color: "#A6ACCD", // Cor do label quando não focado
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#FFFFFF", // Cor do label quando focado
      },
    },
    errorText: {
      color: "red", // Cor vermelha para as mensagens de erro
      fontSize: "12px",
      marginTop: "4px",
    },
    button: {
      padding: "12px 0",
      fontSize: "16px",
      fontWeight: "bold",
      backgroundColor: "#1A73E8",
      borderRadius: "24px",
      "&:hover": {
        backgroundColor: "#1765CC",
      },
      transition: "background-color 0.3s ease-in-out",
      color: "#FFFFFF", // Cor do texto do botão
    },
    iconButton: {
      color: "#FFFFFF", // Cor branca para o ícone do olho
    },
    rememberText: {
      color: "#A6ACCD", // Cor clara para o texto "Lembrar"
      cursor: "pointer",
      userSelect: "none",
      ml: 1,
    },
  };

  return (
    <BasicLayout
      image={backgroundImage}
      imageOpacity={0.95}
      backgroundColor="#0D1E26"
      secondaryImage={secondaryLogo} // Logo da Estrutural Engenharia
      secondaryImagePosition={{ bottom: "-750px", right: "-650px" }} // Posição personalizada
      secondaryImageOpacity={0.05}
      secondaryImageSize={{ width: "1700px", height: "1700px" }}
    >
      <KBox position="relative">

        <KBox display="flex" alignItems="center" justifyContent="center" mt={2}>
          <img src={logo}  style={styles.logo} />
        </KBox>

        {/* Formulário de Login */}
        <KBox pt={4} pb={3} px={3}>
          <KBox component="form" role="form" onSubmit={formik.handleSubmit}>
            {/* Campo de email */}
            <KBox mb={2}>
              <KInput
                type="email"
                label="Email"
                fullWidth
                {...formik.getFieldProps("email")}
                error={Boolean(formik.touched.email && formik.errors.email)}
                autoFocus
                aria-label="Email"
                aria-invalid={Boolean(formik.touched.email && formik.errors.email)}
                sx={styles.input}
              />
              {/* Mensagem de erro para o campo de email */}
              {formik.touched.email && formik.errors.email && (
                <KTypography sx={styles.errorText}>{formik.errors.email}</KTypography>
              )}
            </KBox>

            {/* Campo de senha */}
            <KBox mb={2}>
              <KInput
                type={showPassword ? "text" : "password"}
                label="Senha"
                fullWidth
                {...formik.getFieldProps("password")}
                error={Boolean(formik.touched.password && formik.errors.password)}
                aria-label="Senha"
                aria-invalid={Boolean(formik.touched.password && formik.errors.password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                        aria-label={showPassword ? "Ocultar senha" : "Mostrar senha"}
                        sx={styles.iconButton}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={styles.input}
              />
              {/* Mensagem de erro para o campo de senha */}
              {formik.touched.password && formik.errors.password && (
                <KTypography sx={styles.errorText}>{formik.errors.password}</KTypography>
              )}
            </KBox>

            {/* Opção de lembrar login */}
            <KBox display="flex" alignItems="center" ml={-1} mt={2}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <KTypography
                variant="button"
                fontWeight="regular"
                sx={styles.rememberText}
                onClick={handleSetRememberMe}
              >
                Lembrar
              </KTypography>
            </KBox>

            {/* Botão de Login */}
            <KBox mt={4} mb={1}>
              <KButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                disabled={loading}
                sx={styles.button}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </KButton>
            </KBox>
          </KBox>
        </KBox>
      </KBox>
    </BasicLayout>
  );
}

export default SignIn;
