import React from "react";
import { Grid, Divider, Box, Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import KTypography from "components/KTypography";

function RegisterItemsHeader({
  documentNumber,
  supplier,
  date,
  group,
  requester,
  requestNumber,
  requestMessage,
  carrier,
  freightType,
  vehicleType,
  vehiclePlate,
  inspection,
  observations,
  comments,
  addEvaluation,
  evaluationLevel,
}) {
  const theme = useTheme();
  const textColor =
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.text.primary;

  return (
    <Box py={3}>
      <Card elevation={3} sx={{ padding: 3, borderRadius: 2, marginBottom: 3 }}>
        <KTypography variant="h4" component="div">
          Resumo da Entrada
        </KTypography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Número do Documento:</strong> {documentNumber || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Fornecedor:</strong> {supplier.name || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Data de Entrada:</strong> {date || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Grupo:</strong> {group.name || "N/A"}
              </KTypography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Solicitante:</strong> {requester?.name || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Nº Requisição:</strong> {requestNumber || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Mensagem:</strong> {requestMessage || "N/A"}
              </KTypography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Transportadora:</strong> {carrier || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Tipo de Frete:</strong> {freightType || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Tipo de Veículo:</strong> {vehicleType || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Placa do Veículo:</strong> {vehiclePlate || "N/A"}
              </KTypography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Fiscalização:</strong> {inspection || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <KTypography variant="subtitle1">
                <strong>Observações:</strong> {observations || "N/A"}
              </KTypography>
            </Box>
            <Box display="flex" alignItems="center">
              <KTypography variant="subtitle1">
                <strong>Avaliação:</strong>{" "}
                {addEvaluation ? evaluationLevel || "N/A" : "Nenhuma avaliação"}
              </KTypography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

export default RegisterItemsHeader;
