import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import KBox from "components/KBox";
import KButton from "components/KButton";
import apiService from "utils/apiService"; // Ajuste para sua API

function SendNotificationModal({ open, onClose, onSendSuccess }) {
  const [message, setMessage] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [sendToAll, setSendToAll] = useState(false);

  // Assumindo que você tenha uma função para buscar usuários
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await apiService.users.getAll(); // Ajuste para sua API
        setRecipients(response.data);
      } catch (error) {
        console.error("Erro ao carregar usuários:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSendNotification = async () => {
    try {
      const selectedRecipients = sendToAll
        ? recipients.map((user) => user.email)
        : recipients.filter((user) => user.selected).map((user) => user.email);

      // Chamar a API para enviar e-mail
      await apiService.notifications.send({
        message,
        recipients: selectedRecipients,
      });

      onSendSuccess();
      onClose();
    } catch (error) {
      console.error("Erro ao enviar comunicado:", error);
    }
  };

  const toggleRecipient = (index) => {
    const updatedRecipients = [...recipients];
    updatedRecipients[index].selected = !updatedRecipients[index].selected;
    setRecipients(updatedRecipients);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" textAlign="center" mb={2}>
          Enviar Comunicado
        </Typography>

        <TextField
          label="Mensagem"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ mb: 3 }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={sendToAll}
              onChange={() => setSendToAll(!sendToAll)}
            />
          }
          label="Enviar para todos"
        />

        {!sendToAll && (
          <Box mb={2}>
            <Typography variant="subtitle1" mb={1}>
              Selecionar Destinatários:
            </Typography>
            {recipients.map((user, index) => (
              <FormControlLabel
                key={user.id}
                control={
                  <Checkbox
                    checked={user.selected || false}
                    onChange={() => toggleRecipient(index)}
                  />
                }
                label={user.name}
              />
            ))}
          </Box>
        )}

        <KBox display="flex" justifyContent="space-between">
          <KButton variant="outlined" color="error" onClick={onClose}>
            Cancelar
          </KButton>
          <KButton variant="contained" color="success" onClick={handleSendNotification}>
            Enviar
          </KButton>
        </KBox>
      </Box>
    </Modal>
  );
}

export default SendNotificationModal;
