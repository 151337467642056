/*
  SISTEMA KORTEX - AVISO LEGAL
  ...
*/

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { useMaterialUIController } from "context";

// Custom styles for KChip
const KChipRoot = styled(Chip)(({ theme, ownerState }) => {
  const { palette, typography } = theme;
  const { darkMode, color, fontColor, fontSize } = ownerState;

  // Determine chip background and text color based on theme mode and color prop
  const backgroundColor = palette[color]
    ? darkMode
      ? palette[color].dark
      : palette[color].light
    : palette.grey[300];

  const textColor = fontColor || (palette[color]
    ? darkMode
      ? palette[color].light
      : palette[color].dark
    : palette.text.primary);

  return {
    backgroundColor,
    color: textColor,
    "& .MuiChip-label": {
      color: textColor,
      fontSize: fontSize || typography.fontSize, // Use custom font size or default typography size
    },
  };
});

const KChip = forwardRef(({ color, label, fontColor, fontSize, ...rest }, ref) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <KChipRoot
      {...rest}
      ref={ref}
      ownerState={{ darkMode, color, fontColor, fontSize }}
      label={label}
    />
  );
});

// Definindo os valores padrão das propriedades do KChip
KChip.defaultProps = {
  color: "info",
  fontColor: null, // Default to null so it uses the color scheme based on theme
  fontSize: null, // Default to null to use theme's font size
};

// Tipagem de propriedades do KChip
KChip.propTypes = {
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  label: PropTypes.node.isRequired,
  fontColor: PropTypes.string, // Accepts any valid CSS color
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Accepts size in px, rem, etc.
};

export default KChip;
