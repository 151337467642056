import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PeopleIcon from "@mui/icons-material/People";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import KBox from "components/KBox";
import AddUserModal from "./AddUserModal";
import AddSupplierModal from "./AddSupplierModal";
import AddTagModal from "layouts/Item/AddTagModal";
import SendNotificationModal from "./SendNotificationModal"; // Importando o modal de envio
import { useAuth } from "layouts/authentication/AuthContext";
import { useNavigate } from "react-router-dom"; // Para navegação entre páginas
import KTypography from "components/KTypography";

const AdminDashboard = () => {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [isAddSupplierModalOpen, setAddSupplierModalOpen] = useState(false);
  const [isAddTagModalOpen, setAddTagModalOpen] = useState(false);
  const [isSendNotificationModalOpen, setSendNotificationModalOpen] = useState(false);
  const navigate = useNavigate();

  const permissionLevel = currentUser?.permission_level;
  const permissionLevelMap = {
    0: "ADMIN",
    1: "LEVEL_1",
    2: "LEVEL_2",
    3: "LEVEL_3",
  };
  const mappedPermissionLevel = permissionLevelMap[permissionLevel];

  const handleAddUser = () => setAddUserModalOpen(true);
  const handleCloseAddUserModal = () => setAddUserModalOpen(false);

  const handleAddSupplier = () => setAddSupplierModalOpen(true);
  const handleCloseAddSupplierModal = () => setAddSupplierModalOpen(false);

  const handleAddTag = () => setAddTagModalOpen(true);
  const handleCloseAddTagModal = () => setAddTagModalOpen(false);

  const handleSendNotifications = () => setSendNotificationModalOpen(true); // Definindo a função corretamente
  const handleCloseSendNotificationModal = () => setSendNotificationModalOpen(false);


  const handleSupplierAdded = () => {
    console.log("Fornecedor adicionado! Atualize a lista de fornecedores aqui, se necessário.");
  };

  const handleEditPermission = () => console.log("Editar permissão de usuário clicado");

  // Navegar para a página de cadastro de itens
  const handleAddCategory = () => {
    navigate("/adicionar-item");
  };

  const renderCard = (title, icon, description, buttons) => (
    <Card sx={{ borderRadius: 2, mb: 2, p: 2, height: "100%" }}>
      <CardContent>
        <KBox display="flex" alignItems="center" mb={2}>
          <KBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              width: 60,
              height: 60,
              mr: 2,
            }}
          >
            {icon}
          </KBox>
          <KTypography variant="h6" fontWeight="medium">
            {title}
          </KTypography>
        </KBox>
        <KTypography variant="body2"  mb={2}>
          {description}
        </KTypography>
        {buttons && (
          <KBox display="flex" gap={1.5}>
            {buttons.map((button, index) => (
              <Button
                key={index}
                onClick={button.action}
                color="primary"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {button.text}
              </Button>
            ))}
          </KBox>
        )}
      </CardContent>
    </Card>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox py={3} px={2}>
        <Grid container spacing={3}>
          {["ADMIN"].includes(mappedPermissionLevel) && (
            <Grid item xs={12}>
              {renderCard(
                "Administradores",
                <AdminPanelSettingsIcon fontSize="large" color="primary" />,
                "Gerenciamento completo do sistema.",
                [{ text: "Adicionar Usuário", action: handleAddUser }]
              )}
            </Grid>
          )}
          {["ADMIN", "LEVEL_1"].includes(mappedPermissionLevel) && (
            <Grid item xs={12}>
              {renderCard(
                "Gerentes",
                <SupervisorAccountIcon fontSize="large" color="secondary" />,
                "Supervisão e controle de operações.",
                [
                  {
                    text: "Editar Permissão de Usuário",
                    action: handleEditPermission,
                  },
                  /*{
                    text: "Enviar Comunicado",
                    action: handleSendNotifications, // Chamando a função aqui
                  },*/
                ]
              )}
            </Grid>
          )}
          {["ADMIN", "LEVEL_1", "LEVEL_2"].includes(mappedPermissionLevel) && (
            <Grid item xs={12}>
              {renderCard(
                "Funcionários",
                <PeopleIcon fontSize="large" color="info" />,
                "Acesso para tarefas operacionais.",
                [
                  { text: "Cadastrar Item", action: handleAddCategory },
                  { text: "Cadastrar Fornecedor", action: handleAddSupplier },
                  { text: "Gerenciamento de Tag", action: handleAddTag },
                ]
              )}
            </Grid>
          )}
        </Grid>
        <Divider sx={{ my: 3 }} />
      </KBox>
      <Footer />

      <AddSupplierModal
        open={isAddSupplierModalOpen}
        onClose={handleCloseAddSupplierModal}
        onSupplierAdded={handleSupplierAdded}
      />

      <AddTagModal
        open={isAddTagModalOpen}
        onClose={handleCloseAddTagModal}
        onTagAdded={() => {
          console.log("Tag adicionada!");
        }}
      />

      <AddUserModal
        open={isAddUserModalOpen}
        handleClose={handleCloseAddUserModal}
      />

      <SendNotificationModal
        open={isSendNotificationModalOpen}
        onClose={handleCloseSendNotificationModal}
        onSendSuccess={() => console.log("Comunicado enviado!")}
      />
    </DashboardLayout>
  );
};

export default AdminDashboard;
