// utils/exportData.js
import * as XLSX from "xlsx";

export const exportToExcel = (rows, columns, fileName = "data_export.xlsx") => {
  // Cria uma planilha do Excel em branco
  const worksheet = XLSX.utils.json_to_sheet(
    rows.map((row) => {
      const formattedRow = {};
      columns.forEach((col) => {
        formattedRow[col.headerName] = row[col.field];
      });
      return formattedRow;
    }),
  );

  // Ajusta as colunas para caberem no conteúdo
  const columnWidths = columns.map((col) => ({
    wpx: Math.max(80, col.headerName.length * 10),
  }));
  worksheet["!cols"] = columnWidths;

  // Cria um workbook (arquivo Excel) e adiciona a planilha
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dados");

  // Gera o arquivo Excel e inicia o download
  XLSX.writeFile(workbook, fileName);
};
