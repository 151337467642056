import React from "react";
import KBox from "components/KBox";
import StatisticsCardGroup from "components/StatisticsCardGroup";  // Importa o componente de cartões

function SupplierHeader() {
  // Identificadores dos cartões de fornecedores que serão exibidos
  const supplierCardIdentifiers = [
    "totalSuppliers",          // Total de fornecedores
    "topSupplier",             // Fornecedor com mais pedidos
    "bestRatedSupplier",       // Fornecedor com a melhor avaliação
    "worstRatedSupplier",      // Fornecedor com a pior avaliação
  ];

  return (
    <KBox mb={3}>
      {/* Usa o StatisticsCardGroup para renderizar os cartões de fornecedores */}
      <StatisticsCardGroup identifiers={supplierCardIdentifiers} />
    </KBox>
  );
}

export default SupplierHeader;
