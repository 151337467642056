import React from "react";
import Grid from "@mui/material/Grid";
import KBox from "components/KBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import StatisticsCardGroup from "components/StatisticsCardGroup";  // Importa o novo componente de estatísticas
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

function Dashboard() {
  // Lista de identificadores dos cartões de estatísticas que você quer exibir
  const cardIdentifiers = ["itemsInStock", "ongoingWorks","totalActions", "criticalMaterials","pendingActions","recentMovements","recentActions", "materialsNearShortage",];

  // Dados de gráficos
  const chartData = [
    {
      component: ReportsBarChart,
      color: "info",
      title: "Gráfico 1",
      description: "Consumo de materiais nos últimos 30 dias",
      date: "Atualizado recentemente",
      chart: reportsBarChartData,
    },
    {
      component: ReportsLineChart,
      color: "success",
      title: "Gráfico 2",
      description: (
        <>
          (<strong>+5%</strong>) Aumento nos custos médios
        </>
      ),
      date: "Atualizado recentemente",
      chart: reportsLineChartData.sales,
    },
    {
      component: ReportsLineChart,
      color: "dark",
      title: "Gráfico 3",
      description: "Projeção de consumo de materiais",
      date: "Atualizado recentemente",
      chart: reportsLineChartData.tasks,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox py={3}>
        {/* Agora os cartões de estatísticas são renderizados via o novo componente */}
        <StatisticsCardGroup identifiers={cardIdentifiers} />

        <KBox mt={4.5}>
          <Grid container spacing={3}>
            {chartData.map(({ component: ChartComponent, ...chartProps }, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <KBox mb={3}>
                  <ChartComponent {...chartProps} />
                </KBox>
              </Grid>
            ))}
          </Grid>
        </KBox>

        <KBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
              <OrdersOverview /> {/* Visão geral dos pedidos */}
            </Grid>
          </Grid>
        </KBox>
      </KBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
