/*
  SISTEMA KORTEX - AVISO LEGAL

  Este código-fonte é propriedade exclusiva da Kortex.
  A reprodução, distribuição ou modificação deste código sem autorização expressa é estritamente proibida.

  Qualquer violação dos direitos autorais ou das disposições contratuais aplicáveis
  resultará em medidas legais apropriadas.

  Todos os direitos reservados.

  KORTEX SYSTEM - LEGAL NOTICE

  This source code is the exclusive property of the Kortex.
  Reproduction, distribution, or modification of this code without explicit authorization is strictly prohibited.

  Any violation of copyright or applicable contractual provisions
  will result in appropriate legal action.

  All rights reserved.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Fade from "@mui/material/Fade";

// Material Dashboard 2 React components
import KBox from "components/KBox";

// Custom styles for the KAlert
import KAlertRoot from "components/KAlert/KAlertRoot";
import KAlertCloseIcon from "components/KAlert/KAlertCloseIcon";

function KAlert({ color, dismissible, children, ...rest }) {
  const [alertStatus, setAlertStatus] = useState("mount");

  const handleAlertStatus = () => setAlertStatus("fadeOut");

  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
      <KAlertRoot ownerState={{ color }} {...rest}>
        <KBox display="flex" alignItems="center" color="white">
          {children}
        </KBox>
        {dismissible ? (
          <KAlertCloseIcon onClick={mount ? handleAlertStatus : null}>
            &times;
          </KAlertCloseIcon>
        ) : null}
      </KAlertRoot>
    </Fade>
  );

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

// Setting default values for the props of KAlert
KAlert.defaultProps = {
  color: "info",
  dismissible: false,
};

// Typechecking props of the KAlert
KAlert.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "erro",
    "light",
    "dark",
  ]),
  dismissible: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default KAlert;
