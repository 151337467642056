import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import KButton from "components/KButton";
import EditGroupModal from "./EditGroupModal";
import apiService from "utils/apiService";

function DefaultProjectCard({ id, image, title, description, onGroupUpdate }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [groupData, setGroupData] = useState(null);

  const handleOpenEditModal = () => {
    apiService.groups
      .getById(id)
      .then((response) => {
        setGroupData(response.data);
        setIsEditModalOpen(true);
      })
      .catch((error) => {
        console.error(
          "Erro ao carregar os dados do grupo:",
          error.response?.data || error.message
        );
      });
  };

  const handleEditGroup = (updatedGroup) => {
    onGroupUpdate();
    setGroupData(updatedGroup);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "hidden",
          width: "100%",
          maxWidth: { xs: "100%", sm: "100%", md: 330 },
          height: 400,
          padding: 0,
          margin: "auto",
        }}
      >
        <Link to={`/project-details/${id}`}>
          <CardMedia
            component="img"
            src={image}
            title={title}
            loading="lazy"
            sx={{
              height: 230,
              width: "100%",
              margin: 0,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              objectFit: "cover",
            }}
          />
        </Link>
        <KBox
          mt={1}
          mx={1.5}
          mb={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <KBox>
            <KTypography
              component={Link}
              to={`/project-details/${id}`}
              variant="h5"
              textTransform="capitalize"
              sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}
            >
              {title}
            </KTypography>
          </KBox>
          <KBox mb={3} lineHeight={0}>
            <KTypography
              variant="button"
              fontWeight="light"
              color="text"
              sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
            >
              {description}
            </KTypography>
          </KBox>
          <KBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="auto"
          >
            <KBox display="flex" gap={1} width="100%">
              <KButton
                component={Link}
                to={`/project-details/${id}`}
                variant="outlined"
                size="small"
                color="info"
                sx={{
                  fontSize: { xs: "0.75rem", sm: "0.875rem" },
                  width: "100%",
                }}
              >
                Visualizar
              </KButton>
              <KButton
                variant="outlined"
                size="small"
                color="primary"
                sx={{
                  fontSize: { xs: "0.75rem", sm: "0.875rem" },
                  width: "100%",
                }}
                onClick={handleOpenEditModal}
              >
                Editar
              </KButton>
            </KBox>
          </KBox>
        </KBox>
      </Card>
      {isEditModalOpen && groupData && (
        <EditGroupModal
          open={isEditModalOpen}
          handleClose={() => setIsEditModalOpen(false)}
          groupId={id}
          groupData={groupData}
          handleEditGroup={handleEditGroup}
          onGroupUpdate={onGroupUpdate} // Passa o evento para atualizar grupos ao modal
        />
      )}
    </>
  );
}

DefaultProjectCard.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onGroupUpdate: PropTypes.func.isRequired,
};

export default DefaultProjectCard;
