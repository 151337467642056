import React, { useState } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import KButton from "components/KButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const ActionButton = ({ onActionSelect, availableActions, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    onActionSelect(action);
    handleMenuClose();
  };

  return (
    <>
      <KButton
        variant="contained"
        color="info"
        onClick={handleMenuOpen}
        disabled={disabled}
        style={{
          ml: 2,
          mt: 1,
          mb: 1,
          margin: 1,
        }}
      >
        Gerenciar Seleção
      </KButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {availableActions.includes("delete") && (
          <MenuItem onClick={() => handleMenuItemClick("delete")}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Excluir
          </MenuItem>
        )}
        {availableActions.includes("move") && (
          <MenuItem onClick={() => handleMenuItemClick("move")}>
            <ListItemIcon>
              <MoveToInboxIcon fontSize="small" />
            </ListItemIcon>
            Mover
          </MenuItem>
        )}
        {availableActions.includes("export") && (
          <MenuItem onClick={() => handleMenuItemClick("export")}>
            <ListItemIcon>
              <SaveAltIcon fontSize="small" />
            </ListItemIcon>
            Exportar
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ActionButton;
