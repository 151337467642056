import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import KBox from "components/KBox";
import KButton from "components/KButton";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom"; // Importando useNavigate
import RegisterOptionsPopup from "./RegisterOptionsPopup"; // Certifique-se de ajustar o caminho do seu componente

function WarehouseHeader() {
  const navigate = useNavigate(); // Inicializando useNavigate

  // Estado para controlar o pop-up
  const [openModal, setOpenModal] = useState(false);

  // Funções para navegação
  const handleAddItem = () => {
    navigate("/adicionar-item");
  };

  const handleAddEntry = () => {
    setOpenModal(true); // Abrir o pop-up
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Fechar o pop-up
  };

  const handleAddExit = () => {
    navigate("/registrar-saida"); // Redireciona para a rota de registrar saída
  };

  const handleMovement = () => {
    navigate("/adicionar-movimentacao"); // Redireciona para a rota de movimentação
  };

  return (
    <>
      <KBox mb={3}>
        <Grid container spacing={3} justifyContent="center" px={10}>
          <Grid item xs={12} md={3}>
            <KButton
              variant="contained"
              color="info"
              fullWidth
              onClick={handleAddItem}
              startIcon={<Icon>add</Icon>}
            >
              Cadastrar Item
            </KButton>
          </Grid>
          <Grid item xs={12} md={3}>
            <KButton
              variant="contained"
              color="success"
              fullWidth
              onClick={handleAddEntry}
              startIcon={<Icon>input</Icon>}
            >
              Registrar Entrada
            </KButton>
          </Grid>
          <Grid item xs={12} md={3}>
            <KButton
              variant="contained"
              color="error"
              fullWidth
              onClick={handleAddExit}
              startIcon={<Icon>output</Icon>}
            >
              Registrar Saída
            </KButton>
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <KButton
              variant="contained"
              color="warning"
              fullWidth
              onClick={handleMovement}
              startIcon={<Icon>swap_horiz</Icon>}
            >
              Movimentação
            </KButton>
          </Grid> */}
        </Grid>
      </KBox>

      {/* Pop-up para escolher entre Cadastrar API ou Manual */}
      <RegisterOptionsPopup open={openModal} onClose={handleCloseModal} />
    </>
  );
}

export default WarehouseHeader;
