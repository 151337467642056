import React, { useCallback, useState } from "react";
import { Tabs, Tab, Card, CardHeader } from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import KBox from "components/KBox";
import KTable from "components/Table";
import WarehouseHeader from "./WarehouseHeader";
import useWarehouseData from "./hooks/useWarehouseData"; // Hook atualizado

const TAB_CONFIG = [
  { label: "Geral", icon: <InventoryIcon />, dataKey: "item" },
  { label: "Entrada", icon: <InputIcon />, dataKey: "entry" },
  { label: "Saída", icon: <OutputIcon />, dataKey: "exit" },
];

function Warehouse() {
  const [activeTab, setActiveTab] = useState(0); // Começar pela aba Geral
  const { data, handleEdit, handleDelete } = useWarehouseData(); // Hook que conecta a API
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelection = useCallback((selectedRows) => {
    setSelectedRows(selectedRows);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox pb={6} px={2}>
        <Card sx={{ boxShadow: 4, borderRadius: 2 }}>
          <CardHeader
            title="Gestão de Almoxarifado"
            subheader="Gerencie itens, entradas e saídas de forma eficiente"
            titleTypographyProps={{ variant: "h4", fontWeight: "bold" }}
            subheaderTypographyProps={{
              variant: "subtitle1",
              color: "textSecondary",
            }}
            sx={{ textAlign: "center", py: 2 }}
          />
          <WarehouseHeader />
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            {TAB_CONFIG.map((tab, index) => (
              <Tab
                key={index}
                icon={tab.icon}
                iconPosition="start"
                label={tab.label}
              />
            ))}
          </Tabs>
          <KBox p={3}>
            <KTable
              columns={data[TAB_CONFIG[activeTab].dataKey]?.columns || []}
              rows={data[TAB_CONFIG[activeTab].dataKey]?.rows || []}
              title={`Gestão de ${TAB_CONFIG[activeTab].label}`}
              onEdit={() => handleEdit(TAB_CONFIG[activeTab].dataKey)}
              onDelete={() => handleDelete(TAB_CONFIG[activeTab].dataKey)}
              onSelect={handleSelection}
              showQuantityColumn={false}
              showEditButton={false}
              showDeleteButton={false}
              editableFields={
                data[TAB_CONFIG[activeTab].dataKey]?.editableFields || []
              }
            />
          </KBox>
        </Card>
      </KBox>
      <Footer />
    </DashboardLayout>
  );
}

export default React.memo(Warehouse);
