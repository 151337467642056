import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh"; // Importando o ícone de refresh
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import TimelineItem from "examples/Timeline/TimelineItem";
import apiService from "utils/apiService";

// Importando ícones específicos do Material-UI
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

function RecentHistory() {
  const [historyEntries, setHistoryEntries] = useState([]);
  const [loading, setLoading] = useState(true);

  // Função para buscar os dados mais recentes da API
  const fetchHistoryEntries = () => {
    setLoading(true); // Define o estado de carregamento para true
    apiService.historyEntries
      .getAll()
      .then((response) => {
        console.log("Dados da API:", response.data); // Log para verificar os dados recebidos

        const sortedEntries = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setHistoryEntries(sortedEntries.slice(0, 5)); // Exibir apenas os 5 mais recentes
      })
      .catch((error) => {
        console.error("Erro ao buscar histórico:", error);
      })
      .finally(() => {
        setLoading(false); // Remove o estado de carregamento
      });
  };

  useEffect(() => {
    fetchHistoryEntries(); // Chama a função ao montar o componente
  }, []);

  // Função para selecionar ícone e cor baseado no tipo de ação
  const getIconAndColor = (actionType) => {
    switch (actionType) {
      case "Removeu":
        return { icon: <DeleteIcon />, color: "error" }; // Ícone de lixeira e cor vermelha
      case "Adicionou":
        return { icon: <AddCircleOutlineIcon />, color: "success" }; // Ícone de adicionar e cor verde
      case "Editou":
        return { icon: <EditIcon />, color: "info" }; // Ícone de edição e cor azul
      default:
        return { icon: <EditIcon />, color: "warning" }; // Ícone padrão
    }
  };

  return (
    <Card sx={{ height: "500px", overflow: "auto" }}> {/* Altura fixa definida para o Card */}
      <KBox pt={3} px={3} display="flex" justifyContent="space-between" alignItems="center">
        <KTypography variant="h6" fontWeight="medium">
          Histórico Recentes
        </KTypography>
        <IconButton onClick={fetchHistoryEntries} color="info">
          <RefreshIcon />
        </IconButton>
      </KBox>
      <KBox p={2}>
        {loading ? (
          <CircularProgress />
        ) : (
          historyEntries.map((entry, index) => {
            const { icon, color } = getIconAndColor(entry.action_type);
            const title = `${entry.actor_name}: ${entry.action_description}`; // Concatenando o actor_name com a descrição da ação

            return (
              <TimelineItem
                key={entry.id}
                color={color}
                icon={icon}
                title={title}
                dateTime={new Date(entry.created_at).toLocaleString()}
                lastItem={index === historyEntries.length - 1}
              />
            );
          })
        )}
      </KBox>
    </Card>
  );
}

export default RecentHistory;
