import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext/index";

export function PrivateRoute({ children }) {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    // Optionally, you can return a loading indicator here
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/authentication/logar" />;
}
