import React, { useState } from "react";
import { Box } from "@mui/material"; // Usar Box em vez de Grid
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ItemMover from "./ItemMover"; // Certifique-se de importar o componente corretamente
import KBox from "components/KBox";

function AddMovement() {
  const [sourceGroup, setSourceGroup] = useState("");
  const [destinationGroup, setDestinationGroup] = useState("");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Usar Box para ocupar 100% da largura e altura */}
      <KBox
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "980px",
          width: "100%", // Garante que ocupe 100% do espaço
          padding: 3,
        }}
      >
        {/* Utilize o componente ItemMover */}
        <ItemMover
          sourceGroup={sourceGroup}
          destinationGroup={destinationGroup}
          setSourceGroup={setSourceGroup}
          setDestinationGroup={setDestinationGroup}
        />
      </KBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddMovement;
