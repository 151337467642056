/*
  SISTEMA KORTEX - AVISO LEGAL

  Este código-fonte é propriedade exclusiva da Kortex.
  A reprodução, distribuição ou modificação deste código sem autorização expressa é estritamente proibida.

  Qualquer violação dos direitos autorais ou das disposições contratuais aplicáveis
  resultará em medidas legais apropriadas.

  Todos os direitos reservados.

  KORTEX SYSTEM - LEGAL NOTICE

  This source code is the exclusive property of the Kortex.
  Reproduction, distribution, or modification of this code without explicit authorization is strictly prohibited.

  Any violation of copyright or applicable contractual provisions
  will result in appropriate legal action.

  All rights reserved.
*/

import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import KSnackbarIconRoot from "components/KSnackbar/KSnackbarIconRoot";
import { useMaterialUIController } from "context";

function KSnackbar({
  color,
  icon,
  title,
  dateTime,
  content,
  close,
  bgWhite,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let titleColor;
  let dateTimeColor;
  let dividerColor;

  if (bgWhite) {
    titleColor = color;
    dateTimeColor = "dark";
    dividerColor = false;
  } else if (color === "light") {
    titleColor = darkMode ? "inherit" : "dark";
    dateTimeColor = darkMode ? "inherit" : "text";
    dividerColor = false;
  } else {
    titleColor = "white";
    dateTimeColor = "white";
    dividerColor = true;
  }

  return (
    <Snackbar
      TransitionComponent={Slide}
      transitionDuration={600}
      autoHideDuration={7000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      {...rest}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={close}
          sx={{ padding: "4px" }}
        >
          <Icon fontSize="small">close</Icon>
        </IconButton>
      }
    >
      <KBox
        variant={bgWhite ? "contained" : "gradient"}
        bgColor={bgWhite ? "white" : color}
        minWidth="23.875rem"
        maxWidth="100%"
        shadow="xl"
        borderRadius="lg"
        p={2}
        sx={{
          background: darkMode
            ? "linear-gradient(60deg, #29323c, #485563)"
            : `linear-gradient(60deg, ${color}.main, ${color}.dark)`,
          backgroundColor: ({ palette }) =>
            darkMode
              ? palette.background.card
              : palette[color] || palette.white.main,
          transform: "scale(1)",
          transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
          animation: "bump 0.3s ease-in-out",
        }}
      >
        <KBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="dark"
          p={1.5}
        >
          <KBox display="flex" alignItems="center" lineHeight={0}>
            <KSnackbarIconRoot
              fontSize="large"
              ownerState={{ color, bgWhite }}
              sx={{ marginRight: 1.5 }}
            >
              {icon}
            </KSnackbarIconRoot>
            <KTypography
              variant="h6"
              fontWeight="bold"
              color={titleColor}
              textGradient={bgWhite}
            >
              {title}
            </KTypography>
          </KBox>
          <KBox display="flex" alignItems="center" lineHeight={0}>
            <KTypography variant="caption" color={dateTimeColor}>
              {dateTime}
            </KTypography>
            <Icon
              sx={{
                color: ({ palette: { dark, white } }) =>
                  (bgWhite && !darkMode) || color === "light"
                    ? dark.main
                    : white.main,
                fontWeight: ({ typography: { fontWeightBold } }) =>
                  fontWeightBold,
                cursor: "pointer",
                marginLeft: 2,
                transform: "translateY(-1px)",
              }}
              onClick={close}
            >
              close
            </Icon>
          </KBox>
        </KBox>
        <Divider sx={{ margin: 0 }} light={dividerColor} />
        <KBox
          p={1.5}
          sx={{
            fontSize: ({ typography: { size } }) => size.md,
            color: ({ palette: { white, text } }) => {
              let colorValue =
                bgWhite || color === "light" ? text.main : white.main;

              if (darkMode) {
                colorValue = color === "light" ? "inherit" : white.main;
              }

              return colorValue;
            },
          }}
        >
          {content}
        </KBox>
      </KBox>
    </Snackbar>
  );
}

// Adicionando a animação de "bump"
const bumpAnimation = `
  @keyframes bump {
    0% {
      transform: scale(0.95);
      opacity: 0;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
`;

const style = document.createElement("style");
style.textContent = bumpAnimation;
document.head.append(style);

KSnackbar.defaultProps = {
  bgWhite: false,
  color: "info",
};

KSnackbar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  bgWhite: PropTypes.bool,
};

export default KSnackbar;
