import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardActions,
  InputAdornment,
  Box,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import KButton from "components/KButton";
import KTypography from "components/KTypography";
import apiService from "utils/apiService";
import KBox from "components/KBox";

const ItemMover = () => {
  const [groupsList, setGroupsList] = useState([]);
  const [sourceGroup, setSourceGroup] = useState("");
  const [destinationGroup, setDestinationGroup] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSelectedItems, setSearchSelectedItems] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [removeDialog, setRemoveDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [pendingSourceGroup, setPendingSourceGroup] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [quantityToMove, setQuantityToMove] = useState(1);
  const [quantityToRemove, setQuantityToRemove] = useState(1);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await apiService.items.getActive();
        console.log("Itens carregados:", response.data);
        setItemsList(response.data);
      } catch (error) {
        console.error("Erro ao buscar itens:", error);
      }
    };
    fetchItems();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await apiService.groups.getAll();
        setGroupsList(response.data);
      } catch (error) {
        console.error("Erro ao buscar grupos:", error);
      }
    };
    fetchGroups();
  }, []);

  useEffect(() => {
    const filterItems = () => {
      if (!sourceGroup) {
        setFilteredItems([]);
        return;
      }

      const filtered = itemsList.filter((item) => {
        const matchesGroup = item.group_id === parseInt(sourceGroup);
        const matchesSearchTerm = item.item_model_name
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        return matchesGroup && matchesSearchTerm;
      });
      setFilteredItems(filtered);
    };

    filterItems();
  }, [itemsList, sourceGroup, searchTerm]);

  const getRemainingQuantity = (item) => {
    if (!item) return 0;
    const movedItem = selectedItems.find((i) => i.id === item.id);
    return movedItem ? item.quantity - movedItem.quantityToMove : item.quantity;
  };

  const handleOpenDialog = (item) => {
    setCurrentItem(item);
    setQuantityToMove(1); // Reset quantity
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentItem(null);
  };

  const handleAddToDestination = () => {
    const remainingQuantity = getRemainingQuantity(currentItem);

    if (quantityToMove > remainingQuantity) {
      alert(
        `A quantidade selecionada excede o disponível. Máximo permitido: ${remainingQuantity}`
      );
      return;
    }

    setSelectedItems((prev) => {
      const existingItem = prev.find((i) => i.id === currentItem.id);
      const updatedItems = existingItem
        ? // Atualiza a quantidade do item existente
          prev.map((i) =>
            i.id === currentItem.id ? { ...i, quantityToMove: quantityToMove } : i
          )
        : // Adiciona o novo item à lista
          [...prev, { ...currentItem, quantityToMove }];

      // Adiciona o console.log para mostrar os itens selecionados para movimentação
      console.log('Itens Selecionados para Movimentação:', updatedItems);

      return updatedItems;
    });

    handleCloseDialog();
  };


  const handleOpenRemoveDialog = (item) => {
    setCurrentItem(item);
    setQuantityToRemove(1);
    setRemoveDialog(true);
  };

  const handleCloseRemoveDialog = () => {
    setRemoveDialog(false);
    setCurrentItem(null);
  };

  const handleRemoveFromDestination = () => {
    setSelectedItems((prev) =>
      prev
        .map((item) =>
          item.id === currentItem.id
            ? { ...item, quantityToMove: item.quantityToMove - quantityToRemove }
            : item
        )
        .filter((item) => item.quantityToMove > 0)
    );
    handleCloseRemoveDialog();
  };

  const handleSourceGroupChange = (value) => {
    if (selectedItems.length > 0) {
      setPendingSourceGroup(value); // Temporarily store the new group
      setConfirmationDialog(true); // Open the confirmation dialog
    } else {
      setSourceGroup(value);
      setDestinationGroup(""); // Reset destination group when source changes
      setSelectedItems([]); // Clear selected items when source changes
    }
  };

  const confirmSourceGroupChange = () => {
    setSourceGroup(pendingSourceGroup);
    setDestinationGroup("");
    setSelectedItems([]);
    setConfirmationDialog(false);
    setPendingSourceGroup(null);
  };

  const handleDestinationGroupChange = (value) => {
    if (value !== sourceGroup) {
      setDestinationGroup(value);
    }
  };

  const handleMovement = async () => {
    try {
      const itemsToMove = selectedItems.map((item) => ({
        item_id: item.id,
        quantity: item.quantityToMove,
      }));

      // Fazer a chamada para criar o movimento com o formato correto
      const response = await apiService.movements.add({
        origin_group_id: parseInt(sourceGroup),
        destination_group_id: parseInt(destinationGroup),
        items: itemsToMove, // Enviando a lista de itens com item_id e quantity
        observation: "Movimentação de itens entre grupos",
      });

      console.log("Movimentação realizada com sucesso:", response.data);
      alert("Movimentação realizada com sucesso!");
      setSelectedItems([]);
    } catch (error) {
      console.error("Erro ao realizar movimentação:", error);
      alert("Erro ao realizar movimentação.");
    }
  };

  const filteredSelectedItems = selectedItems.filter((item) =>
    item.item_model_name.toLowerCase().includes(searchSelectedItems.toLowerCase())
  );

  return (
    <Card
      sx={{ width: "100%", p: 3, boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" }}
    >
      <CardHeader
        title={
          <KTypography variant="h5" fontWeight="bold">
            Movimentação de Itens entre Grupos
          </KTypography>
        }
        subheader={
          <KTypography variant="body2" color="text">
            Selecione o grupo de origem e destino, depois escolha os itens para
            mover.
          </KTypography>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Grupo de Origem"
              variant="outlined"
              fullWidth
              select
              value={sourceGroup}
              onChange={(e) => handleSourceGroupChange(e.target.value)}
            >
              {groupsList.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Grupo de Destino"
              variant="outlined"
              fullWidth
              select
              value={destinationGroup}
              onChange={(e) => handleDestinationGroupChange(e.target.value)}
              disabled={!sourceGroup}
            >
              {groupsList
                .filter((group) => group.id !== parseInt(sourceGroup))
                .map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          {/* Seção de Itens de Origem */}
          <Grid item xs={12} sm={6}>
            <KTypography variant="h6" sx={{ mb: 2 }}>
              Itens Disponíveis
            </KTypography>
            <TextField
              placeholder="Pesquisar item..."
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />
            <KBox
              sx={{
                height: 300,
                overflowY: "auto",
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                p: 2,
              }}
            >
              {filteredItems.map((item) => {
                const remainingQuantity = getRemainingQuantity(item);
                return (
                  <KBox
                    key={item.id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1}
                    p={1}
                    borderRadius="8px"
                    sx={{ backgroundColor: "rgba(193, 192, 199, 0.2)" }}
                  >
                    <KBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <KTypography variant="subtitle2" fontWeight="bold">
                        Modelo: {item.item_model_name}
                      </KTypography>
                      <KTypography variant="body2" color="text">
                        NCM: {item.ncm} | Documento:{" "}
                        {item.stock_inflow_document_number}
                      </KTypography>
                      <KTypography variant="body2" color="text">
                        Quantidade Disponível: {remainingQuantity}
                      </KTypography>
                    </KBox>
                    <KButton
                      variant="outlined"
                      color="info"
                      onClick={() => handleOpenDialog(item)}
                      disabled={remainingQuantity <= 0}
                    >
                      Mover
                    </KButton>
                  </KBox>
                );
              })}
            </KBox>
          </Grid>

          {/* Seção de Itens de Destino */}
          <Grid item xs={12} sm={6}>
            <KTypography variant="h6" sx={{ mb: 2 }}>
              Itens Selecionados para Movimentação
            </KTypography>
            <TextField
              placeholder="Pesquisar item selecionado..."
              variant="outlined"
              fullWidth
              value={searchSelectedItems}
              onChange={(e) => setSearchSelectedItems(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />
            <KBox
              sx={{
                height: 300,
                overflowY: "auto",
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                p: 2,
              }}
            >
              {filteredSelectedItems.map((item) => (
                <KBox
                  key={item.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                  p={1}
                  borderRadius="8px"
                  sx={{ backgroundColor: "rgba(193, 192, 199, 0.2)" }}
                >
                  <KBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <KTypography variant="subtitle2" fontWeight="bold">
                      Modelo: {item.item_model_name}
                    </KTypography>
                    <KTypography variant="body2" color="text">
                      Quantidade a Mover: {item.quantityToMove}
                    </KTypography>
                  </KBox>
                  <KButton
                    variant="outlined"
                    color="error"
                    onClick={() => handleOpenRemoveDialog(item)}
                  >
                    Remover
                  </KButton>
                </KBox>
              ))}
            </KBox>
          </Grid>
        </Grid>
      </CardContent>

      {/* Botão Realizar Movimentação no Canto Inferior Direito */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <KButton variant="contained" color="warning" onClick={handleMovement}>
          Realizar Movimentação
        </KButton>
      </Box>

      {/* Modal para Inserir Quantidade */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <Card>
          <CardContent>
            <KTypography variant="h5" gutterBottom>
              Selecionar Quantidade para Mover
            </KTypography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <KTypography>Modelo: {currentItem?.item_model_name}</KTypography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  label="Quantidade"
                  variant="outlined"
                  fullWidth
                  value={quantityToMove}
                  onChange={(e) =>
                    setQuantityToMove(
                      Math.min(
                        Number(e.target.value),
                        getRemainingQuantity(currentItem)
                      )
                    )
                  }
                  inputProps={{ min: 1, max: getRemainingQuantity(currentItem) }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <KButton
              onClick={handleCloseDialog}
              color="secondary"
              variant="outlined"
            >
              Cancelar
            </KButton>
            <KButton
              onClick={handleAddToDestination}
              color="success"
              variant="contained"
              disabled={quantityToMove <= 0}
            >
              Mover
            </KButton>
          </CardActions>
        </Card>
      </Dialog>

      {/* Modal para Remover Quantidade */}
      <Dialog open={removeDialog} onClose={handleCloseRemoveDialog} fullWidth maxWidth="sm">
        <Card>
          <CardContent>
            <KTypography variant="h5" gutterBottom>
              Selecionar Quantidade para Remover
            </KTypography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <KTypography>Modelo: {currentItem?.item_model_name}</KTypography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  label="Quantidade a Remover"
                  variant="outlined"
                  fullWidth
                  value={quantityToRemove}
                  onChange={(e) =>
                    setQuantityToRemove(
                      Math.min(
                        Number(e.target.value),
                        currentItem?.quantityToMove
                      )
                    )
                  }
                  inputProps={{ min: 1, max: currentItem?.quantityToMove }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <KButton
              onClick={handleCloseRemoveDialog}
              color="secondary"
              variant="outlined"
            >
              Cancelar
            </KButton>
            <KButton
              onClick={handleRemoveFromDestination}
              color="error"
              variant="contained"
              disabled={quantityToRemove <= 0}
            >
              Remover
            </KButton>
          </CardActions>
        </Card>
      </Dialog>

      {/* Modal de Confirmação de Mudança de Grupo */}
      <Dialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
      >
        <DialogTitle>Confirmação</DialogTitle>
        <DialogContent>
          <KTypography variant="body1">
            Alterar o grupo de origem irá limpar todos os itens selecionados para
            movimentação. Deseja continuar?
          </KTypography>
        </DialogContent>
        <DialogActions>
          <KButton
            onClick={() => setConfirmationDialog(false)}
            color="secondary"
            variant="outlined"
          >
            Cancelar
          </KButton>
          <KButton
            onClick={confirmSourceGroupChange}
            color="primary"
            variant="contained"
          >
            Confirmar
          </KButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ItemMover;
