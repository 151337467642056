/*
  SISTEMA KORTEX - AVISO LEGAL

  Este código-fonte é propriedade exclusiva da Kortex.
  A reprodução, distribuição ou modificação deste código sem autorização expressa é estritamente proibida.

  Qualquer violação dos direitos autorais ou das disposições contratuais aplicáveis
  resultará em medidas legais apropriadas.

  Todos os direitos reservados.

  KORTEX SYSTEM - LEGAL NOTICE

  This source code is the exclusive property of the Kortex.
  Reproduction, distribution, or modification of this code without explicit authorization is strictly prohibited.

  Any violation of copyright or applicable contractual provisions
  will result in appropriate legal action.

  All rights reserved.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for KButton
import KButtonRoot from "components/KButton/KButtonRoot";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

const KButton = forwardRef(
  ({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <KButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {children}
      </KButtonRoot>
    );
  },
);

// Setting default values for the props of KButton
KButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
};

// Typechecking props for the KButton
KButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default KButton;
