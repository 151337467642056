import React, { useEffect } from 'react';
import apiService from 'utils/apiService';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import KBox from 'components/KBox';
import KTypography from 'components/KTypography';
import ConstructionIcon from '@mui/icons-material/Construction';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

function PurchaseOrder() {
  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const countResponse = await apiService.stock_inflows.getAll();
        console.log('Top 10:', countResponse.data);

        const topSuppliersResponse = await apiService.suppliers.getTop();
        console.log('Fornecedores com mais entradas de estoque:', topSuppliersResponse.data);

        const highestRatedResponse = await apiService.stock_inflows.getAll();
        console.log('aa:', highestRatedResponse.data);

        const lowestRatedResponse = await apiService.suppliers.getLowestRated();
        console.log('Fornecedores menos bem avaliados:', lowestRatedResponse.data);
      } catch (error) {
        console.error('Erro ao buscar dados dos fornecedores:', error);
      }
    };



    fetchSupplierData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <KBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="80vh"
        textAlign="center"
        p={2}
      >
        <ConstructionIcon
          style={{ fontSize: '16rem', color: '#ff9800', mb: 2 }}
          aria-label="Página em desenvolvimento"
        />
        <KTypography variant="h4" gutterBottom>
          Esta página está em desenvolvimento!
        </KTypography>
        <KTypography variant="body1" color="text">
          A equipe da Kortex está trabalhando pesado para trazer novidades para você. Fique atento!
        </KTypography>
      </KBox>
    </DashboardLayout>
  );
}

export default PurchaseOrder;
