import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import apiService from "utils/apiService"; // Importa o serviço de API

function TopRatedSuppliersList({ icon, title, description, onRefresh }) {
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Função para buscar os fornecedores de topo usando o endpoint 'getTop'
  const fetchTopSuppliers = async () => {
    try {
      setLoading(true);
      const response = await apiService.suppliers.getTopRated(10);
      const data = response.data;

      // Verifica se é um array e se todos os itens têm as propriedades necessárias
      const validData = Array.isArray(data)
        ? data.filter((supplier) => supplier && supplier.name)
        : [];
      setSuppliers(validData);
    } catch (error) {
      console.error("Erro ao buscar os fornecedores:", error);
      setSuppliers([]); // Define como array vazio em caso de erro
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopSuppliers();
  }, []);

  const renderIcon = () =>
    icon?.component && (
      <KBox
        width="4rem"
        height="4rem"
        bgColor={icon.color || "dark"}
        variant="gradient"
        coloredShadow={icon.color || "dark"}
        borderRadius="xl"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        mt={-5}
        mr={2}
      >
        <Icon fontSize="medium">{icon.component}</Icon>
      </KBox>
    );

  const renderHeader = () =>
    (title || description) && (
      <KBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1.5}
        sx={{ borderBottom: "1px solid #e0e0e0", flexShrink: 0 }}
      >
        {renderIcon()}
        <KBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flex="1"
          minWidth="0"
        >
          {title && (
            <KTypography variant="h6" noWrap>
              {title}
            </KTypography>
          )}
          {description && (
            <KBox>
              <KTypography component="div" variant="button" color="text" noWrap>
                {description}
              </KTypography>
            </KBox>
          )}
        </KBox>
        {onRefresh && (
          <Tooltip title="Atualizar Lista">
            <IconButton
              onClick={() => {
                fetchTopSuppliers();
                if (onRefresh) onRefresh();
              }}
              sx={{
                color: "primary.main",
                backgroundColor: "rgba(0, 123, 255, 0.1)",
                "&:hover": {
                  backgroundColor: "rgba(0, 123, 255, 0.2)",
                  transition: "background-color 0.3s ease",
                },
                borderRadius: "12px",
                ml: "auto",
              }}
            >
              <Icon>refresh</Icon>
            </IconButton>
          </Tooltip>
        )}
      </KBox>
    );

  const renderSupplierCard = (supplier) => {
    const supplierName = supplier?.name || "N/A"; // Verifica se o nome do fornecedor existe
    const firstLetter = supplierName.charAt(0); // Usa `charAt` para pegar o primeiro caractere

    return (
      <KBox
        key={supplier.name || Math.random()} // Garante uma chave única, mesmo se o nome for indefinido
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
        px={2}
        mb={1}
        borderRadius="lg"
        boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
      >
        <KBox display="flex" alignItems="center">
          <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>{firstLetter}</Avatar>
          <KBox>
            <KTypography variant="h6" noWrap>
              {supplierName}
            </KTypography>

            <KTypography variant="body2" color="textSecondary" noWrap>
              Pedidos: {supplier?.number_of_stock_inflows || 0}
            </KTypography>
          </KBox>
        </KBox>
        <KBox display="flex" alignItems="center">
          <Icon color="warning" sx={{ mr: 1 }}>
            star
          </Icon>
          <KTypography variant="button" fontWeight="medium">
            {supplier?.rating || "0"}
          </KTypography>
        </KBox>
      </KBox>
    );
  };

  const renderList = () => (
    <KBox flex={1} px={3} py={2} sx={{ overflowY: "auto" }}>
      <Grid container spacing={2}>
        {suppliers.map((supplier) => (
          <Grid item xs={12} key={supplier.name || Math.random()}>
            {renderSupplierCard(supplier)}
          </Grid>
        ))}
      </Grid>
    </KBox>
  );

  return (
    <Card
      sx={{
        height: "472px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <KBox sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {renderHeader()}
        {loading ? (
          <KBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <CircularProgress />
          </KBox>
        ) : (
          renderList()
        )}
      </KBox>
    </Card>
  );
}

// Definindo valores padrão para as propriedades do TopRatedSuppliersList
TopRatedSuppliersList.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  onRefresh: null,
};

// Validação de tipo para as propriedades do TopRatedSuppliersList
TopRatedSuppliersList.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onRefresh: PropTypes.func,
};

export default TopRatedSuppliersList;
