import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Divider,
  Tabs,
  Tab,
  GlobalStyles,
} from "@mui/material";
import Assignment from "@mui/icons-material/Assignment";
import Description from "@mui/icons-material/Description";
import AccountBalance from "@mui/icons-material/AccountBalance";
import Note from "@mui/icons-material/Note";
import Preview from "@mui/icons-material/Preview";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import KBox from "components/KBox";
import KButton from "components/KButton";
import ConfirmationModal from "./components/ConfirmationModal";
import dayjs from "dayjs";
import PrincipalTab from "./components/PrincipalTab";
import RequisitionTab from "./components/RequisitionTab";
import FreightTab from "./components/FreightTab";
import ObservationsTab from "./components/ObservationsTab";
import SummaryTab from "./components/SummaryTab";
import apiService from "utils/apiService";

function RegisterEntry() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    documentNumber: "",
    supplier: {id: "", name: ""},
    date: dayjs().format("YYYY-MM-DD"),
    addEvaluation: false,
    evaluationLevel: "",
    evaluationError: false,
    requester: null,
    requestMessage: "",
    requestNumber: "",
    carrier: "",
    freightType: "",
    vehicleType: "",
    vehiclePlate: "",
    inspection: "",
    observations: "",
    group: {id: "", name: ""},
    comments: "",
  });
  const [groupsList, setGroupsList] = useState([]);
  const [suppliersList, setSuppliersList] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchGroupsAndSuppliers = async () => {
      try {
        const [groupsResponse, suppliersResponse] = await Promise.all([
          apiService.groups.getAll(),
          apiService.suppliers.getAll(),
        ]);
        setGroupsList(groupsResponse.data);
        setSuppliersList(suppliersResponse.data);
      } catch (error) {
        console.error("Erro ao buscar grupos e fornecedores:", error);
      }
    };
    fetchGroupsAndSuppliers();
  }, []);

  const handleTabChange = (event, newValue) => setActiveTab(newValue);
  const handleChange = (field, value) =>
    setFormData((prev) => ({ ...prev, [field]: value }));

  const handleSubmit = () => {
    if (
      !formData.documentNumber ||
      !formData.supplier ||
      !formData.date ||
      !formData.group
    ) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
    if (formData.addEvaluation && !formData.evaluationLevel) {
      setFormData((prev) => ({ ...prev, evaluationError: true }));
      return;
    }
    navigate("/register-items", { state: formData });
  };

  const handleClear = () => {
    setOpenModal(false);
    setFormData({
      documentNumber: "",
      supplier: null,
      date: dayjs().format("YYYY-MM-DD"),
      addEvaluation: false,
      evaluationLevel: "",
      evaluationError: false,
      requester: null,
      requestMessage: "",
      requestNumber: "",
      carrier: "",
      freightType: "",
      vehicleType: "",
      vehiclePlate: "",
      inspection: "",
      observations: "",
      group: null,
      comments: "",
    });
  };

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox py={3}>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card sx={{ width: "100%" }}>
              <CardHeader
                title="Registrar Entrada"
                subheader="Preencha as informações abaixo para registrar a entrada dos itens no estoque"
                titleTypographyProps={{ variant: "h4", fontWeight: "bold" }}
                subheaderTypographyProps={{
                  variant: "subtitle1",
                  color: "textSecondary",
                }}
              />
              <CardContent>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Principal" icon={<Assignment />} />
                  <Tab label="Requisição" icon={<Description />} />
                  <Tab label="Frete" icon={<AccountBalance />} />
                  <Tab label="Observações" icon={<Note />} />
                  <Tab label="Resumo" icon={<Preview />} />
                </Tabs>

                <Divider sx={{ my: 2 }} />

                {activeTab === 0 && (
                  <PrincipalTab
                    formData={formData}
                    groupsList={groupsList}
                    suppliersList={suppliersList}
                    onChange={handleChange}
                  />
                )}
                {activeTab === 1 && (
                  <RequisitionTab formData={formData} onChange={handleChange} />
                )}
                {activeTab === 2 && (
                  <FreightTab formData={formData} onChange={handleChange} />
                )}
                {activeTab === 3 && (
                  <ObservationsTab
                    formData={formData}
                    onChange={handleChange}
                  />
                )}
                {activeTab === 4 && <SummaryTab {...formData} />}
              </CardContent>

              <CardActions sx={{ justifyContent: "space-between", p: 3 }}>
                <KButton
                  variant="outlined"
                  color="error"
                  onClick={handleModalOpen}
                >
                  Limpar Todos os Dados
                </KButton>
                <KButton
                  variant="contained"
                  color="success"
                  onClick={handleSubmit}
                >
                  Salvar
                </KButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </KBox>
      <Footer />
      <ConfirmationModal
        open={openModal}
        onClose={handleModalClose}
        onConfirm={handleClear}
      />
    </DashboardLayout>
  );
}

export default RegisterEntry;
