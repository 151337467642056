import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import KBox from "components/KBox";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import KTable from "components/Table";

function SupplierTable({ columns, rows, onEdit, onDelete }) {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelection = useCallback((selectedRows) => {
    setSelectedRows(selectedRows);
  }, []);

  return (
    <Card>
      <KBox p={3}>
        <Typography variant="h6" gutterBottom>
          Lista de Fornecedores
        </Typography>
        <Box pt={3}>
          <KTable
            columns={columns}
            rows={rows}
            title="Fornecedores"
            onEdit={onEdit}
            onDelete={onDelete}
            onSelect={handleSelection}
            availableActions={["delete", "export"]}
            editableFields={{
              name: "Nome",
              contact: "Contact",
              address: "Address",
              cnpj: "CNPJ",
            }}
            sx={{
              "& .MuiDataGrid-root": {
                borderRadius: "8px",
                boxShadow: "0 3px 10px rgba(0,0,0,0.1)",
              },
              "& .MuiDataGrid-cell": {
                transition: "background-color 0.2s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(0, 123, 255, 0.1)",
                },
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "primary.light",
                color: "white",
                fontWeight: "bold",
              },
            }}
          />
        </Box>
      </KBox>
    </Card>
  );
}

SupplierTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SupplierTable;
