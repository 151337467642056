import React, { useState, useEffect } from "react";
import { Modal, TextField, Autocomplete, Chip } from "@mui/material";
import KButton from "components/KButton";
import KBox from "components/KBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditModal({
  open,
  handleClose,
  rowData,
  handleSave,
  editableFields,
  tagsList,
}) {
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (rowData) {
      setEditData({ ...rowData });
    }
  }, [rowData]);

  const handleChange = (field, value) => {
    setEditData((prev) => ({ ...prev, [field]: value }));
  };

  const handleTagsChange = (event, value) => {
    setEditData((prev) => ({
      ...prev,
      tags: value,
    }));
  };

  const onSave = () => {
    // Converte as tags para uma lista de IDs antes de enviar para a API
    const updatedData = {
      ...editData,
      tag_ids: editData.tags ? editData.tags.map(tag => tag.id) : [], // Convertendo tags para IDs
    };

    handleSave(updatedData); // Envia os dados atualizados com as tags convertidas
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-modal-title"
      aria-describedby="edit-modal-description"
    >
      <KBox sx={style}>
        <h2 id="edit-modal-title">Editar</h2>
        {Object.keys(editableFields).map(key => {
          const value = editableFields[key];
          if (key === "tags") {
            return (
              <Autocomplete
                key={key}
                multiple
                options={tagsList}
                getOptionLabel={(option) => option.name}
                value={editData.tags || []}
                onChange={handleTagsChange}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      key={option.id}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Selecione as tags"
                    fullWidth
                  />
                )}
              />
            );
          } else {
            return (
              <TextField
                key={key}
                margin="normal"
                fullWidth
                name={key}
                label={value}
                value={editData[key] || ""}
                onChange={(e) => handleChange(key, e.target.value)}
              />
            );
          }
        })}
        <KButton
          variant="contained"
          color="success"
          onClick={onSave}
          style={{ marginLeft: "8px", color: "white" }}
        >
          Salvar
        </KButton>
        <KButton
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          style={{ marginLeft: "8px", color: "black", opacity: 0.5 }}
        >
          Cancelar
        </KButton>
      </KBox>
    </Modal>
  );
}

export default EditModal;
