/*
  SISTEMA KORTEX - AVISO LEGAL

  Este código-fonte é propriedade exclusiva da Kortex.
  A reprodução, distribuição ou modificação deste código sem autorização expressa é estritamente proibida.

  Qualquer violação dos direitos autorais ou das disposições contratuais aplicáveis
  resultará em medidas legais apropriadas.

  Todos os direitos reservados.

  KORTEX SYSTEM - LEGAL NOTICE

  This source code is the exclusive property of the Kortex.
  Reproduction, distribution, or modification of this code without explicit authorization is strictly prohibited.

  Any violation of copyright or applicable contractual provisions
  will result in appropriate legal action.

  All rights reserved.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for KInput
import KInputRoot from "components/KInput/KInputRoot";

const KInput = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  <KInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
));

// Setting default values for the props of KInput
KInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the KInput
KInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default KInput;
