import React, { useState, useCallback, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  IconButton,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import localeText from "./config/localeText";
import EditModal from "./EditModal";
import KSnackbar from "components/KSnackbar";
import KBox from "components/KBox";
import ConfirmationDialog from "./ConfirmationDialog";
import Filters from "./Filters";
import { exportToExcel } from "./utils/exportData";
import KButton from "components/KButton";
import KTypography from "components/KTypography";
import KChip from "components/KChip";
import debounce from "lodash/debounce";
import ActionButton from "./ActionButton";

function KTable({
  columns,
  rows,
  title,
  onEdit,
  onDelete,
  onSelect,
  editableFields,
  tagsList,
  onQuantityChange,
  showQuantityColumn = false,
  showEditButton = true,
  showDeleteButton = true,
  showActionButton = true,
  availableActions = ["delete", "move", "export"],
  fontSize = "15px",
  lightTextColor = "dark",
  darkTextColor = "white"
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [editRow, setEditRow] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarDateTime, setSnackbarDateTime] = useState("");
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [loading, setLoading] = useState(true);

  const initialSearchParams = useMemo(() => {
    return columns.reduce((acc, column) => {
      acc[column.field] = "";
      return acc;
    }, {});
  }, [columns]);

  const [searchParams, setSearchParams] = useState(initialSearchParams);

  const handleSearchChange = useMemo(() =>
    debounce((newSearchParams) => {
      setSearchParams(newSearchParams);
    }, 300),
    []
  );

  const filteredRows = useMemo(() => {
    return rows.filter((row) =>
      Object.keys(searchParams).every((key) => {
        if (!searchParams[key]) return true;

        if (key === "tags") {
          return row[key].some((tag) =>
            tag.name.toLowerCase().includes(searchParams[key].toLowerCase())
          );
        }

        return row[key]?.toString().toLowerCase().includes(searchParams[key].toLowerCase());
      })
    );
  }, [searchParams, rows]);

  // Atualiza o estado de carregamento quando os dados são recebidos
  useMemo(() => {
    setLoading(!rows); // Define como true se rows for null/undefined
  }, [rows]);

  const openEditModal = useCallback(
    (rowId) => {
      const rowToEdit = rows.find((row) => row.id === rowId);
      if (rowToEdit) {
        setEditRow(rowToEdit);
        setEditModalOpen(true);
      }
    },
    [rows]
  );

  const handleSave = useCallback(
    (updatedRow) => {
      onEdit(updatedRow);
      setSnackbarMessage(`O item com ID ${updatedRow.id} foi editado com sucesso!`);
      setSnackbarDateTime(new Date().toLocaleString());
      setSnackbarOpen(true);
      setEditModalOpen(false);
    },
    [onEdit]
  );

  const handleDeleteClick = useCallback((rowId) => {
    setRowToDelete(rowId);
    setConfirmationDialogOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    onDelete(rowToDelete);
    setSnackbarMessage(`O item com ID ${rowToDelete} foi removido com sucesso!`);
    setSnackbarDateTime(new Date().toLocaleString());
    setSnackbarOpen(true);
    setConfirmationDialogOpen(false);
    setRowToDelete(null);
  }, [rowToDelete, onDelete]);

  const handleExport = useCallback(() => {
    exportToExcel(filteredRows, columns);
  }, [filteredRows, columns]);

  const handleBulkAction = useCallback(
    (action) => {
      if (action === "delete") {
        selectionModel.forEach((id) => onDelete(id));
      } else if (action === "move") {
        console.log("Mover itens selecionados:", selectionModel);
      } else if (action === "export") {
        const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
        exportToExcel(selectedRows, columns);
      }
    },
    [onDelete, selectionModel, rows, columns]
  );

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  const handleQuantityChange = useCallback(
    (id, quantity) => {
      onQuantityChange(id, quantity);
    },
    [onQuantityChange]
  );

  const handleSelection = useCallback(
    (newSelection) => {
      setSelectionModel(newSelection);
      if (onSelect) {
        const selectedRows = rows.filter((row) => newSelection.includes(row.id));
        onSelect(selectedRows);
      }
    },
    [onSelect, rows]
  );

  const actionColumns = useMemo(() => {
    const actions = [];
    if (showEditButton) {
      actions.push({
        field: "edit",
        headerName: "Editar",
        width: 80,
        renderCell: (params) => (
          <IconButton onClick={() => openEditModal(params.id)} color="info">
            <EditIcon />
          </IconButton>
        ),
      });
    }

    if (showDeleteButton) {
      actions.push({
        field: "delete",
        headerName: "Remover",
        width: 80,
        renderCell: (params) => (
          <IconButton onClick={() => handleDeleteClick(params.id)} color="error">
            <DeleteIcon />
          </IconButton>
        ),
      });
    }
    return actions;
  }, [openEditModal, handleDeleteClick, showEditButton, showDeleteButton]);

  const getResponsiveColumns = useCallback(() => {
    const newColumns = columns.map((column) => ({
      ...column,
      renderHeader: () => (
        <KTypography fontSize={fontSize} color="inherit" fontWeight="medium" sx={{ textAlign: "center" }}>
          {column.headerName}
        </KTypography>
      ),
      renderCell: (params) => {
        // Verifica se a coluna é "Icone" e renderiza o ícone diretamente
        if (column.field === "icon") {
          return params.row.icon; // Renderiza o componente de ícone diretamente
        }
        if (params.field === "tags") {
          return (
            <Box>
              {Array.isArray(params.value) &&
                params.value.map((tag) => (
                  <KChip
                    key={tag.id}
                    label={tag.name}
                    color="info"
                    size="small"
                    fontColor="white"
                    fontSize="14px"
                    sx={{ margin: "2px" }}
                  />
                ))}
            </Box>
          );
        }
        return (
          <KTypography
            fontSize={fontSize}
            color={theme.palette.mode === "dark" ? darkTextColor : lightTextColor}
            sx={{
              textAlign: "justify",
              display: "block",
              width: '100%',
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
              justifyContent: 'center',
            }}
          >
            {params.value}
            {/* Caractere invisível para garantir a justificação correta */}
            <Box component="span" sx={{ visibility: 'hidden' }}>{"\u200B"}</Box>
          </KTypography>
        );
      },
    }));

    // Mantém as demais colunas e ações configuradas
    return [...newColumns, ...actionColumns];
  }, [columns, actionColumns, handleQuantityChange, showQuantityColumn, fontSize, lightTextColor, darkTextColor, theme.palette.mode]);



  const tableHeight = isMobile ? 400 : 600;

  return (
    <Card sx={{ margin: "auto", width: "100%", boxShadow: 3 }}>
      <CardContent>
        {title && (
          <KTypography
            variant="h5"
            component="div"
            fontSize={fontSize}
            color={theme.palette.mode === "dark" ? darkTextColor : lightTextColor}
            sx={{ textAlign: "center", mb: 2 }}
          >
            {title}
          </KTypography>
        )}
        <Filters
          columns={columns}
          searchParams={searchParams}
          setSearchParams={handleSearchChange}
        />
        <KBox
          sx={{
            textAlign: "center",
            mb: 2,
            flexDirection: isMobile ? "column" : "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <KButton
            variant="outlined"
            color="success"
            onClick={handleExport}
            style={{
              ml: isMobile ? 0 : 2,
              mt: isMobile ? 1 : 0,
              mb: isMobile ? 1 : 0,
              margin: 5,
              color: "success",
            }}
          >
            Exportar Excel
          </KButton>
          {showActionButton && (
            <ActionButton
              onActionSelect={handleBulkAction}
              availableActions={availableActions}
              disabled={selectionModel.length === 0}
            />
          )}
        </KBox>
        <KBox
          component={Paper}
          sx={{
            height: tableHeight,
            overflowY: "auto",
            overflowX: "auto",
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : rows.length === 0 ? (
            // Mostra a mensagem quando não há dados
            <KTypography variant="h6" color="text">
              Nenhum dado disponível
            </KTypography>
          ) : (
            <DataGrid
              rows={filteredRows}

              columns={getResponsiveColumns()}
              pageSize={isMobile ? 3 : 5}
              rowsPerPageOptions={isMobile ? [3] : [5, 10, 20]}
              checkboxSelection
              localeText={localeText}
              pagination
              onRowSelectionModelChange={(newSelection) => handleSelection(newSelection)}
              sx={{
                minWidth: columns.length > 12 ? `${columns.length * 150}px` : "100%",
                maxWidth: "120%",
              }}
            />
          )}
        </KBox>
      </CardContent>
      {editRow && (
        <EditModal
          open={isEditModalOpen}
          handleClose={() => setEditModalOpen(false)}
          rowData={editRow}
          handleSave={handleSave}
          editableFields={editableFields}
          tagsList={tagsList}
        />
      )}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        handleClose={() => setConfirmationDialogOpen(false)}
        handleConfirm={handleDeleteConfirm}
        message="Tem certeza que deseja remover este item?"
      />
      <KSnackbar
        color="success"
        icon="check"
        title="Sucesso"
        dateTime={snackbarDateTime}
        content={snackbarMessage}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        close={handleSnackbarClose}
        bgWhite={true}
      />
    </Card>
  );
}

export default KTable;
