import { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip as ChartTooltip, // Renomeado para evitar conflito
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip"; // Mantenha esta importação
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import configs from "examples/Charts/RadarChart/configs";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, ChartTooltip, Legend); // Use o nome renomeado aqui

function RadarChart({ icon, title, description, size, chart, onRefresh }) {
  const chartDatasets = useMemo(
    () =>
      chart.datasets?.map((dataset) => ({
        ...dataset,
        backgroundColor: colors[dataset.color]
          ? rgba(colors[dataset.color || "dark"].main, 0.2)
          : rgba(colors.dark.main, 0.2),
      })) || [],
    [chart.datasets]
  );

  const { data, options } = useMemo(
    () => configs(chart.labels || [], chartDatasets),
    [chart.labels, chartDatasets]
  );

  const renderIcon = () =>
    icon.component && (
      <KBox
        width="4rem"
        height="4rem"
        bgColor={icon.color || "dark"}
        variant="gradient"
        coloredShadow={icon.color || "dark"}
        borderRadius="xl"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        mt={-5}
        mr={2}
      >
        <Icon fontSize="medium">{icon.component}</Icon>
      </KBox>
    );

  const renderHeader = () =>
    (title || description) && (
      <KBox display="flex" justifyContent="space-between" alignItems="center" px={description ? 1 : 0} pt={description ? 1 : 0}>
        {renderIcon()}
        <KBox mt={icon.component ? -2 : 0}>
          {title && <KTypography variant="h6">{title}</KTypography>}
          {description && (
            <KBox mb={2}>
              <KTypography component="div" variant="button" color="text">
                {description}
              </KTypography>
            </KBox>
          )}
        </KBox>
        {onRefresh && (
          <Tooltip title="Atualizar Gráfico">
            <IconButton
              onClick={onRefresh}
              sx={{
                color: "primary.main",
                backgroundColor: "rgba(0, 123, 255, 0.1)",
                "&:hover": {
                  backgroundColor: "rgba(0, 123, 255, 0.2)",
                  transition: "background-color 0.3s ease",
                },
                borderRadius: "12px",
              }}
            >
              <Icon>refresh</Icon>
            </IconButton>
          </Tooltip>
        )}
      </KBox>
    );

  const renderChart = (
    <KBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {renderHeader()}
      <KBox
        p={3}
        style={{
          width: size.width || '100%',
          height: size.height || 'auto',
        }}
      >
        <Radar data={data} options={options} redraw style={{ height: size.height }}/>
      </KBox>
    </KBox>
  );

  return <Card>{renderChart}</Card>;
}

// Definindo valores padrão para as propriedades do RadarChart
RadarChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  size: { width: "100%", height: "400px" }, // Valores padrão de tamanho
  onRefresh: null,
};

// Validação de tipo para as propriedades do RadarChart
RadarChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  chart: PropTypes.shape({
    datasets: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
  }).isRequired,
  onRefresh: PropTypes.func,
};

export default RadarChart;
