import React, { useState } from "react";
import { TextField, Box, IconButton, Grid, Collapse, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

function Filters({ columns, searchParams, setSearchParams }) {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [tempSearchParams, setTempSearchParams] = useState(searchParams); // Temporary state

  // Handle input change for temp filters
  const handleTempChange = (e) => {
    const { name, value } = e.target;
    setTempSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  // Apply filters when the button is clicked
  const handleApplyFilters = () => {
    setSearchParams(tempSearchParams); // Set the actual filters only on button press
  };

  const handleClearFilters = () => {
    const clearedParams = columns.reduce((acc, column) => {
      acc[column.field] = "";
      return acc;
    }, {});
    setTempSearchParams(clearedParams); // Clear temp filters
    setSearchParams(clearedParams); // Clear applied filters
  };

  const toggleFiltersVisibility = () => {
    setFiltersVisible((prev) => !prev);
  };

  return (
    <Box sx={{ mb: 1 }}>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <IconButton
            onClick={toggleFiltersVisibility}
            sx={{
              color: filtersVisible ? "red" : "blue",
              opacity: 1,
            }}
            aria-label={filtersVisible ? "fechar filtros" : "abrir filtros"}
          >
            {filtersVisible ? <CloseIcon /> : <SearchIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={filtersVisible}>
        <Grid container spacing={2} alignItems="flex-start">
          {columns.map((column) => (
            <Grid item key={column.field} xs={12} sm={6} md={4} lg={4}>
              <TextField
                label={column.headerName}
                name={column.field}
                value={tempSearchParams[column.field] || ""} // Use temp params here
                onChange={handleTempChange} // Update temp params
                size="small"
                fullWidth
                sx={{
                  maxWidth: "100%",
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12} style={{ textAlign: "center", padding: " 0px 0px 0px"}}>

            <Button
              onClick={handleClearFilters}
              variant="outlined"
              style={{color:"red",marginRight:"5px"}}
            >
              Limpar Filtros
            </Button>
            <Button
              onClick={handleApplyFilters} // Apply filters on click
              variant="contained"
              color="primary"
              style={{ color:"white", marginLeft:"1px"}}
            >
              Aplicar Filtros
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
}

export default Filters;
