import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import apiService from "utils/apiService";
import burceMars from "assets/images/logoempresa.jpeg";

const ProfilePictureContext = createContext();

export const useProfilePicture = () => {
  return useContext(ProfilePictureContext);
};

export function ProfilePictureProvider({ children }) {
  const [profilePicture, setProfilePicture] = useState(burceMars);
  const [loading, setLoading] = useState(true);

  const fetchProfilePicture = useCallback(async () => {
    try {
      const response = await apiService.users.getProfilePicture();
      const imgURL = window.URL.createObjectURL(new Blob([response.data]));
      setProfilePicture(imgURL);
      setLoading(false); // Done loading
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      setProfilePicture(burceMars);
      setLoading(false); // Done loading
    }
  }, []);

  useEffect(() => {
    fetchProfilePicture();
  }, [fetchProfilePicture]);

  return (
    <ProfilePictureContext.Provider value={{ loading, profilePicture, setProfilePicture }}>
      {children}
    </ProfilePictureContext.Provider>
  );
}
