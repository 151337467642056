import React from "react";
import StatisticsCardGroup from "components/StatisticsCardGroup";
import KBox from "components/KBox";

function HistoryHeader() {
  // Identificadores dos cartões que queremos exibir
  const cardIdentifiers = ["totalActions", "recentActions", "pendingActions"];

  return (
    <KBox mb={3}>
      {/* Usa o componente StatisticsCardGroup e passa os identificadores dos cartões que queremos mostrar */}
      <StatisticsCardGroup identifiers={cardIdentifiers} />
    </KBox>
  );
}

export default HistoryHeader;
