import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import apiService from "utils/apiService";

const AddSupplierModal = ({ open, onClose, onSupplierAdded }) => {
  const [newSupplier, setNewSupplier] = useState({
    name: "",
    contact: "",
    address: "",
    cnpj: "",
  });

  const [nameError, setNameError] = useState(false);

  const handleSaveSupplier = async () => {
    if (!newSupplier.name) {
      setNameError(true);
      return;
    }
    try {
      await apiService.suppliers.add(newSupplier);
      if (onSupplierAdded) onSupplierAdded(); // Callback para atualizar a lista de fornecedores, se necessário
      setNewSupplier({ name: "", contact: "", address: "", cnpj: "" });
      onClose(); // Fecha o modal após salvar
    } catch (error) {
      console.error("Erro ao adicionar fornecedor:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar Novo Fornecedor</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Nome do Fornecedor"
          type="text"
          fullWidth
          required
          error={nameError}
          helperText={nameError ? "Nome do fornecedor é obrigatório" : ""}
          value={newSupplier.name}
          onChange={(e) => {
            setNewSupplier({ ...newSupplier, name: e.target.value });
            if (e.target.value) setNameError(false); // Limpa o erro se o campo for preenchido
          }}
        />
        <TextField
          margin="dense"
          label="Contato do Fornecedor"
          type="text"
          fullWidth
          value={newSupplier.contact}
          onChange={(e) =>
            setNewSupplier({ ...newSupplier, contact: e.target.value })
          }
        />
        <TextField
          margin="dense"
          label="Endereço do Fornecedor"
          type="text"
          fullWidth
          value={newSupplier.address}
          onChange={(e) =>
            setNewSupplier({ ...newSupplier, address: e.target.value })
          }
        />
        <TextField
          margin="dense"
          label="CNPJ do Fornecedor"
          type="text"
          fullWidth
          value={newSupplier.cnpj}
          onChange={(e) =>
            setNewSupplier({ ...newSupplier, cnpj: e.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleSaveSupplier}
          color="primary"
          disabled={!newSupplier.name}
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSupplierModal;
