import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import axios from "axios";

const AddUserModal = ({ open, handleClose }) => {
  const [newUser, setNewUser] = useState({
    email: "",
    name: "",
    description: "",
    phone: "",
    role: "",
    picture_path: "",
    permission_level: "", // Aceitará números como string inicialmente
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (field) => (event) => {
    setNewUser({ ...newUser, [field]: event.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const userToSubmit = {
        ...newUser,
        permission_level: parseInt(newUser.permission_level, 10), // Converte para número
      };

      await axios.post("/users", userToSubmit);
      alert("Usuário adicionado com sucesso!");
      handleClose();
    } catch (error) {
      console.error("Erro ao adicionar usuário:", error);
      alert("Erro ao adicionar usuário.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Adicionar Novo Usuário</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              value={newUser.email}
              onChange={handleChange("email")}
              fullWidth
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nome"
              value={newUser.name}
              onChange={handleChange("name")}
              fullWidth
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Descrição"
              value={newUser.description}
              onChange={handleChange("description")}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Telefone"
              value={newUser.phone}
              onChange={handleChange("phone")}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Cargo"
              value={newUser.role}
              onChange={handleChange("role")}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Caminho da Imagem"
              value={newUser.picture_path}
              onChange={handleChange("picture_path")}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nível de Permissão"
              value={newUser.permission_level}
              onChange={handleChange("permission_level")}
              fullWidth
              variant="outlined"
              type="number" // Tipo número para garantir que o usuário insira um número
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Senha"
              value={newUser.password}
              onChange={handleChange("password")}
              fullWidth
              variant="outlined"
              type="password"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          {loading ? "Adicionando..." : "Adicionar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
