/*
  SISTEMA KORTEX - AVISO LEGAL

  Este código-fonte é propriedade exclusiva da Kortex.
  A reprodução, distribuição ou modificação deste código sem autorização expressa é estritamente proibida.

  Qualquer violação dos direitos autorais ou das disposições contratuais aplicáveis
  resultará em medidas legais apropriadas.

  Todos os direitos reservados.

  KORTEX SYSTEM - LEGAL NOTICE

  This source code is the exclusive property of the Kortex.
  Reproduction, distribution, or modification of this code without explicit authorization is strictly prohibited.

  Any violation of copyright or applicable contractual provisions
  will result in appropriate legal action.

  All rights reserved.
*/
// Loader.js
import React from "react";
import { Box } from "@mui/material";

function Loader({ open, color }) {
  if (!open) return null; // Retorna null se o loader não estiver ativo

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute", // Absoluto em relação ao DashboardLayout
        top: 0,
        left: 0,
        pointerEvents: "none", // Permitir interação com outros elementos
        zIndex: 1200, // Alto z-index para sobrepor conteúdo
      }}
    >
      <Box sx={loaderStyle(color)}>
        <Box sx={crescentStyle(color)} />
      </Box>

      <style>
        {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </Box>
  );
}

const loaderStyle = (color) => ({
  position: "relative",
  width: "64px",
  height: "64px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const crescentStyle = (color) => ({
  width: "48px",
  height: "48px",
  borderRadius: "50%",
  border: `6px solid ${color}`,
  borderColor: `${color} transparent ${color} transparent`,
  animation: "spin 1s linear infinite",
});

export default Loader;
