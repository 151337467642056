import React, { useState, useEffect, useCallback } from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import { motion } from "framer-motion";
import KBox from "components/KBox";
import Breadcrumbs from "examples/Breadcrumbs";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import apiService from "utils/apiService";
import { useAuth } from "../../../layouts/authentication/AuthContext";
import ProfilePicture from "layouts/profile/components/Header/ProfilePicture";

const DashboardNavbar = ({ absolute, light, isMini }) => {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const { currentUser, logout } = useAuth();
  const route = useLocation().pathname.split("/").slice(1);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    setNavbarType(fixedNavbar ? "sticky" : "static");

    const handleTransparentNavbar = () => {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("scroll", handleTransparentNavbar);
    window.addEventListener("resize", handleResize);
    handleTransparentNavbar();

    return () => {
      window.removeEventListener("scroll", handleTransparentNavbar);
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = useCallback(
    () => setMiniSidenav(dispatch, !miniSidenav),
    [dispatch, miniSidenav]
  );

  const handleConfiguratorOpen = useCallback(
    () => setOpenConfigurator(dispatch, !openConfigurator),
    [dispatch, openConfigurator]
  );

  const handleProfileMenuOpen = (event) =>
    setProfileMenuAnchor(event.currentTarget);
  const handleProfileMenuClose = () => setProfileMenuAnchor(null);

  const handleLogout = useCallback(() => {
    handleProfileMenuClose();
    logout();
  }, [logout]);

  const fetchProfilePicture = useCallback(async () => {
    try {
      const response = await apiService.users.getProfilePicture();
      const imgURL = window.URL.createObjectURL(new Blob([response.data]));
      setProfilePicture(imgURL);
    } catch (error) {
      console.error("Erro ao buscar foto de perfil:", error);
      setProfilePicture(null); // Defina uma imagem padrão aqui, se necessário
    }
  }, []);

  useEffect(() => {
    fetchProfilePicture();
  }, [fetchProfilePicture]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = useCallback(async () => {
    if (selectedFile) {
      try {
        await apiService.users.updateProfilePicture(selectedFile);
        const imgURL = window.URL.createObjectURL(selectedFile);
        setProfilePicture(imgURL);
      } catch (error) {
        console.error("Erro ao fazer upload da imagem", error);
      }
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedFile) {
      handleUpload(); // Faz o upload da imagem quando selecionada
    }
  }, [selectedFile, handleUpload]);

  const iconsStyle = useCallback(
    ({ palette: { dark, white, text }, functions: { rgba } }) => ({
      color: () => {
        let colorValue = light || darkMode ? white.main : dark.main;

        if (transparentNavbar && !light) {
          colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
        }

        return colorValue;
      },
    }),
    [light, darkMode, transparentNavbar]
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <KBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </KBox>
        {!isMini && (
          <KBox sx={(theme) => navbarRow(theme, { isMini })}>
            <KBox color={light ? "white" : "inherit"}>
              <IconButton onClick={handleProfileMenuOpen} sx={navbarIconButton}>
                <ProfilePicture
                  canChangePicture={false}
                  sx={{
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    borderRadius: "50%",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={profileMenuAnchor}
                open={Boolean(profileMenuAnchor)}
                onClose={handleProfileMenuClose}
                sx={{ mt: 2 }}
              >
                <MenuItem component={Link} to="/conta">
                  Ver Perfil
                </MenuItem>
                <MenuItem
                  onClick={handleLogout}
                  sx={{ color: "red", fontWeight: "bold" }}
                >
                  Sair
                </MenuItem>
              </Menu>

              {/* Botão de interação do menu lateral, apenas para mobile */}
              {isMobile && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleMiniSidenav}
                >
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <Icon sx={iconsStyle} fontSize="medium">
                      {miniSidenav ? "menu_open" : "menu"}
                    </Icon>
                  </motion.div>
                </IconButton>
              )}

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <motion.div
                  whileHover={{ rotate: 90 }}
                  transition={{ duration: 0.3 }}
                >
                  <Icon sx={{ fontSize: "medium" }}>settings</Icon>
                </motion.div>
              </IconButton>
            </KBox>
          </KBox>
        )}
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
