import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import KBox from "components/KBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";

function BasicLayout({
  image,
  children,
  backgroundColor,
  imageOpacity,
  secondaryImage,
  secondaryImagePosition,
  secondaryImageOpacity,
  secondaryImageSize,
}) {
  return (
    <PageLayout>
      <KBox
        position="relative" // Alterado para "relative" para permitir posicionamento do logo secundário
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: image
            ? `linear-gradient(rgba(13, 30, 38, ${imageOpacity || 0.8}), rgba(13, 30, 38, ${imageOpacity || 0.8})), url(${image})`
            : "none",
          backgroundColor: backgroundColor || "transparent",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          overflow: "hidden", // Garante que o logo secundário possa ser cortado pela borda
        }}
      >
        {secondaryImage && (
          <KBox
            sx={{
              position: "absolute",
              width: secondaryImageSize?.width || "300px",
              height: secondaryImageSize?.height || "300px",
              backgroundImage: `url(${secondaryImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              opacity: secondaryImageOpacity || 0.5,
              ...secondaryImagePosition, // Permite definir a posição personalizada da imagem
            }}
          />
        )}
        <KBox px={1} width="100%" height="100vh" mx="auto">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              {children}
            </Grid>
          </Grid>
        </KBox>
      </KBox>
      <KBox>
      </KBox>

    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string,
  backgroundColor: PropTypes.string,
  imageOpacity: PropTypes.number,
  secondaryImage: PropTypes.string,
  secondaryImagePosition: PropTypes.object,
  secondaryImageOpacity: PropTypes.number,
  secondaryImageSize: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
