import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Badge from "@mui/material/Badge";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import KAvatar from "components/KAvatar";

function ProfilesList({ title, profiles, shadow, currentUserId }) {
  const listRef = useRef(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  // Filtra o usuário logado e ordena os usuários online primeiro
  const filteredProfiles = profiles
    .filter(({ id }) => id !== currentUserId)
    .sort((a, b) => b.isOnline - a.isOnline);

  useEffect(() => {
    // Verifica se o scroll é necessário (mais de 3 usuários)
    setShowScrollIndicator(filteredProfiles.length > 3);
  }, [filteredProfiles]);

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 5; // Checa se está no final
      setShowScrollIndicator(!atBottom);
    }
  };

  const renderProfiles = filteredProfiles.map(
    ({ id, foto_perfil, nome, cargo, isOnline }) => (
      <KBox
        key={id}
        component="li"
        display="flex"
        alignItems="center"
        py={1}
        mb={1}
      >
        <KBox mr={2}>
          <Badge
            color={isOnline ? "success" : "error"}
            variant="dot"
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              "& .MuiBadge-dot": {
                height: "16px", // Aumenta a altura da bolinha
                width: "16px", // Aumenta a largura da bolinha
                borderRadius: "50%", // Certifica-se de que a bolinha é circular
              },
            }}
          >
            <KAvatar
              src={foto_perfil}
              alt={nome}
              shadow="md"
              sx={{
                width: 56, // Largura do avatar
                height: 56, // Altura do avatar
                "& img": {
                  width: "100%", // Imagem preenche 100% do avatar
                  height: "100%", // Imagem preenche 100% do avatar
                  objectFit: "cover", // A imagem é cortada para preencher o avatar
                },
              }}
            />
          </Badge>
        </KBox>
        <KBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <KTypography variant="button" fontWeight="medium">
            {nome}
          </KTypography>
          <KTypography variant="caption" color="text">
            {cargo}
          </KTypography>
        </KBox>
      </KBox>
    ),
  );

  return (
    <Card
      sx={{
        height: "100%",
        boxShadow: !shadow && "none",
        position: "relative",
      }}
    >
      <KBox pt={2} px={2}>
        <KTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </KTypography>
      </KBox>
      <KBox
        p={2}
        ref={listRef}
        sx={{
          maxHeight: 300, // Define uma altura máxima para ativar o scroll
          overflowY: "auto", // Ativa o scroll vertical se o conteúdo exceder a altura
        }}
        onScroll={handleScroll}
      >
        <KBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </KBox>
      </KBox>
      {showScrollIndicator && (
        <KBox
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "6px", // Aumenta a altura do indicador
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Torna o indicador mais visível
            borderRadius: "3px",
            opacity: showScrollIndicator ? 1 : 0, // Fade-in e fade-out suave
            transition: "opacity 0.3s ease-in-out", // Transição suave
            pointerEvents: "none", // Impede que a barra interfira na rolagem
          }}
        />
      )}
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired, // Suporta números e strings
      foto_perfil: PropTypes.string.isRequired,
      nome: PropTypes.string.isRequired,
      cargo: PropTypes.string.isRequired,
      isOnline: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  shadow: PropTypes.bool,
  currentUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired, // Suporta números e strings
};

export default ProfilesList;
