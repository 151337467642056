import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  GlobalStyles,
  TextField,
  Chip,
  Autocomplete,
} from "@mui/material";
import KBox from "components/KBox";
import KButton from "components/KButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import apiService from "utils/apiService";
import { useNavigate } from "react-router-dom";

const MEASUREMENT_UNITS = [
  { label: "Unidade", value: "Un" },
  { label: "Quilograma (kg)", value: "kg" },
  { label: "Tonelada", value: "tonelada" },
  { label: "Grama (g)", value: "grama" },
  { label: "Miligramas (mg)", value: "mg" },
  { label: "Litros", value: "litro" },
  { label: "Mililitros (ml)", value: "ml" },
  { label: "Metro Cúbico (m³)", value: "m3" },
  { label: "Galão", value: "galao" },
  { label: "Metros", value: "m" },
  { label: "Centímetros (cm)", value: "cm" },
  { label: "Milímetros (mm)", value: "mm" },
  { label: "Metro Quadrado (m²)", value: "m2" },
  { label: "Centímetro Quadrado (cm²)", value: "cm2" },
  { label: "Caixa", value: "caixa" },
  { label: "Pacote", value: "pacote" },
  { label: "Rolo", value: "rolo" },
  { label: "Balde", value: "balde" },
  { label: "Palete", value: "palete" },
  { label: "Dúzia", value: "duzia" },
  { label: "Par", value: "par" },
];

function AddItem() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    brand: "",
    tags: [],
    description: "",
  });

  const [tagsList, setTagsList] = useState([]);
  const [nameError, setNameError] = useState(false);

  const fetchTags = async () => {
    try {
      const response = await apiService.tags.getAll();
      const fetchedRows = response.data;
      setTagsList(fetchedRows);
    } catch (error) {
      console.error("Erro ao buscar tags:", error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));

    // If the user starts typing, remove the error
    if (field === "name" && value) {
      setNameError(false);
    }
  };

  const handleTagsChange = (event, value) => {
    setFormData((prev) => ({
      ...prev,
      tags: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formData.name) {
      setNameError(true);
      return;
    }
    try {
      console.log("Formulário a ser enviado:", formData);
      await apiService.item_models.add({
        name: formData.name,
        brand: formData.brand,
        tag_ids: formData.tags.map((tag) => tag.id),
        description: formData.description,
      });
      navigate("/item");
    } catch (error) {
      console.error("Erro ao cadastrar item:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GlobalStyles
        styles={{
          ".MuiSelect-select": {
            padding: "12px 14px !important",
            height: "45px !important",
            display: "flex",
            alignItems: "center",
          },
        }}
      />
      <KBox py={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card sx={{ width: "100%" }}>
              <CardHeader
                title="Cadastrar Novo Item"
                subheader="Preencha as informações abaixo para cadastrar um novo item"
                titleTypographyProps={{ variant: "h4", fontWeight: "bold" }}
                subheaderTypographyProps={{
                  variant: "subtitle1",
                  color: "textSecondary",
                }}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Nome"
                      variant="outlined"
                      fullWidth
                      value={formData.name}
                      onChange={(e) => handleChange("name", e.target.value)}
                      required
                      error={nameError}
                      helperText={nameError ? "Campo obrigatório." : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Marca"
                      variant="outlined"
                      fullWidth
                      value={formData.brand}
                      onChange={(e) => handleChange("brand", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      multiple
                      options={tagsList}
                      getOptionLabel={(option) => option.name}
                      value={formData.tags}
                      onChange={handleTagsChange}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                            key={option.id}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Tags"
                          placeholder="Selecione as tags"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Descrição"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={formData.description}
                      onChange={(e) =>
                        handleChange("description", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions sx={{ justifyContent: "space-between", p: 3 }}>
                <KButton
                  variant="outlined"
                  color="error"
                  onClick={() => navigate("/items")}
                >
                  Cancelar
                </KButton>
                <KButton
                  variant="contained"
                  color="info"
                  onClick={handleSubmit}
                >
                  Cadastrar Item
                </KButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </KBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddItem;
