import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MaterialUIControllerProvider } from "context"; // Importação do Context Provider

const container = document.getElementById("app");

if (container) {
  const root = createRoot(container);

  root.render(
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  );
} else {
  console.error("Elemento de container 'app' não encontrado.");
}
