import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../axiosInstance";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          const response = await axios.get("/users/me");

          // Exibe o permission_level do usuário logado no console
          console.log(
            "Nível de permissão do usuário logado:",
            response.data.permission_level,
          );

          setCurrentUser(response.data); // Define os dados do usuário
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        localStorage.removeItem("token"); // Remove token se falhar
        delete axios.defaults.headers.common["Authorization"];
      } finally {
        setLoading(false); // Indica que o carregamento foi concluído
      }
    };

    fetchUserData();
  }, []);

  const login = async (email, senha) => {
    try {
      const data = new URLSearchParams();
      data.append("username", email);
      data.append("password", senha);

      const response = await axios.post(
        "/login/access-token",
        data.toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      const token = response.data.access_token;
      if (token) {
        setIsAuthenticated(true);
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const userResponse = await axios.get("/users/me");

        // Exibe o permission_level do usuário logado no console
        console.log(
          "Nível de permissão do usuário logado:",
          userResponse.data.permission_level,
        );

        setCurrentUser(userResponse.data);
        navigate("/dashboard");
      } else {
        alert("Falha no login: nenhum token recebido");
      }
    } catch (error) {
      console.error("Erro de login:", error);
      alert("Falha no login: credenciais incorretas ou erro no servidor");
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setCurrentUser(null);
    localStorage.removeItem("token");
    console.log("Usuário desconectado");
    delete axios.defaults.headers.common["Authorization"];
    navigate("/authentication/logar");
  };

  const value = {
    isAuthenticated,
    currentUser,
    login,
    logout,
    loading,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
