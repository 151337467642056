import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  GlobalStyles,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import KButton from "components/KButton";
import apiService from "utils/apiService";

function EditGroupModal({
  open,
  handleClose,
  groupId,
  groupData,
  handleEditGroup,
  onGroupUpdate,
}) {
  const [editedGroupData, setEditedGroupData] = useState(groupData || {});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (open && groupId) {
      apiService.groups
        .getById(groupId)
        .then((response) => {
          setEditedGroupData(response.data);
        })
        .catch((error) => {
          console.error(
            "Erro ao carregar os dados do grupo:",
            error.response?.data || error.message
          );
        });
    }
  }, [open, groupId]);

  const handleChange = (field) => (event) => {
    setEditedGroupData({ ...editedGroupData, [field]: event.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setEditedGroupData({ ...editedGroupData, picture_path: file });
    }
  };

  const validateFields = () => {
    const errors = {
      name: editedGroupData.name.trim() === "",
      cnpj: editedGroupData.cnpj.trim() === "",
      status: editedGroupData.status.trim() === "",
    };
    return !Object.values(errors).includes(true);
  };

  const handleSubmit = () => {
    if (validateFields()) {
      const formData = new FormData();
      Object.keys(editedGroupData).forEach((key) => {
        formData.append(key, editedGroupData[key]);
      });

      apiService.groups
        .update(groupId, formData)
        .then((response) => {
          handleEditGroup(response.data);
          handleClose();
        })
        .catch((error) => {
          console.error(
            "Erro ao editar grupo:",
            error.response?.data || error.message
          );
        });
    }
  };

  const handleDeleteConfirmation = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  const handleDelete = () => {
    apiService.groups
      .delete(groupId)
      .then(() => {
        onGroupUpdate();
        handleClose();
        navigate("/grupos");
      })
      .catch((error) => {
        console.error(
          "Erro ao excluir grupo:",
          error.response?.data || error.message
        );
      })
      .finally(() => {
        handleConfirmDeleteClose();
      });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Card>
          <CardContent>
            <DialogTitle>Editar Grupo</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} paddingTop={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Nome"
                    value={editedGroupData.name || ""}
                    onChange={handleChange("name")}
                    fullWidth
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Descrição"
                    value={editedGroupData.description || ""}
                    onChange={handleChange("description")}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="CNPJ"
                    value={editedGroupData.cnpj || ""}
                    onChange={handleChange("cnpj")}
                    fullWidth
                    variant="outlined"
                    required
                  />
                </Grid>
                <GlobalStyles
                  styles={{
                    ".MuiSelect-select": {
                      padding: "12px 14px !important",
                      height: "45px !important",
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                />
                <Grid item xs={12} style={{ padding: "16px 0px 0px 16px" }}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={editedGroupData.status || ""}
                      onChange={handleChange("status")}
                      label="Status"
                      sx={{
                        height: 50,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="ONGOING">Em Andamento</MenuItem>
                      <MenuItem value="FINISHED">Finalizado</MenuItem>
                      <MenuItem value="CANCELED">Cancelado</MenuItem>
                    </Select>
                    <FormHelperText>Selecione o status do grupo</FormHelperText>
                  </FormControl>
                </Grid>

                {/* Adicionando a seção de seleção de imagem */}
                <Grid item xs={12}>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file-edit"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="raised-button-file-edit">
                    <KButton
                      variant="contained"
                      color="info"
                      component="span"
                      fullWidth
                    >
                      Selecionar Imagem do Grupo
                    </KButton>
                  </label>
                  {editedGroupData.picture_path && (
                    <FormHelperText>
                      Imagem selecionada: {editedGroupData.picture_path.name}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </CardContent>
          <Card>
            <DialogActions
              sx={{ justifyContent: "space-between", width: "100%", px: 3 }}
            >
              <KButton onClick={handleDeleteConfirmation} color="error">
                Excluir
              </KButton>
              <KBox display="flex" justifyContent="flex-end" gap={1}>
                <KButton onClick={handleClose} color="secondary">
                  Cancelar
                </KButton>
                <KButton onClick={handleSubmit} color="success">
                  Salvar
                </KButton>
              </KBox>
            </DialogActions>
          </Card>
        </Card>
      </Dialog>

      {/* Modal de confirmação de exclusão */}
      <Dialog open={confirmDeleteOpen} onClose={handleConfirmDeleteClose}>
        <Card p={3}>
          <DialogTitle>Confirmação de Exclusão</DialogTitle>
          <DialogContent>
            <KTypography variant="body2" color="text">
              Tem certeza de que deseja excluir o grupo "{editedGroupData.name}
              "?
            </KTypography>
          </DialogContent>
          <DialogActions>
            <KButton onClick={handleConfirmDeleteClose} color="secondary">
              Cancelar
            </KButton>
            <KButton onClick={handleDelete} color="error">
              Excluir
            </KButton>
          </DialogActions>
        </Card>
      </Dialog>
    </>
  );
}

export default EditGroupModal;
