import React from "react";
import { Grid, Divider, Box, Card } from "@mui/material";
import KTypography from "components/KTypography";

const SummaryTab = ({
  documentNumber,
  supplier,
  date,
  group,
  requester,
  requestNumber,
  requestMessage,
  carrier,
  freightType,
  vehicleType,
  vehiclePlate,
  inspection,
  observations,
  comments,
  addEvaluation,
  evaluationLevel,
}) => {
  return (
    <Box py={3}>
      <Card elevation={3} sx={{ padding: 3, borderRadius: 2, marginBottom: 3 }}>
        <KTypography variant="h4" component="div">
          Resumo da Entrada
        </KTypography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Número do Documento:</strong> {documentNumber || "N/A"}
            </KTypography>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Fornecedor:</strong> {supplier.name || "N/A"}
            </KTypography>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Data de Recebimento:</strong> {date || "N/A"}
            </KTypography>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Grupo:</strong> {group.name || "N/A"}
            </KTypography>
          </Grid>

          <Grid item xs={12} md={6}>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Solicitante:</strong> {requester?.name || "N/A"}
            </KTypography>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Nº Requisição:</strong> {requestNumber || "N/A"}
            </KTypography>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Mensagem:</strong> {requestMessage || "N/A"}
            </KTypography>
          </Grid>

          <Grid item xs={12} md={6}>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Transportadora:</strong> {carrier || "N/A"}
            </KTypography>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Tipo de Frete:</strong> {freightType || "N/A"}
            </KTypography>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Tipo de Veículo:</strong> {vehicleType || "N/A"}
            </KTypography>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Placa do Veículo:</strong> {vehiclePlate || "N/A"}
            </KTypography>
          </Grid>

          <Grid item xs={12} md={6}>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Fiscalização:</strong> {inspection || "N/A"}
            </KTypography>
            <KTypography variant="subtitle1" mb={2}>
              <strong>Observações:</strong> {observations || "N/A"}
            </KTypography>
          </Grid>

          <Grid item xs={12}>
            <KTypography variant="subtitle1">
              <strong>Avaliação:</strong>{" "}
              {addEvaluation ? evaluationLevel || "N/A" : "Nenhuma avaliação"}
            </KTypography>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default SummaryTab;
