/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import KAlert from "components/KAlert";
import KButton from "components/KButton";
import KSnackbar from "components/KSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function Notifications() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const alertContent = (name) => (
    <KTypography variant="body2" color="white">
      NOTIFICAÇÃO de: {name} alert with{" "}
      <KTypography
        component="a"
        href="#"
        variant="body2"
        fontWeight="medium"
        color="white"
      >
        LINK
      </KTypography>
      . Clique caso queria ir ate lá
    </KTypography>
  );

  const renderSuccessSB = (
    <KSnackbar
      color="success"
      icon="check"
      title="Sucesso"
      content="Sucesso! Notificação"
      dateTime="11 minutos atrás"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <KSnackbar
      icon="notifications"
      title="Informação"
      content="Mensagem"
      dateTime="11 minutos atrás"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <KSnackbar
      color="warning"
      icon="star"
      title="Aviso"
      content="Mensagem de aviso"
      dateTime="11 minutos atrás"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <KSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Ocorreu um problema!"
      dateTime="11 minutos atrás"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <KBox p={2}>
                <KTypography variant="h5">Alertas</KTypography>
              </KBox>
              <KBox pt={2} px={2}>
                <KAlert color="primary" dismissible>
                  {alertContent("PRIMARIO")}
                </KAlert>
                <KAlert color="secondary" dismissible>
                  {alertContent("SECUNDARIO")}
                </KAlert>
                <KAlert color="success" dismissible>
                  {alertContent("SUCESSO")}
                </KAlert>
                <KAlert color="error" dismissible>
                  {alertContent("ERRO")}
                </KAlert>
                <KAlert color="warning" dismissible>
                  {alertContent("AVISO")}
                </KAlert>
                <KAlert color="info" dismissible>
                  {alertContent("INFO")}
                </KAlert>
                <KAlert color="light" dismissible>
                  {alertContent("LIGTH")}
                </KAlert>
                <KAlert color="dark" dismissible>
                  {alertContent("DARK")}
                </KAlert>
              </KBox>
            </Card>
          </Grid>

          <Grid item xs={12} lg={8}>
            <Card>
              <KBox p={2} lineHeight={0}>
                <KTypography variant="h5">Notificação</KTypography>
                <KTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                >
                  Utilizando Bootstrap:
                </KTypography>
              </KBox>
              <KBox p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <KButton
                      variant="gradient"
                      color="success"
                      onClick={openSuccessSB}
                      fullWidth
                    >
                      notificação de sucesso
                    </KButton>
                    {renderSuccessSB}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <KButton
                      variant="gradient"
                      color="info"
                      onClick={openInfoSB}
                      fullWidth
                    >
                      notificação de info
                    </KButton>
                    {renderInfoSB}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <KButton
                      variant="gradient"
                      color="warning"
                      onClick={openWarningSB}
                      fullWidth
                    >
                      notificação de aviso
                    </KButton>
                    {renderWarningSB}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <KButton
                      variant="gradient"
                      color="error"
                      onClick={openErrorSB}
                      fullWidth
                    >
                      notificação de erro
                    </KButton>

                    {renderErrorSB}
                  </Grid>
                </Grid>
              </KBox>
            </Card>
          </Grid>
        </Grid>
      </KBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Notifications;
