import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import KBox from "components/KBox";
import KTypography from "components/KTypography";
import backgroundImage from "assets/images/fundo1.jpg";
import { useAuth } from "layouts/authentication/AuthContext";
import breakpoints from "assets/theme/base/breakpoints";
import ProfilePicture from "layouts/profile/components/Header/ProfilePicture";

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const { currentUser, loading } = useAuth(); // Pega o currentUser e loading do contexto

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  // Verifica se está carregando os dados
  if (loading) {
    return <div>Carregando dados do usuário...</div>; // Pode personalizar o loader
  }

  return (
    <KBox position="relative" mb={5}>
      <KBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6),
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <ProfilePicture canChangePicture={true} />
          </Grid>
          <Grid item>
            <KBox height="100%" mt={0.5} lineHeight={1}>
              <KTypography variant="h5" fontWeight="medium">
                {currentUser?.name || "Nome não disponível"}
              </KTypography>
              <KTypography variant="button" color="text" fontWeight="regular">
                {currentUser?.role || "Cargo não disponível"}
              </KTypography>
            </KBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </KBox>
  );
}

Header.defaultProps = {
  children: "",
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
