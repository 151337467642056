import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import RadarChart from "examples/Charts/RadarChart";
import KBox from "components/KBox";
import SupplierTable from "./components/SupplierTable";
import TopRatedSuppliersList from "./TopRatedSuppliersList";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import apiService from "../../utils/apiService";
import SupplierHeader from "./SupplierHeader";

const supplierColumns = [
  { field: "id", headerName: "ID", flex: 0.2 },
  { field: "name", headerName: "Nome", flex: 1 },
  { field: "contact", headerName: "Contato", flex: 1 },
  { field: "address", headerName: "Endereço", flex: 2 },
  { field: "cnpj", headerName: "CNPJ", flex: 1 },
  { field: "number_of_stock_inflows", headerName: "Quantidade de Pedidos", flex: 0.5 },
];

const createChartData = (rows) => ({
  labels: rows.map((row) => row.name),
  datasets: [
    {
      label: "Pedidos",
      color: "info",
      data: rows.map((row) => row.number_of_stock_inflows),
    },
  ],
});

function Suppliers() {
  const [loading, setLoading] = useState(true);
  const [supplierRows, setSupplierRows] = useState([]);
  const [chartData, setChartData] = useState(createChartData([]));
  const [monthlyStockInflows, setMonthlyStockInflows] = useState(0);

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await apiService.suppliers.getAll();
        const fetchedRows = response.data;
        setSupplierRows(fetchedRows);
        setChartData(createChartData(fetchedRows));
      } catch (error) {
        console.error("Erro ao buscar fornecedores:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchMonthlyStockInflows = async () => {
      try {
        const response = await apiService.stockInflows.getMonthlyTotal();
        setMonthlyStockInflows(response.data.total);
      } catch (error) {
        console.error("Erro ao buscar entradas do mês:", error);
      }
    };

    fetchSuppliers();
    fetchMonthlyStockInflows();
  }, []);

  const handleEdit = async (updatedRow) => {
    try {
      await apiService.suppliers.update(updatedRow.id, updatedRow);
      const updatedRows = supplierRows.map((row) =>
        row.id === updatedRow.id ? updatedRow : row
      );
      setSupplierRows(updatedRows);
      setChartData(createChartData(updatedRows));
    } catch (error) {
      console.error("Erro ao atualizar fornecedor:", error);
    }
  };

  const handleDelete = async (rowId) => {
    try {
      await apiService.suppliers.delete(rowId);
      const updatedRows = supplierRows.filter((row) => row.id !== rowId);
      setSupplierRows(updatedRows);
      setChartData(createChartData(updatedRows));
    } catch (error) {
      console.error("Erro ao deletar fornecedor:", error);
    }
  };

  const renderRadarChart = () => (
    <Grid item xs={12} md={6}>
      {/* Radar Chart */}
           <RadarChart
            icon={{ component: "equalizer" }}
            title="Pedidos por Fornecedor"
            chart={chartData}
            size={{ width: "100%", height: "400px" }}
            onRefresh={() => setChartData(createChartData(supplierRows))}
          />
    </Grid>
  );

  const renderTopRatedSuppliers = () => (
    <Grid item xs={12} md={6}>
      {/* Top Rated Suppliers List */}
      <TopRatedSuppliersList
        icon={{ color: "info", component: "star" }}
        title="Top Fornecedores"
        description="Os 10 fornecedores mais bem avaliados"
        suppliers={supplierRows}
        onRefresh={() => setSupplierRows([...supplierRows])} // Se quiser um refresh manual
      />
    </Grid>
  );


  const renderLoadingIndicator = () => (
    loading && (
      <Grid item xs={12} display="flex" justifyContent="center">
        <CircularProgress />
      </Grid>
    )
  );

  const renderSupplierTable = () => (
    !loading && (
      <Grid item xs={12}>
        <SupplierTable
          columns={supplierColumns}
          rows={supplierRows}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      </Grid>
    )
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KBox py={3}>
        <SupplierHeader
          totalSuppliers={supplierRows.length}
          monthlyStockInflows={monthlyStockInflows}
        />

        <Grid container spacing={3}>
          {renderRadarChart()}
          {renderTopRatedSuppliers()}
          {renderLoadingIndicator()}
          {renderSupplierTable()}
        </Grid>
      </KBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Suppliers;
