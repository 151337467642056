// localeText.js

import { GridColumnMenuManageItem } from "@mui/x-data-grid";

const localeText = {
  // General
  noRowsLabel: "Nenhuma linha",
  noResultsOverlayLabel: "Nenhum resultado encontrado.",

  // Columns panel
  toolbarDensity: "Densidade",
  toolbarDensityLabel: "Densidade",
  toolbarDensityCompact: "Compacto",
  toolbarDensityStandard: "Padrão",
  toolbarDensityComfortable: "Confortável",

  // Columns selector
  columnsPanelTextFieldLabel: "Encontrar coluna",
  columnsPanelTextFieldPlaceholder: "Título da coluna",
  columnsPanelDragIconLabel: "Reordenar coluna",
  columnsPanelShowAllButton: "Mostrar todos",
  columnsPanelHideAllButton: "Esconder todos",

  // Filters panel
  filterPanelAddFilter: "Adicionar filtro",
  filterPanelDeleteIconLabel: "Excluir",
  filterPanelLinkOperator: "Operadores lógicos",
  filterPanelOperators: "Operadores",
  filterPanelOperatorAnd: "E",
  filterPanelOperatorOr: "Ou",
  filterPanelColumns: "Colunas",
  filterPanelInputLabel: "Valor",
  filterPanelInputPlaceholder: "Valor do filtro",

  // Filter operators
  filterOperatorContains: "contém",
  filterOperatorEquals: "é igual a",
  filterOperatorStartsWith: "começa com",
  filterOperatorEndsWith: "termina com",
  filterOperatorIs: "é",
  filterOperatorNot: "não é",
  filterOperatorAfter: "é depois de",
  filterOperatorOnOrAfter: "está em ou depois de",
  filterOperatorBefore: "é antes de",
  filterOperatorOnOrBefore: "está em ou antes de",
  filterOperatorIsEmpty: "está vazio",
  filterOperatorIsNotEmpty: "não está vazio",
  filterOperatorIsAnyOf: "é qualquer um de",

  // Filter values
  filterValueAny: "qualquer",
  filterValueTrue: "verdadeiro",
  filterValueFalse: "falso",

  // Column menu
  columnMenuLabel: "Menu",
  columnMenuShowColumns: "Mostrar colunas",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Esconder",
  columnMenuUnsort: "Desfazer ordenação",
  columnMenuSortAsc: "Crescente",
  columnMenuSortDesc: "Decrescente",

  // Column header
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnHeaderFiltersLabel: "Mostrar filtros",
  columnHeaderSortIconLabel: "Ordenar",

  // Rows selected footer
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  // Total rows footer
  footerTotalRows: "Total de linhas:",

  // Total visible rows footer
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Pagination
  toolbarPaginationRowsPerPage: "Linhas por página:", // Tradução para "Rows per page:" NÃO ESTÁ INDO #####
};

export default localeText;
