import { useState, useEffect, useCallback } from "react";
import apiService from "utils/apiService"; // Conectando à API

function useWarehouseData() {
  const [data, setData] = useState({
    item: { columns: [], rows: [], editableFields: [] },
    entry: { columns: [], rows: [], editableFields: [] },
    exit: { columns: [], rows: [], editableFields: [] },
  });
  const [loading, setLoading] = useState(false); // Estado para indicar carregamento
  const [error, setError] = useState(null); // Estado para lidar com erros

  // Função para carregar os dados de inflows da API com paginação
  const fetchStockInflows = useCallback(async (page = 1, pageSize = 10) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.stock_inflows.getAll({ page, pageSize });
      const inflows = response.data;

      if (!Array.isArray(inflows)) {
        console.error("A resposta de inflows não é um array:", inflows);
        setError("Dados inválidos recebidos do servidor.");
        setLoading(false);
        return;
      }

      const columns = [
        { field: "id", headerName: "ID", width: 50 },
        { field: "document_number", headerName: "Num. do Documento", width: 150 },
        { field: "supplier_name", headerName: "Fornecedor", width: 150 },
        { field: "group_name", headerName: "Grupo", width: 150 },
        { field: "purchase_rating", headerName: "Classificação da Compra", width: 100 },
        { field: "oservation", headerName: "Observação", width: 150 },
        { field: "delivery_date", headerName: "Data de Entrega", width: 150 },
        { field: "created_at", headerName: "Data de Criação", width: 150 },
      ];


      const rows = inflows.map((inflow) => ({
        id: inflow.id,
        document_number: inflow.document_number,
        delivery_date: inflow.delivery_date
          ? new Date(inflow.delivery_date).toLocaleDateString()
          : 'Não definido',
        supplier_name: inflow.supplier_name || 'Não definido',
        group_name: inflow.group_name || 'Não definido',
        purchase_rating: inflow.purchase_rating || "Neutra",
        created_at: new Date(inflow.created_at).toLocaleDateString(),
      }));

      setData((prevData) => ({
        ...prevData,
        entry: {
          columns,
          rows,
          editableFields: ["document_number", "delivery_date", "supplier_name", "group_name"],
        },
      }));
    } catch (error) {
      console.error("Erro ao carregar os dados de entrada:", error);
      setError("Erro ao carregar os dados de entrada.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Função para carregar os dados de outflows da API
  const fetchStockOutflows = useCallback(async (page = 1, pageSize = 10) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.stock_outflows.getAll({ page, pageSize });
      const outflows = response.data;

      if (!Array.isArray(outflows)) {
        console.error("A resposta de outflows não é um array:", outflows);
        setError("Dados inválidos recebidos do servidor.");
        setLoading(false);
        return;
      }

      const columns = [
        { field: "id", headerName: "ID", width: 50 },
        { field: "observation", headerName: "Observação", width: 250 },
        { field: "outflow_date", headerName: "Data de Saída", width: 150 },
        { field: "requester_name", headerName: "Responsável", width: 150 },
        { field: "total_quantity", headerName: "Quantidade Total de Itens", width: 250 },
        { field: "created_at", headerName: "Data de Registro", width: 150 },
      ];

      const rows = outflows.map((outflow) => ({
        id: outflow.id,
        observation: outflow.observation || "Sem Observação",
        outflow_date: outflow.outflow_date ? new Date(outflow.outflow_date).toLocaleDateString() : 'Não definido',
        requester_name: outflow.requester_name || 'Não definido',
        total_quantity: outflow.total_quantity,
        created_at: new Date(outflow.created_at).toLocaleDateString(),
        updated_at: new Date(outflow.updated_at).toLocaleDateString(),
      }));

      setData((prevData) => ({
        ...prevData,
        exit: {
          columns,
          rows,
          editableFields: ["quantity", "observation"],
        },
      }));
    } catch (error) {
      console.error("Erro ao carregar os dados de saída:", error);
      setError("Erro ao carregar os dados de saída.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Função para carregar os dados de itens da API com paginação
  const fetchItems = useCallback(async (page = 1, pageSize = 10) => {
    setLoading(true);
    setError(null);
    try {
      const [itemsResponse, itemModelsResponse, groupsResponse] = await Promise.all([
        apiService.items.getActive({ page, pageSize }),
        apiService.item_models.getAll(),
        apiService.groups.getAll(),
      ]);

      const items = itemsResponse.data;
      const itemModels = itemModelsResponse.data;
      const groups = groupsResponse.data;

      if (!Array.isArray(items) || !Array.isArray(itemModels) || !Array.isArray(groups)) {
        console.error("Os itens, modelos de itens ou grupos não são arrays válidos:", items, itemModels, groups);
        setError("Dados inválidos recebidos do servidor.");
        setLoading(false);
        return;
      }

      // Cria mapas para buscar rapidamente os nomes de modelos de itens, grupos e fornecedores
      const itemModelMap = itemModels.reduce((acc, model) => {
        acc[model.id] = model.name;
        return acc;
      }, {});

      const groupMap = groups.reduce((acc, group) => {
        acc[group.id] = group.name;
        return acc;
      }, {});

      const columns = [
        { field: "id", headerName: "ID", width: 70 },
        { field: "code", headerName: "Cod. Produto", width: 150 },
        { field: "item_model_name", headerName: "Item", width: 200 },
        { field: "ncm", headerName: "NCM", width: 100 },
        { field: "measurement_unit", headerName: "Unid.", width: 100 },
        { field: "quantity", headerName: "Quantidade", width: 100 },
        { field: "price", headerName: "Preço Unit", width: 100 },
        { field: "stock_inflow_document_number", headerName: "Num. de Documento", width: 170 },
        { field: "group_name", headerName: "Grupo", width: 150 },
        { field: "supplier_name", headerName: "Fornecedor", width: 150 },
        { field: "created_at", headerName: "Data Criação", width: 150 },
        { field: "updated_at", headerName: "Data de Update", width: 150 },
      ];

      const rows = items.map((item) => ({
        id: item.id,
        code: item.code,
        item_model_name: item.item_model_name || 'Não definido',
        ncm: item.ncm,
        measurement_unit: item.measurement_unit,
        quantity: item.quantity,
        price: item.price,
        stock_inflow_document_number: item.stock_inflow_document_number || 'Não definido',
        group_name: item.group_name || 'Não definido',
        supplier_name: item.supplier_name || 'Não definido',  // Certifique-se de que 'supplier_name' é mapeado corretamente
        created_at: new Date(item.created_at).toLocaleDateString(),
        updated_at: new Date(item.updated_at).toLocaleDateString(),
      }));

      setData((prevData) => ({
        ...prevData,
        item: {
          columns,
          rows,
          editableFields: ["code", "ncm", "price", "quantity"],
        },
      }));
    } catch (error) {
      console.error("Erro ao carregar os dados de itens:", error);
      setError("Erro ao carregar os dados de itens.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleEdit = useCallback(
    (type) => async (updatedRow) => {
      try {
        const id = updatedRow.id;
        if (type === 'item') {
          await apiService.items.update(id, updatedRow);
        } else if (type === 'entry') {
          await apiService.stock_inflows.update(id, updatedRow);
        } else {
          await apiService.stock_outflows.update(id, updatedRow);
        }
        type === 'item' ? fetchItems() : type === 'entry' ? fetchStockInflows() : fetchStockOutflows();
      } catch (error) {
        console.error(`Erro ao editar ${type}:`, error);
        setError(`Erro ao editar ${type}.`);
      }
    },
    [fetchItems, fetchStockInflows, fetchStockOutflows]
  );

  const handleDelete = useCallback(
    (type) => async (rowId) => {
      try {
        if (type === 'item') {
          await apiService.items.delete(rowId);
        } else if (type === 'entry') {
          await apiService.stock_inflows.delete(rowId);
        } else {
          await apiService.stock_outflows.delete(rowId);
        }
        type === 'item' ? fetchItems() : type === 'entry' ? fetchStockInflows() : fetchStockOutflows();
      } catch (error) {
        console.error(`Erro ao deletar ${type}:`, error);
        setError(`Erro ao deletar ${type}.`);
      }
    },
    [fetchItems, fetchStockInflows, fetchStockOutflows]
  );

  useEffect(() => {
    fetchStockInflows();
    fetchStockOutflows();
    fetchItems();
  }, [fetchStockInflows, fetchStockOutflows, fetchItems]);

  return { data, handleEdit, handleDelete, loading, error };
}

export default useWarehouseData;
