import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  Tooltip,
  FormControlLabel,
  Checkbox,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Assignment from "@mui/icons-material/Assignment";
import AccountBalance from "@mui/icons-material/AccountBalance";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Group from "@mui/icons-material/Group";
import SentimentVeryDissatisfied from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfied from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfied from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAlt from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVerySatisfied from "@mui/icons-material/SentimentVerySatisfied";
import { styled } from "@mui/material/styles";
import KTypography from "components/KTypography";

const StyledToggleButton = styled(ToggleButton)(({ theme, value }) => ({
  flex: 1,
  color: theme.palette.text.primary,
  border: "1px solid",
  borderColor: theme.palette.divider,
  transition: "background-color 0.3s, color 0.3s",
  "&.Mui-selected": {
    color: theme.palette.common.white,
    backgroundColor: getColorByValue(value, theme),
  },
  "&.Mui-selected:hover": {
    backgroundColor: getHoverColorByValue(value, theme),
  },
  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
    marginBottom: theme.spacing(0.5),
  },
  "& .MuiTypography-root": {
    marginLeft: theme.spacing(1),
  },
}));

const getColorByValue = (value, theme) => {
  switch (value) {
    case "-2":
    case "-1":
      return theme.palette.error.light;
    case "0":
      return theme.palette.warning.light;
    case "1":
    case "2":
      return theme.palette.success.light;
    default:
      return theme.palette.action.disabled;
  }
};

const getHoverColorByValue = (value, theme) => {
  switch (value) {
    case "-2":
    case "-1":
      return theme.palette.error.dark;
    case "0":
      return theme.palette.warning.dark;
    case "1":
    case "2":
      return theme.palette.success.dark;
    default:
      return theme.palette.action.disabledBackground;
  }
};

function PrincipalTab({ formData, groupsList, suppliersList, onChange }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Tooltip
          title="Digite o número do documento. Este campo é obrigatório."
          arrow
        >
          <TextField
            label="Número do Documento"
            variant="outlined"
            fullWidth
            value={formData.documentNumber}
            onChange={(e) => onChange("documentNumber", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Assignment />
                </InputAdornment>
              ),
            }}
            required
            error={!formData.documentNumber}
          />
        </Tooltip>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Tooltip title="Informe o fornecedor do item." arrow>
          <TextField
            label="Fornecedor"
            variant="outlined"
            fullWidth
            select
            value={formData.supplier.id}
            onChange={(e) => onChange("supplier", { id: e.target.value, name: suppliersList.find(s => s.id === e.target.value).name })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBalance />
                </InputAdornment>
              ),
            }}
            required
            error={!formData.supplier}
          >
            {suppliersList.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </TextField>
        </Tooltip>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Tooltip title="Escolha a data de entrada do item no estoque." arrow>
          <TextField
            label="Data do Recebimento"
            type="date"
            variant="outlined"
            fullWidth
            value={formData.date}
            onChange={(e) => onChange("date", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarToday />
                </InputAdornment>
              ),
            }}
            required
            error={!formData.date}
          />
        </Tooltip>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Tooltip title="Selecione o grupo de destino deste item." arrow>
          <TextField
            label="Grupo"
            variant="outlined"
            fullWidth
            select
            value={formData.group.id}
            onChange={(e) => onChange("group", { id: e.target.value, name: groupsList.find(g => g.id === e.target.value).name })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Group />
                </InputAdornment>
              ),
            }}
            required
            error={!formData.group}
          >
            {groupsList.map((g) => (
              <MenuItem key={g.id} value={g.id}>
                {g.name}
              </MenuItem>
            ))}
          </TextField>
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.addEvaluation}
              onChange={(e) => onChange("addEvaluation", e.target.checked)}
            />
          }
          label="Deseja adicionar uma avaliação para o fornecedor?"
        />
      </Grid>

      {formData.addEvaluation && (
        <Grid item xs={12}>
          <KTypography variant="subtitle1" gutterBottom>
            Avaliação do Fornecedor:
          </KTypography>
          <ToggleButtonGroup
            value={formData.evaluationLevel}
            exclusive
            onChange={(e, value) => onChange("evaluationLevel", value)}
            aria-label="Avaliação do Fornecedor"
            fullWidth
            sx={{ display: "flex", gap: 2 }}
          >
            <StyledToggleButton value="-2" aria-label="Péssimo">
              <SentimentVeryDissatisfied />
              <KTypography variant="body2">Péssimo</KTypography>
            </StyledToggleButton>
            <StyledToggleButton value="-1" aria-label="Ruim">
              <SentimentDissatisfied />
              <KTypography variant="body2">Ruim</KTypography>
            </StyledToggleButton>
            <StyledToggleButton value="0" aria-label="Neutro">
              <SentimentSatisfied />
              <KTypography variant="body2">Neutro</KTypography>
            </StyledToggleButton>
            <StyledToggleButton value="1" aria-label="Bom">
              <SentimentSatisfiedAlt />
              <KTypography variant="body2">Bom</KTypography>
            </StyledToggleButton>
            <StyledToggleButton value="2" aria-label="Ótimo">
              <SentimentVerySatisfied />
              <KTypography variant="body2">Ótimo</KTypography>
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
    </Grid>
  );
}

export default PrincipalTab;
