import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import KBox from "components/KBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Skeleton from "@mui/material/Skeleton";
import apiService from "utils/apiService"; // Certifique-se de que o caminho esteja correto para o serviço de API

// Configuração padrão dos cartões com suas respectivas chamadas de API
const defaultCardConfig = {
  itemsInStock: {
    color: "dark",
    icon: "inventory_2",
    title: "Itens em Estoque",
    count: null,
    percentage: {
      color: "success",
      amount: "-5%",
      label: "em relação à semana passada",
    },
    fetchData: apiService.items.getCount,
  },
  ongoingWorks: {
    color: "info",
    icon: "construction",
    title: "Obras em Andamento",
    count: null,
    percentage: {
      color: "success",
      amount: "+2",
      label: "novas obras este mês",
    },
    fetchData: apiService.groups.getOngoingCount,
  },
  recentMovements: {
    color: "success",
    icon: "swap_vert",
    title: "Movimentações Recentes",
    count: null,
    percentage: {
      color: "error",
      amount: "-12",
      label: "em relação ao mês passado",
    },
    fetchData: apiService.movements.getCurrentMonthCount,
  },
  criticalMaterials: {
    color: "error",
    icon: "report_problem",
    title: "Materiais Críticos",
    count: null,
    percentage: {
      color: "error",
      amount: "+3",
      label: "materiais abaixo do nível crítico",
    },
    fetchData: apiService.item_models.getInCriticalStockAmountCount,
  },
  materialsNearShortage: {
    color: "warning",
    icon: "report_problem",
    title: "Materiais Próximos a Faltar",
    count: null,
    percentage: {
      color: "warning",
      amount: "+5",
      label: "materiais próximos ao limite",
    },
    fetchData: apiService.item_models.getInWarningStockAmountCount,
  },
  topSupplier: {
    color: "info",
    icon: "leaderboard",
    title: "Fornecedor com Mais Pedidos",
    count: null,
    percentage: {
      color: "info",
      amount: null, // Será preenchido com o número de entradas de estoque
      label: "Total de pedido(s)",
    },
    fetchData: apiService.suppliers.getTop,
  },
  bestRatedSupplier: {
    color: "success",
    icon: "thumb_up",
    title: "Fornecedor com Melhor Avaliação",
    count: null,
    percentage: {
      color: "success",
      amount: null, // Será preenchido com a avaliação
      label: "avaliação média",
    },
    fetchData: apiService.suppliers.getHighestRated,
  },
  worstRatedSupplier: {
    color: "error",
    icon: "thumb_down",
    title: "Fornecedor com Pior Avaliação",
    count: null,
    percentage: {
      color: "error",
      amount: null, // Será preenchido com a avaliação
      label: "avaliação média",
    },
    fetchData: apiService.suppliers.getLowestRated,
  },
  totalSuppliers: {
    color: "dark",
    icon: "store",
    title: "Total de Fornecedores",
    count: null,
    percentage: {
      color: "success",
      amount: "+10%",
      label: "em relação ao mês anterior",
    },
    fetchData: apiService.suppliers.getCount,
  },

  // Novos cartões adicionados
  totalActions: {
    color: "info",
    icon: "assignment",
    title: "Total de Ações",
    count: null,
    percentage: {
      color: "success",
      amount: "+10%",
      label: "ações esse mês",
    },
    fetchData: apiService.historyEntries.getCount, // API para buscar o total de ações
  },
  recentActions: {
    color: "success",
    icon: "update",
    title: "Ações Recentes",
    count: null,
    percentage: {
      color: "info",
      amount: "+20",
      label: "nas últimas 24 horas",
    },
    fetchData: apiService.historyEntries.getCountLast24Hours, // API para buscar as ações recentes
  },
  pendingActions: {
    color: "warning",
    icon: "pending_actions",
    title: "Ações Pendentes",
    count: null,
    percentage: {
      color: "error",
      amount: "-5%",
      label: "aguardando aprovação",
    },
    fetchData: () => Promise.resolve({ data: 0 }), // Simulação de uma resposta de dados estática
  },
};

function StatisticsCardGroup({ identifiers }) {
  const [cardData, setCardData] = useState({});

  useEffect(() => {
    const fetchDataForCards = async () => {
      const newData = {};
      for (let id of identifiers) {
        const cardConfig = defaultCardConfig[id];
        if (cardConfig && cardConfig.fetchData) {
          try {
            const response = await cardConfig.fetchData();

            let countValue = "";
            let amountValue = "";

            const responseData = response.data;

            // Se o dado for um objeto, extrai os valores que queremos renderizar
            if (typeof responseData === "object" && responseData !== null) {
              if (id === "topSupplier") {
                countValue = responseData.name || "Fornecedor Desconhecido"; // Fallback para nome
                amountValue = `${responseData.number_of_stock_inflows || 0} `; // Atualiza o amount com o número de entradas
              } else if (id === "bestRatedSupplier" || id === "worstRatedSupplier") {
                countValue = responseData.name || "Fornecedor Desconhecido"; // Fallback para nome
                amountValue = `${responseData.rating || "0"}`; // Atualiza o amount com a avaliação
              } else {
                countValue = typeof responseData.count === 'number' ? responseData.count : "0"; // Garante que count seja número ou string
                amountValue = responseData.amount || "0"; // Fallback para amount
              }
            } else {
              countValue = responseData || "0"; // Fallback para valor simples
            }

            newData[id] = {
              ...cardConfig,
              count: countValue.toString(), // Garante que count seja string
              percentage: {
                ...cardConfig.percentage,
                amount: amountValue.toString(), // Atualiza amount com a avaliação
              },
            };
          } catch (error) {
            console.error(`Erro ao buscar dados para ${id}:`, error);
            newData[id] = { ...cardConfig, count: "Erro ao carregar" };
          }
        } else {
          newData[id] = cardConfig; // Usa os dados estáticos se não houver fetchData
        }
      }
      setCardData(newData);
    };

    fetchDataForCards();
  }, [identifiers]);

  return (
    <KBox mb={3}>
      <Grid container spacing={3}>
        {identifiers.map((id) => {
          const { color, icon, title, count, percentage } = cardData[id] || {};

          return (
            <Grid item xs={12} md={6} lg={3} key={id}>
              <KBox mb={1.5}>
                <ComplexStatisticsCard
                  color={color || "info"} // Fallback para cor
                  icon={icon || "info"} // Fallback para ícone
                  title={title || "Título Desconhecido"} // Fallback para título
                  count={
                    count !== undefined && count !== null ? (
                      count // Exibe o valor no count
                    ) : (
                      <Skeleton variant="text" width={80} height={40} /> // Exibe skeleton enquanto carrega
                    )
                  }
                  percentage={percentage || { color: "info", amount: "N/A", label: "" }} // Fallback para percentage
                />
              </KBox>
            </Grid>
          );
        })}
      </Grid>
    </KBox>
  );
}

export default StatisticsCardGroup;
